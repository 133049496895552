import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import moment from "moment";
import api from "../../libs/api";
import styles from "./Announcements.module.scss";

import { Button, Container } from "@magic-engineering/components";
import { getCoverStyle } from "../ResourceCard/ResourceCard";

export const MAX_DISPLAYED = 5;

const Announcements = () => {
  const [announcements, setAnnouncements] = useState();
  const [showAllNews, setShowAllNews] = useState(false);

  useEffect(() => {
    async function getAnnouncements() {
      try {
        const { data } = await api.get("/cms/assistant/announcements");
        setAnnouncements(data || []);
      } catch (e) {
        toast.error("Failed to fetch announcements.");
        console.error(e.message);
      }
    }

    if (!announcements) {
      getAnnouncements();
    }
  }, [announcements]);

  if (!announcements || announcements.length === 0) return null;
  const [heroCard, ...cards] = announcements;

  const visibleCards = showAllNews ? cards : cards.slice(0, MAX_DISPLAYED - 1);

  return (
    <Container className={`${styles.Announcements} p-0`} dataTestId="Announcements">
      <div id="news">
        <h3 className="module-spacing-bottom pl-2">News & Announcements</h3>
        <AnnouncementCard {...heroCard} />
        {visibleCards.map((card) => (
          <AnnouncementCard {...card} key={card.id} compact={true} />
        ))}
        {announcements.length > MAX_DISPLAYED ? (
          <div className="d-flex">
            <Button
              type="PrimaryInverse"
              onClick={() => setShowAllNews(!showAllNews)}
              label={showAllNews ? "View Less" : "View All News"}
              dataTestId="ToggleAllNews"
            />
          </div>
        ) : null}
      </div>
    </Container>
  );
};

const AnnouncementCard = ({ link, title, category, created_at, cover_image, compact }) => (
  <a
    className={`${compact ? styles.Card : styles.HeroCard} p-0 w-100`}
    data-testid="AnnouncementCard"
    href={link}
    target="_blank"
    rel="noreferrer"
    title={title}
  >
    <figure className={styles.Image} style={getCoverStyle(cover_image)}></figure>
    <div className={`${compact ? styles.CompactContent : ""}`}>
      <h3 className={styles.Title}>{title}</h3>
      <div className={styles.Separator}></div>
      <div className={styles.Meta}>
        {category}
        <div className={styles.Dot}>•</div>
        {moment(created_at).format("MMM DD, YYYY")}
      </div>
    </div>
  </a>
);

export default Announcements;
