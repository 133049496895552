import React, { useState } from "react";
import { Auth } from "aws-amplify";
import { toast } from "react-toastify";
import { Button } from "@magic-engineering/components";

import "./common.scss";
import PeekablePasswordInput from "./PeekablePasswordInput.js";

const signInAmplify = async (username, password) => {
  try {
    await Auth.signIn(username, password);
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export default function SignIn({ setMode, username: initialUsername }) {
  const [username, setUsername] = useState(initialUsername);
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsLoading(true);
    try {
      await signInAmplify(username, password);
      setIsSuccess(true);
      toast.success("Logged in!");
    } catch (error) {
      toast.error(error.message);
    }
    setIsLoading(false);
  };

  return (
    <div className="container" data-testid="sign-in">
      <div className="row">
        <div className="col"></div>
        <form className="col sign-in-container" onSubmit={handleSubmit}>
          <div className="pb-2 header">Login</div>
          <div className="pt-3 pb-3 new-user">
            New User?
            <span className="ml-3" onClick={() => setMode({ mode: "sign-up", username })}>
              Create an account
            </span>
          </div>
          <div className="col pt-2 pb-3 input-field">
            <label className="row">
              <span className="mt-1 mb-1">Email</span>
              <input
                type="email"
                className="form-control mt-2"
                placeholder="jane@mycompany.com"
                onChange={handleUsernameChange}
                defaultValue={username}
                data-testid="email"
                required
              />
            </label>
          </div>
          <div className="col pt-2 pb-3 input-field">
            <label className="row">
              <span className="mt-1 mb-1">Password</span>
              <PeekablePasswordInput
                className="form-control mt-2"
                placeholder="**********"
                onChange={handlePasswordChange}
                data-testid="password"
                required
              />
            </label>
          </div>
          <div className="pb-3 forgot-password">
            Forgot password?
            <span className="ml-3" onClick={() => setMode({ mode: "forgot-password", username })}>
              Reset password
            </span>
          </div>
          <div className="pt-3">
            <Button
              dataTestId="submit-button"
              isLoading={isLoading}
              disabled={isLoading}
              loadingText=""
              className="float-right"
              label={isSuccess ? "LOGGED IN" : "LOGIN"}
            />
          </div>
        </form>
        <div className="col"></div>
      </div>
    </div>
  );
}
