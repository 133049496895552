import React, { useState, useEffect } from "react";
import { Alert, ButtonLink, Container } from "@magic-engineering/components";
import format from "date-fns-tz/format";
import sub from "date-fns/sub";
import { ReactComponent as InfoIcon } from "../../assets/images/info.svg";
import { getLocalData, saveLocalData } from "../../libs/localData";
import api from "../../libs/api";

const LOCAL_STORAGE_KEY = "dismissed-plan-notifications";
const NOTIFICATION_TYPES = ["PLAN_CONFIRMED", "PLAN_UPDATED"];

const PlanNotifications = ({ clients, profile = {} }) => {
  const [notifications, setNotifications] = useState([]);

  useEffect(() => {
    const fetchNotifications = async () => {
      const params = {
        type: NOTIFICATION_TYPES.join(","),
        start_date: format(sub(new Date(), { days: 1 }), "yyyy-MM-dd'T'HH:mm:ssz"),
        order: "ASC",
      };

      const { data } = await api.get(`/assistants/notifications`, { params });

      // Group by plan id and only show latest notification
      const latestPlanNotifications = data.reduce(
        (acc, n) => ({ ...acc, [n.details.plan_id]: { ...n, ...n.details } }),
        {}
      );
      const formattedNotifications = Object.values(latestPlanNotifications)
        .map((n) => {
          const plan = clients.find((c) => c.plan_id === n.plan_id);
          return { ...n, plan };
        })
        .filter((n) => n.plan);
      setNotifications(formattedNotifications);
    };

    if (profile.assistant_id) fetchNotifications();
  }, [profile.assistant_id, clients]);

  const [dismissedKeys, setDismissedKeys] = useState(getLocalData(LOCAL_STORAGE_KEY) || []);

  const dismissNotification = ({ notification_id }) => {
    const dismissed = [...dismissedKeys, notification_id];
    setDismissedKeys(dismissed);
    saveLocalData(LOCAL_STORAGE_KEY, dismissed);
  };

  // Filter out dismissed notifications
  const visibileNotifications = notifications.filter(
    (n) => !dismissedKeys.includes(n.notification_id)
  );
  if (visibileNotifications.length === 0) return null;

  return (
    <Container dataTestId="PlanNotifications" className="p-0">
      {visibileNotifications.map((n, idx) => {
        const onDismiss = () => dismissNotification(n);

        const alertProps =
          n.type === "PLAN_CONFIRMED"
            ? {
                title: `You’re been Hired by ${n.plan.name}! 🎉 Start Date: ${format(
                  new Date(n.start_date),
                  "MMMM d, yyyy @ hh:mm aa z"
                )}`,
                text: (
                  <div style={{ lineHeight: "200%" }}>
                    <div className={`mg-t-s`}>
                      Congrats {profile.first_name}, you were hired by {n.plan.name} today!
                      <br />
                      You are expected to begin tracking your time in the Magic Portal on your start
                      date.
                    </div>
                    <ButtonLink
                      anchorProps={{
                        rel: "noreferrer",
                        target: "_blank",
                      }}
                      className="mt-4 mb-2"
                      href="https://getmagic.com/help/assistants/timetracker/"
                      label="Learn How To Track Time"
                    />
                  </div>
                ),
              }
            : {
                title: `Important! Your plan with ${n.plan.name} was updated on ${format(
                  new Date(n.created_at),
                  "MMMM d, yyyy"
                )}`,
                text: (
                  <div style={{ lineHeight: "200%" }}>
                    <div className={`mg-t-s`}>
                      Please review the new plan details for {n.plan.name}
                      <br />
                      Your client will expect you to know and adhere to the new changes.
                    </div>
                    <ButtonLink
                      className="mt-4 mb-2"
                      href={`/#client-${n.client_id}`}
                      label="Review Plan Updates"
                    />
                  </div>
                ),
              };

        return (
          <Alert
            key={idx}
            className="mg-b-l"
            icon={<InfoIcon />}
            iconClassName="mb-1 mr-2"
            onDismiss={onDismiss}
            {...alertProps}
          />
        );
      })}
    </Container>
  );
};

export default PlanNotifications;
