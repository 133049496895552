import React from "react";
import styles from "./ClientCard.module.scss";
import { ReactComponent as NextIcon } from "../../assets/images/arrow-icon.svg";
import { UserAvatar } from "@magic-engineering/components";

//TODO: Move this to UI Components
export const ClientCard = ({
  image,
  name,
  label,
  showNextIcon,
  rightComponent,
  onClick,
  className,
  dataTestId,
  avatarSize,
}) => (
  <div
    className={`d-flex justify-content-between align-items-center p-4 mb-4 ${styles.ClientCard} ${className}`}
    data-testid={dataTestId || "ClientCard"}
    onClick={onClick}
  >
    <UserAvatar
      className={styles.LeftComponent}
      image={image}
      name={name}
      label={label}
      size={avatarSize}
      labelClass={styles.Label}
    />
    {rightComponent ? (
      rightComponent
    ) : showNextIcon ? (
      <div className={`position-relative mg-l-s`}>
        <NextIcon className={`${styles.NextIcon}`} />
      </div>
    ) : null}
  </div>
);

const ClientSelector = ({ clients, label, rightComponentFn, onClientSelect, showNextIcon }) => (
  <div data-testid="ClientSelector">
    {label && <div className="mg-b-l">{label}</div>}

    {clients.map((c, idx) => (
      <ClientCard
        key={idx}
        name={c.name}
        label={c.name}
        image={c.photo_url}
        onClick={() => onClientSelect(c)}
        showNextIcon={showNextIcon}
        rightComponent={
          typeof rightComponentFn === "function"
            ? rightComponentFn({ client: c, onClientSelect })
            : null
        }
      />
    ))}
  </div>
);

export default ClientSelector;
