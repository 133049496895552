import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";
import { Provider } from "hooks-for-redux";
import "bootstrap";
import "@magic-engineering/components/dist/magic-components.css";
import "./custom.scss";
import App from "./App";
import Tracking from "@magic-engineering/magic-tracking";
import WebFont from "webfontloader";
import { FeatureFlagProvider } from "@magic-engineering/components";

// starts avo tracking library
Tracking.setupTracking({
  env: process.env.REACT_APP_STAGE,
});

WebFont.load({
  google: {
    families: ["Hind:300,400,500,600,700"],
  },
});

if (["staging", "prod"].includes(process.env.REACT_APP_STAGE)) {
  Sentry.init({
    dsn: process.env.REACT_APP_ASSISTANT_PORTAL_SENTRY_DSN,
    environment: process.env.REACT_APP_STAGE,
  });
}

function shouldEnableFeatureFlag() {
  try {
    return Boolean(localStorage.getItem("featureFlag"));
  } catch (e) {
    return false;
  }
}

ReactDOM.render(
  <React.StrictMode>
    <Provider>
      <FeatureFlagProvider shouldEnable={shouldEnableFeatureFlag}>
        <App />
      </FeatureFlagProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
