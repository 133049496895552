import React from "react";
import { useLocation } from "react-router-dom";
import { UserAvatar } from "@magic-engineering/components";

import styles from "./Sidebar.module.scss";
import MenuItem, { ONBOARDING_LINKS, DEFAULT_LINKS } from "./MenuItem";

import { stages } from "../../redux/configuration";
import { isPendingPlan } from "../../libs/helpers";

const Clients = ({ clients }) =>
  clients.map((client, i) => {
    const { photo_url, name, start_date, status } = client;
    return (
      <li key={i} data-testid="Client">
        <UserAvatar
          image={photo_url}
          name={name}
          label={
            <>
              {isPendingPlan({ start_date, status }) ? (
                <span className={styles.highlighted}>NEW: </span>
              ) : null}
              {name}
            </>
          }
          labelClass={styles.clientName}
          className={"d-inline-flex"}
        />
      </li>
    );
  });

const Sidebar = ({ clients = [], stage }) => {
  const { pathname, hash } = useLocation();
  const isOnboardingDone = ![stages.ONBOARDING, stages.REQUIRE_PROFILE].includes(stage);
  const menuItems = isOnboardingDone ? DEFAULT_LINKS : ONBOARDING_LINKS;

  const otherProps = {
    activePath: pathname,
    activeHash: hash,
    stage,
    classes: {
      text: styles.itemText,
    },
  };

  return (
    <div className={styles.Sidebar} data-testid="Sidebar">
      <span className={`d-block ${styles.header} ${styles.text}`}>My Portal</span>
      <ul
        className={styles.list}
        data-testid={isOnboardingDone ? "DefaultSideNav" : "OnboardingSideNav"}
      >
        {Object.keys(menuItems).map((key, idx) => (
          <MenuItem key={idx} {...menuItems[key]} {...otherProps} />
        ))}
      </ul>
      {clients.length ? (
        <>
          <hr />
          <span className={`d-block ${styles.header} ${styles.text}`}>Your Clients</span>
          <ul className={`${styles.list} ${styles.clients}`}>
            <Clients clients={clients} />
          </ul>
        </>
      ) : null}
    </div>
  );
};

Sidebar.propTypes = {};

Sidebar.defaultProps = {};

export default Sidebar;
