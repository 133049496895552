import React from "react";
import moment from "moment";
import styles from "./GroupedTimeReports.module.scss";

import DailyTimeReport from "../../components/DailyTimeReport/DailyTimeReport";

const GroupedTimeReports = ({ date, group = [], onClick }) => (
  <div
    className={`container-xl container-lg container-md container-fluid mb-5 ${styles.GroupedTimeReports}`}
    data-testid="GroupedTimeReports"
  >
    <span className={`${styles.Week} mb-2`} data-testid="GroupedTimeReportDate">
      {moment(date).format("ddd, MMM DD, YYYY")}
    </span>
    {group
      .sort((a, b) => moment(a.created_at) - moment(b.created_at))
      .map((dtr, i) => (
        <DailyTimeReport {...dtr} key={i} onClick={onClick} />
      ))}
  </div>
);

GroupedTimeReports.propTypes = {};

GroupedTimeReports.defaultProps = {};

export default GroupedTimeReports;
