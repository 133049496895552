import React from "react";
// import PropTypes from 'prop-types';
import styles from "./ClientList.module.scss";

import Client from "../Client/Client";

const ClientList = ({ clients }) => {
  return (
    <div data-testid="ClientList">
      {(clients || []).length > 0 ? (
        <>
          <div
            className={`container-xl container-lg container-md container-fluid ${styles.ClientWrapper}`}
          >
            <h3 className="module-spacing-bottom">Clients</h3>
          </div>
          <div
            className={`container-xl container-lg container-md container-fluid ${styles.ClientWrapper} ${styles.ClientList}`}
          >
            <section id="cards">
              {clients.map((client, i) => {
                return <Client key={i} profile={client} />;
              })}
            </section>
          </div>
        </>
      ) : null}
    </div>
  );
};

export default ClientList;
