import React from "react";
import { Container, RadioButtonSet } from "@magic-engineering/components";

const ChangeClientForm = ({ client = {}, clients = [], onChange, onDismiss }) => {
  const onSelect = (name) => {
    const newClient = clients.find((c) => c.name === name);
    onChange(newClient);
    onDismiss();
  };

  return (
    <Container dataTestId="ChangeClientForm" className={`px-0 pt-0`} onDismiss={onDismiss}>
      <h3 className="pd-y-m">Change Client</h3>
      <RadioButtonSet
        choices={clients.map((c) => ({
          label: c.name,
          value: c.name,
          image: c.photo_url,
          includeAvatar: true,
        }))}
        defaultValue={client.name}
        name="client"
        onChange={onSelect}
      />
    </Container>
  );
};

export default ChangeClientForm;
