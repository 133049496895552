import React from "react";

import api from "../../libs/api";

import closeIcon from "../../assets/images/close.svg";
import infoIcon from "../../assets/images/info.svg";
import avatarIcon from "../../assets/images/avatar.svg";
import styles from "./CancellationNotifications.module.scss";

// Local Storage stores a list of plan cancellation notifications that
// have been dismissed. To make lookup easy, this list is just a simple
// concatenation of 3 fields: "<plan_id>/<status>/<effective_date>".
// For example: "12345/ongoing/2021-05-28"

class CancellationNotifications extends React.Component {
  constructor(props) {
    super(props);
    this.clients = props.clients || [];
    this.state = { cancellations: [], notifications: [] };
  }

  componentDidMount() {
    if (this.clients.length > 0) {
      api.get("/clients/cancel-assistants?recent").then(({ data }) => {
        const cancellations = data || [];
        for (const c of cancellations) {
          c.key = `${c.plan_id}/${c.status}/${c.effective_date}`;
          c.client = { id: c.client_id, name: c.client_name };
          const client = this.clients.find((cl) => cl.email === c.client_email);
          c.client.profilePhoto = client ? client.photo_url : null;
        }
        this.updateNotifications(cancellations);
      });
    }
  }

  updateNotifications(cancellations = this.state.cancellations) {
    // Filter out dismissed notifications
    const dismissedKeys = JSON.parse(
      localStorage.getItem("dismissed-cancel-notifications") || "[]"
    );
    const notifications = cancellations.filter((c) => !dismissedKeys.includes(c.key));
    this.setState({ cancellations, notifications });
  }

  render() {
    return (
      <>
        {this.state.notifications.length > 0 ? (
          <div className="mb-5 cancellation-notifications-wrapper">
            {this.state.notifications.map((n) => (
              <CancellationNotification
                key={n.key}
                notificationKey={n.key}
                status={n.status}
                client={n.client}
                effectiveDate={n.effective_date}
                onDismissed={this.updateNotifications.bind(this)}
              />
            ))}
          </div>
        ) : null}
      </>
    );
  }
}

const CancellationNotification = ({
  notificationKey,
  status,
  client,
  effectiveDate,
  onDismissed,
}) => {
  const effectiveDateText = new Date(effectiveDate).toLocaleDateString("en-US", {
    timeZone: "UTC",
    month: "long",
    day: "numeric",
    year: "numeric",
  });

  function dismissNotification(e) {
    const key = e.target.parentElement.dataset.notificationKey;
    const dismissed = JSON.parse(localStorage.getItem("dismissed-cancel-notifications") || "[]");
    dismissed.push(key);
    localStorage.setItem("dismissed-cancel-notifications", JSON.stringify(dismissed));
    onDismissed();
  }

  const profilePhoto = (
    <img className={styles.Photo} src={client.profilePhoto || avatarIcon} alt="" />
  );

  if (status === "ongoing")
    return (
      <div
        className={`mb-2 ${styles.CancelNotification}`}
        data-notification-key={notificationKey}
        data-testid="CancelNotification-ongoing"
      >
        <img
          src={closeIcon}
          className={styles.X}
          alt=""
          onClick={dismissNotification}
          aria-hidden="true"
          data-testid="CancelNotification-ongoing-x"
        />
        {profilePhoto}
        <b>
          <img src={infoIcon} className="mr-2" alt="ⓘ" />
          Your assistant services for {client.name} will stop on {effectiveDateText}
        </b>
        <ul>
          <li>
            Work stoppage will be effective at the end of your client’s current billing period on{" "}
            {effectiveDateText}.
          </li>
          <li>
            You’re still expected to show up to work or be available for your client until then.
          </li>
          <li>Your payment for this week will still be processed.</li>
          <li>After the work stoppage, you will be marked as available for hire.</li>
          <li>
            Questions? Please <a href="mailto:hey@getmagicnow.com">contact Magic</a>.
          </li>
        </ul>
        <button
          type="button"
          className={`btn btn-lilac ${styles.Button}`}
          onClick={dismissNotification}
          data-testid="CancelNotification-ongoing-button"
        >
          Close
        </button>
      </div>
    );

  if (status === "halted")
    return (
      <div
        className={`mb-2 ${styles.CancelNotification}`}
        data-notification-key={notificationKey}
        data-testid="CancelNotification-halted"
      >
        <img
          src={closeIcon}
          className={styles.X}
          alt=""
          onClick={dismissNotification}
          aria-hidden="true"
          data-testid="CancelNotification-halted-x"
        />
        {profilePhoto}
        <b>
          <img src={infoIcon} className="mr-2" alt="ⓘ" />
          {client.name} halted the work stoppage request. Your assistant services will resume.
        </b>
        <ul>
          <li>We’re just letting you know that the work stoppage request was halted.</li>
          <li>Your assistant services for {client.name} will resume as normal.</li>
          <li>
            Questions? <a href="mailto:hey@getmagicnow.com">Contact Magic</a>.
          </li>
        </ul>
        <button
          type="button"
          className={`btn btn-lilac ${styles.Button}`}
          onClick={dismissNotification}
          data-testid="CancelNotification-halted-button"
        >
          Close
        </button>
      </div>
    );

  // completed
  return (
    <div
      className={`mb-2 ${styles.CancelNotification}`}
      data-notification-key={notificationKey}
      data-testid="CancelNotification-completed"
    >
      <img
        src={closeIcon}
        className={styles.X}
        alt=""
        onClick={dismissNotification}
        aria-hidden="true"
        data-testid="CancelNotification-completed-x"
      />
      {profilePhoto}
      <b>
        Your assistant services for {client.name} were stopped today, {effectiveDateText}
      </b>
      <ul>
        <li>{client.name} was removed from your list of active clients in your dashboard.</li>
        <li>
          You still have access to <a href="#billing">billing history</a> for final earnings and tax
          purposes.
        </li>
        <li>You are now marked as available for hire for other clients.</li>
        <li>
          Questions? <a href="mailto:hey@getmagicnow.com">Contact Magic</a>.
        </li>
      </ul>
      <button
        type="button"
        className={`btn btn-lilac ${styles.Button}`}
        onClick={dismissNotification}
        data-testid="CancelNotification-completed-button"
      >
        Close
      </button>
    </div>
  );
};

export default CancellationNotifications;
