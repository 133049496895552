// Example: Destination Interface for the Mixpanel SDK. Replace the Mixpanel implementation with your own tracking SDK methods
const rudderanalytics = window.rudderanalytics;
const destinationInterface = {
  make: function (env, apiKey) {
    // Initializes the tracking libraries. At the moment
    // we are initializing the Rudderstack through an html script
  },

  logEvent: function (eventName, eventProperties = {}) {
    rudderanalytics.track(eventName, eventProperties, {
      integrations: rudderanalytics.configuredDestinations,
    });
  },

  setUserProperties: function (userId, userProperties) {
    window.rudderanalytics.identify(userId, userProperties);
  },

  identify: function (userId) {
    window.rudderanalytics.identify(userId);
  },

  unidentify: function () {
    rudderanalytics.reset();
  },

  revenue: function (amount, eventProperties) {},

  page: function (pageName, eventProperties) {
    const { category = pageName } = eventProperties;
    rudderanalytics.page(category, pageName, eventProperties);
  },

  // A group is for example a company
  setGroupProperties(groupType, groupId, groupProperties) {
    rudderanalytics.group(groupId, { type: groupType, ...groupProperties });
  },

  addCurrentUserToGroup: function (groupType, groupId, groupProperties) {
    rudderanalytics.group(groupId);
  },

  logEventWithGroups: function (eventName, eventProperties, groupTypesToGroupIds) {
    // TODO: to implement when needed
  },
};

export default destinationInterface;
