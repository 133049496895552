// Generated by Avo VERSION 106.75.0. You should never have to make changes to this file.
// If you find yourself in the situation where you have to edit the file please contact us at hi@avo.app.
// If you encounter a git conflict in this file run `avo pull` and it will be resolved automatically.
/* eslint-disable */

// fetch() polyfill
(function () {
  if (typeof window === 'undefined') {
    return;
  }
  var support = {
    searchParams: 'URLSearchParams' in self,
    iterable: 'Symbol' in self && 'iterator' in Symbol,
    blob:
      'FileReader' in self &&
      'Blob' in self &&
      (function() {
        try {
          new Blob();
          return true
        } catch (e) {
          return false
        }
      })(),
    formData: 'FormData' in self,
    arrayBuffer: 'ArrayBuffer' in self
  };

  function isDataView(obj) {
    return obj && DataView.prototype.isPrototypeOf(obj)
  }

  if (support.arrayBuffer) {
    var viewClasses = [
      '[object Int8Array]',
      '[object Uint8Array]',
      '[object Uint8ClampedArray]',
      '[object Int16Array]',
      '[object Uint16Array]',
      '[object Int32Array]',
      '[object Uint32Array]',
      '[object Float32Array]',
      '[object Float64Array]'
    ];

    var isArrayBufferView =
      ArrayBuffer.isView ||
      function(obj) {
        return obj && viewClasses.indexOf(Object.prototype.toString.call(obj)) > -1
      };
  }

  function normalizeName(name) {
    if (typeof name !== 'string') {
      name = String(name);
    }
    if (/[^a-z0-9\-#$%&'*+.^_`|~]/i.test(name)) {
      throw new TypeError('Invalid character in header field name')
    }
    return name.toLowerCase()
  }

  function normalizeValue(value) {
    if (typeof value !== 'string') {
      value = String(value);
    }
    return value
  }

  // Build a destructive iterator for the value list
  function iteratorFor(items) {
    var iterator = {
      next: function() {
        var value = items.shift();
        return {done: value === undefined, value: value}
      }
    };

    if (support.iterable) {
      iterator[Symbol.iterator] = function() {
        return iterator
      };
    }

    return iterator
  }

  function Headers(headers) {
    this.map = {};

    if (headers instanceof Headers) {
      headers.forEach(function(value, name) {
        this.append(name, value);
      }, this);
    } else if (Array.isArray(headers)) {
      headers.forEach(function(header) {
        this.append(header[0], header[1]);
      }, this);
    } else if (headers) {
      Object.getOwnPropertyNames(headers).forEach(function(name) {
        this.append(name, headers[name]);
      }, this);
    }
  }

  Headers.prototype.append = function(name, value) {
    name = normalizeName(name);
    value = normalizeValue(value);
    var oldValue = this.map[name];
    this.map[name] = oldValue ? oldValue + ', ' + value : value;
  };

  Headers.prototype['delete'] = function(name) {
    delete this.map[normalizeName(name)];
  };

  Headers.prototype.get = function(name) {
    name = normalizeName(name);
    return this.has(name) ? this.map[name] : null
  };

  Headers.prototype.has = function(name) {
    return this.map.hasOwnProperty(normalizeName(name))
  };

  Headers.prototype.set = function(name, value) {
    this.map[normalizeName(name)] = normalizeValue(value);
  };

  Headers.prototype.forEach = function(callback, thisArg) {
    for (var name in this.map) {
      if (this.map.hasOwnProperty(name)) {
        callback.call(thisArg, this.map[name], name, this);
      }
    }
  };

  Headers.prototype.keys = function() {
    var items = [];
    this.forEach(function(value, name) {
      items.push(name);
    });
    return iteratorFor(items)
  };

  Headers.prototype.values = function() {
    var items = [];
    this.forEach(function(value) {
      items.push(value);
    });
    return iteratorFor(items)
  };

  Headers.prototype.entries = function() {
    var items = [];
    this.forEach(function(value, name) {
      items.push([name, value]);
    });
    return iteratorFor(items)
  };

  if (support.iterable) {
    Headers.prototype[Symbol.iterator] = Headers.prototype.entries;
  }

  function consumed(body) {
    if (body.bodyUsed) {
      return Promise.reject(new TypeError('Already read'))
    }
    body.bodyUsed = true;
  }

  function fileReaderReady(reader) {
    return new Promise(function(resolve, reject) {
      reader.onload = function() {
        resolve(reader.result);
      };
      reader.onerror = function() {
        reject(reader.error);
      };
    })
  }

  function readBlobAsArrayBuffer(blob) {
    var reader = new FileReader();
    var promise = fileReaderReady(reader);
    reader.readAsArrayBuffer(blob);
    return promise
  }

  function readBlobAsText(blob) {
    var reader = new FileReader();
    var promise = fileReaderReady(reader);
    reader.readAsText(blob);
    return promise
  }

  function readArrayBufferAsText(buf) {
    var view = new Uint8Array(buf);
    var chars = new Array(view.length);

    for (var i = 0; i < view.length; i++) {
      chars[i] = String.fromCharCode(view[i]);
    }
    return chars.join('')
  }

  function bufferClone(buf) {
    if (buf.slice) {
      return buf.slice(0)
    } else {
      var view = new Uint8Array(buf.byteLength);
      view.set(new Uint8Array(buf));
      return view.buffer
    }
  }

  function Body() {
    this.bodyUsed = false;

    this._initBody = function(body) {
      this._bodyInit = body;
      if (!body) {
        this._bodyText = '';
      } else if (typeof body === 'string') {
        this._bodyText = body;
      } else if (support.blob && Blob.prototype.isPrototypeOf(body)) {
        this._bodyBlob = body;
      } else if (support.formData && FormData.prototype.isPrototypeOf(body)) {
        this._bodyFormData = body;
      } else if (support.searchParams && URLSearchParams.prototype.isPrototypeOf(body)) {
        this._bodyText = body.toString();
      } else if (support.arrayBuffer && support.blob && isDataView(body)) {
        this._bodyArrayBuffer = bufferClone(body.buffer);
        // IE 10-11 can't handle a DataView body.
        this._bodyInit = new Blob([this._bodyArrayBuffer]);
      } else if (support.arrayBuffer && (ArrayBuffer.prototype.isPrototypeOf(body) || isArrayBufferView(body))) {
        this._bodyArrayBuffer = bufferClone(body);
      } else {
        this._bodyText = body = Object.prototype.toString.call(body);
      }

      if (!this.headers.get('content-type')) {
        if (typeof body === 'string') {
          this.headers.set('content-type', 'text/plain;charset=UTF-8');
        } else if (this._bodyBlob && this._bodyBlob.type) {
          this.headers.set('content-type', this._bodyBlob.type);
        } else if (support.searchParams && URLSearchParams.prototype.isPrototypeOf(body)) {
          this.headers.set('content-type', 'application/x-www-form-urlencoded;charset=UTF-8');
        }
      }
    };

    if (support.blob) {
      this.blob = function() {
        var rejected = consumed(this);
        if (rejected) {
          return rejected
        }

        if (this._bodyBlob) {
          return Promise.resolve(this._bodyBlob)
        } else if (this._bodyArrayBuffer) {
          return Promise.resolve(new Blob([this._bodyArrayBuffer]))
        } else if (this._bodyFormData) {
          throw new Error('could not read FormData body as blob')
        } else {
          return Promise.resolve(new Blob([this._bodyText]))
        }
      };

      this.arrayBuffer = function() {
        if (this._bodyArrayBuffer) {
          return consumed(this) || Promise.resolve(this._bodyArrayBuffer)
        } else {
          return this.blob().then(readBlobAsArrayBuffer)
        }
      };
    }

    this.text = function() {
      var rejected = consumed(this);
      if (rejected) {
        return rejected
      }

      if (this._bodyBlob) {
        return readBlobAsText(this._bodyBlob)
      } else if (this._bodyArrayBuffer) {
        return Promise.resolve(readArrayBufferAsText(this._bodyArrayBuffer))
      } else if (this._bodyFormData) {
        throw new Error('could not read FormData body as text')
      } else {
        return Promise.resolve(this._bodyText)
      }
    };

    if (support.formData) {
      this.formData = function() {
        return this.text().then(decode)
      };
    }

    this.json = function() {
      return this.text().then(JSON.parse)
    };

    return this
  }

  // HTTP methods whose capitalization should be normalized
  var methods = ['DELETE', 'GET', 'HEAD', 'OPTIONS', 'POST', 'PUT'];

  function normalizeMethod(method) {
    var upcased = method.toUpperCase();
    return methods.indexOf(upcased) > -1 ? upcased : method
  }

  function Request(input, options) {
    options = options || {};
    var body = options.body;

    if (input instanceof Request) {
      if (input.bodyUsed) {
        throw new TypeError('Already read')
      }
      this.url = input.url;
      this.credentials = input.credentials;
      if (!options.headers) {
        this.headers = new Headers(input.headers);
      }
      this.method = input.method;
      this.mode = input.mode;
      this.signal = input.signal;
      if (!body && input._bodyInit != null) {
        body = input._bodyInit;
        input.bodyUsed = true;
      }
    } else {
      this.url = String(input);
    }

    this.credentials = options.credentials || this.credentials || 'same-origin';
    if (options.headers || !this.headers) {
      this.headers = new Headers(options.headers);
    }
    this.method = normalizeMethod(options.method || this.method || 'GET');
    this.mode = options.mode || this.mode || null;
    this.signal = options.signal || this.signal;
    this.referrer = null;

    if ((this.method === 'GET' || this.method === 'HEAD') && body) {
      throw new TypeError('Body not allowed for GET or HEAD requests')
    }
    this._initBody(body);
  }

  Request.prototype.clone = function() {
    return new Request(this, {body: this._bodyInit})
  };

  function decode(body) {
    var form = new FormData();
    body
      .trim()
      .split('&')
      .forEach(function(bytes) {
        if (bytes) {
          var split = bytes.split('=');
          var name = split.shift().replace(/\+/g, ' ');
          var value = split.join('=').replace(/\+/g, ' ');
          form.append(decodeURIComponent(name), decodeURIComponent(value));
        }
      });
    return form
  }

  function parseHeaders(rawHeaders) {
    var headers = new Headers();
    // Replace instances of \r\n and \n followed by at least one space or horizontal tab with a space
    // https://tools.ietf.org/html/rfc7230#section-3.2
    var preProcessedHeaders = rawHeaders.replace(/\r?\n[\t ]+/g, ' ');
    preProcessedHeaders.split(/\r?\n/).forEach(function(line) {
      var parts = line.split(':');
      var key = parts.shift().trim();
      if (key) {
        var value = parts.join(':').trim();
        headers.append(key, value);
      }
    });
    return headers
  }

  Body.call(Request.prototype);

  function Response(bodyInit, options) {
    if (!options) {
      options = {};
    }

    this.type = 'default';
    this.status = options.status === undefined ? 200 : options.status;
    this.ok = this.status >= 200 && this.status < 300;
    this.statusText = 'statusText' in options ? options.statusText : 'OK';
    this.headers = new Headers(options.headers);
    this.url = options.url || '';
    this._initBody(bodyInit);
  }

  Body.call(Response.prototype);

  Response.prototype.clone = function() {
    return new Response(this._bodyInit, {
      status: this.status,
      statusText: this.statusText,
      headers: new Headers(this.headers),
      url: this.url
    })
  };

  Response.error = function() {
    var response = new Response(null, {status: 0, statusText: ''});
    response.type = 'error';
    return response
  };

  var redirectStatuses = [301, 302, 303, 307, 308];

  Response.redirect = function(url, status) {
    if (redirectStatuses.indexOf(status) === -1) {
      throw new RangeError('Invalid status code')
    }

    return new Response(null, {status: status, headers: {location: url}})
  };

  self.DOMException = self.DOMException;
  try {
    new self.DOMException();
  } catch (err) {
    self.DOMException = function(message, name) {
      this.message = message;
      this.name = name;
      var error = Error(message);
      this.stack = error.stack;
    };
    self.DOMException.prototype = Object.create(Error.prototype);
    self.DOMException.prototype.constructor = self.DOMException;
  }

  function fetch(input, init) {
    return new Promise(function(resolve, reject) {
      var request = new Request(input, init);

      if (request.signal && request.signal.aborted) {
        return reject(new self.DOMException('Aborted', 'AbortError'))
      }

      var xhr = new XMLHttpRequest();

      function abortXhr() {
        xhr.abort();
      }

      xhr.onload = function() {
        var options = {
          status: xhr.status,
          statusText: xhr.statusText,
          headers: parseHeaders(xhr.getAllResponseHeaders() || '')
        };
        options.url = 'responseURL' in xhr ? xhr.responseURL : options.headers.get('X-Request-URL');
        var body = 'response' in xhr ? xhr.response : xhr.responseText;
        resolve(new Response(body, options));
      };

      xhr.onerror = function() {
        reject(new TypeError('Network request failed'));
      };

      xhr.ontimeout = function() {
        reject(new TypeError('Network request failed'));
      };

      xhr.onabort = function() {
        reject(new self.DOMException('Aborted', 'AbortError'));
      };

      xhr.open(request.method, request.url, true);

      if (request.credentials === 'include') {
        xhr.withCredentials = true;
      } else if (request.credentials === 'omit') {
        xhr.withCredentials = false;
      }

      if ('responseType' in xhr && support.blob) {
        xhr.responseType = 'blob';
      }

      request.headers.forEach(function(value, name) {
        xhr.setRequestHeader(name, value);
      });

      if (request.signal) {
        request.signal.addEventListener('abort', abortXhr);

        xhr.onreadystatechange = function() {
          // DONE (success or failure)
          if (xhr.readyState === 4) {
            request.signal.removeEventListener('abort', abortXhr);
          }
        };
      }

      xhr.send(typeof request._bodyInit === 'undefined' ? null : request._bodyInit);
    })
  }

  fetch.polyfill = true;

  if (!self.fetch) {
    self.fetch = fetch;
    self.Headers = Headers;
    self.Request = Request;
    self.Response = Response;
  }
})();

  var __AVO_DEV__ = false;
  var __AVO_NOOP__ = false;
  var __AVO_LOGGER__ = null;
  var __AVO_ENV__ = null;
  var __REPORT_FAILURE_AS__ = null;
  var __STRICT__ = null;

  var __WEB_DEBUGGER__ = true;
const avoInspectorApiKey = "XuJBy56o9AY19RhRC0Rs";
var __INSPECTOR__ = null;

  // polyfill Array.isArray
  if (!Array.isArray) {
    Array.isArray = function(arg) {
      return Object.prototype.toString.call(arg) === '[object Array]';
    };
  }

  // polyfill Object.assign
  if (typeof Object.assign !== 'function') {
    // Must be writable: true, enumerable: false, configurable: true
    Object.defineProperty(Object, "assign", {
      value: function assign(target, varArgs) { // .length of function is 2
        if (target == null) { // TypeError if undefined or null
          throw new TypeError('Cannot convert undefined or null to object');
        }

        var to = Object(target);

        for (var index = 1; index < arguments.length; index++) {
          var nextSource = arguments[index];

          if (nextSource != null) { // Skip over if undefined or null
            for (var nextKey in nextSource) {
              // Avoid bugs when hasOwnProperty is shadowed
              if (Object.prototype.hasOwnProperty.call(nextSource, nextKey)) {
                to[nextKey] = nextSource[nextKey];
              }
            }
          }
        }
        return to;
      },
      writable: true,
      configurable: true
    });
  }

  // From https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/keys
  if (!Object.keys) {
    Object.keys = (function() {
      'use strict';
      var hasOwnProperty = Object.prototype.hasOwnProperty,
          hasDontEnumBug = !({ toString: null }).propertyIsEnumerable('toString'),
          dontEnums = [
            'toString',
            'toLocaleString',
            'valueOf',
            'hasOwnProperty',
            'isPrototypeOf',
            'propertyIsEnumerable',
            'constructor'
          ],
          dontEnumsLength = dontEnums.length;

      return function(obj) {
        if (typeof obj !== 'function' && (typeof obj !== 'object' || obj === null)) {
          throw new TypeError('Object.keys called on non-object');
        }

        var result = [], prop, i;

        for (prop in obj) {
          if (hasOwnProperty.call(obj, prop)) {
            result.push(prop);
          }
        }

        if (hasDontEnumBug) {
          for (i = 0; i < dontEnumsLength; i++) {
            if (hasOwnProperty.call(obj, dontEnums[i])) {
              result.push(dontEnums[i]);
            }
          }
        }
        return result;
      };
    }());
  }

  // polyfill Array.indexOf
  if (!Array.prototype.indexOf)  Array.prototype.indexOf = (function(Object, max, min){
    "use strict";
    return function indexOf(member, fromIndex) {
      if(this===null||this===undefined)throw TypeError("Array.prototype.indexOf called on null or undefined");

      var that = Object(this), Len = that.length >>> 0, i = min(fromIndex | 0, Len);
      if (i < 0) i = max(0, Len+i); else if (i >= Len) return -1;

      if(member===void 0){ for(; i !== Len; ++i) if(that[i]===void 0 && i in that) return i; // undefined
      }else if(member !== member){   for(; i !== Len; ++i) if(that[i] !== that[i]) return i; // NaN
      }else                           for(; i !== Len; ++i) if(that[i] === member) return i; // all else

      return -1; // if the value was not found, then return -1
    };
  })(Object, Math.max, Math.min);

var array_difference, AvoAssert, InternalAvoLogger;
  array_difference = function array_difference(a1, a2) {
    var result = [];
    for (var i = 0; i < a1.length; i++) {
      if (a2.indexOf(a1[i]) === -1) {
        result.push(a1[i]);
      }
    }
    return result;
  }

  AvoAssert = {
    assertObject: function assertObject(propertyId, propName, obj) {
      if (typeof obj !== 'object') {
        var message = propName +
          ' should be of type object but you provided type ' +
          typeof obj +
          ' with value ' +
          JSON.stringify(obj);
        return [{tag: 'expectedObjectType', propertyId: propertyId, message: message, actualType: typeof obj}];
      } else {
        return [];
      }
    },

    assertString: function assertString(propertyId, propName, str) {
      if (typeof str !== 'string') {
        var message = propName +
          ' should be of type string but you provided type ' +
          typeof str +
          ' with value ' +
          JSON.stringify(str);
        return [{tag: 'expectedStringType', propertyId: propertyId, message: message, actualType: typeof str}];
      } else {
        return [];
      }
    },

    assertInt: function assertInt(propertyId, propName, int) {
      if (typeof int === 'number' && int !== Math.round(int)) {
        var message = propName +
          ' should be of type int but you provided type float with value ' +
          JSON.stringify(int);
        return [{tag: 'expectedIntType', propertyId: propertyId, actualType: 'float'}];
      } else if (typeof int !== 'number') {
        var message = propName +
          ' should be of type int but you provided type ' +
          typeof int +
          ' with value ' +
          JSON.stringify(int);
        return [{tag: 'expectedIntType', propertyId: propertyId, message: message, actualType: typeof int}];
      } else {
        return [];
      }
    },

    assertLong: function assertLong(propertyId, propName, long) {
      if (typeof long === 'number' && long !== Math.round(long)) {
        var message = propName +
          ' should be of type long but you provided type float with value ' +
          JSON.stringify(long);
        return [{tag: 'expectedLongType', propertyId: propertyId, actualType: 'float'}];
      } else if (typeof long !== 'number') {
        var message = propName +
          ' should be of type long but you provided type ' +
          typeof long +
          ' with value ' +
          JSON.stringify(long);
        return [{tag: 'expectedLongType', propertyId: propertyId, message: message, actualType: typeof long}];
      } else {
        return [];
      }
    },

    assertFloat: function assertFloat(propertyId, propName, float) {
      if (typeof float !== 'number') {
        var message = propName +
          ' should be of type float but you provided type ' +
          typeof float +
          ' with value ' +
          JSON.stringify(float);
        return [{tag: 'expectedFloatType', propertyId: propertyId, message: message, actualType: typeof float}];
      } else {
        return [];
      }
    },

    assertBool: function assertBool(propertyId, propName, bool) {
      if (typeof bool !== 'boolean') {
        var message = propName +
          ' should be of type boolean but you provided type ' +
          typeof bool +
          ' with value ' +
          JSON.stringify(bool);
        return [{tag: 'expectedBoolType', propertyId: propertyId, message: message, actualType: typeof bool}];
      } else {
        return [];
      }
    },

    assertMax: function assertMax(propertyId, propName, max, value) {
      if (value > max) {
        var message = propName +
          ' has a maximum value of ' +
          max +
          ' but you provided the value ' +
          JSON.stringify(value);
        return [{tag: 'expectedMax', propertyId: propertyId, message: message}];
      } else {
        return [];
      }
    },

    assertMin: function assertMin(propertyId, propName, min, value) {
      if (value < min) {
        var message = propName +
          ' has a minimum value of ' +
          min +
          ' but you provided the value ' +
          JSON.stringify(value);
        return [{tag: 'expectedMin', propertyId: propertyId, message: message}];
      } else {
        return [];
      }
    },

    assertList: function assertList(propertyId, propName, value) {
      if (!Array.isArray(value)) {
        var message = propName + ' should be of type list but you provided type ' + typeof value;
        return [{tag: 'expectedList', propertyId: propertyId, message: message, actualType: typeof value}];
      } else {
        return [];
      }
    },

    assertNoAdditionalProperties: function assertNoAdditionalProperties(eventName, input, spec) {
      var additionalKeys = array_difference(input, spec);
      if (additionalKeys.length) {
        var message = "Additional properties when sending event " + eventName + ": " + JSON.stringify(additionalKeys);
        return [{tag: 'expectedNoAdditionalProperties', additionalProperties: additionalKeys, message: message}];
      } else {
        return [];
      }
    },

    assertNoAdditionalUserProperties: function assertNoAdditionalProperties(eventName, input, spec) {
      var additionalKeys = array_difference(input, spec);
      if (additionalKeys.length) {
        var message = "Additional user properties when sending event " + eventName + ": " + JSON.stringify(additionalKeys);
        return [{tag: 'expectedNoAdditionalUserProperties', additionalProperties: additionalKeys, message: message}];
      } else {
        return [];
      }
    }
  };

  InternalAvoLogger = {
    logEventSent: function logEventSent(eventName, eventProperties, userProperties, groupProperties) {
      const message = "Event Sent:" + eventName + "Event Props:" + JSON.stringify(eventProperties) + "User Props:" + JSON.stringify(userProperties) + "Group Props:" + JSON.stringify(groupProperties);

      if (__AVO_LOGGER__ && __AVO_LOGGER__.logDebug && __AVO_LOGGER__.logDebug(__AVO_ENV__, message)) {
        return
      }
      typeof console !== 'undefined' && console.log("[avo] Event Sent:", eventName, "Event Props:", eventProperties, "User Props:", userProperties, "Group Props:", groupProperties);
    },

    log: function log(message) {
      if (__AVO_LOGGER__ && __AVO_LOGGER__.logDebug && __AVO_LOGGER__.logDebug(__AVO_ENV__, message)) {
        return
      }
      typeof console !== 'undefined' && console.log("[avo] " + message);
    },

    warn: function warn(message) {
      if (__AVO_LOGGER__ && __AVO_LOGGER__.logWarn && __AVO_LOGGER__.logWarn(__AVO_ENV__, message)) {
        return
      }
      typeof console !== 'undefined' && console.warn("[avo] " + message);
    },

    error: function error(message, error) {
      if (__AVO_LOGGER__ && __AVO_LOGGER__.logError && __AVO_LOGGER__.logError(__AVO_ENV__, message + error)) {
        return
      }
      typeof console !== 'undefined' && console.error("[avo] " + message, error);
    }
  };

var _avo_invoke, _avo_invoke_meta;
var _avo_sampling_rate = 1.0;
function _avo_invoke_payload(body) {
  if (typeof window === 'undefined') { return; }
  if (_avo_sampling_rate > 0) {
    if (Math.random() < _avo_sampling_rate) {
      fetch("https://api.avo.app/i", {
        method: "POST",
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify(body)
      }).then(function(res) { return res.json(); }).then(function(data) { _avo_sampling_rate = data.sa; }).catch(function() {});
    }
  }
}

_avo_invoke = function _avo_invoke(env, eventId, hash, messages, origin) {
  _avo_invoke_payload({
    "ac": "a6vvfCFvuV0jsJK8kuC2",
    "br": "8TPb5YtuF",
    "en": env,
    "ev": eventId,
    "ha": hash,
    "sc": "0muBXC3bHDRv06LDZguQ",
    "se": (new Date()).toISOString(),
    "so": "GJdEfhLTO",
    "va": messages.length === 0,
    "me": messages,
    "or": origin
  });
}

_avo_invoke_meta = function _avo_invoke_meta(env, type, messages, origin) {
  _avo_invoke_payload({
    "ac": "a6vvfCFvuV0jsJK8kuC2",
    "br": "8TPb5YtuF",
    "en": env,
    "ty": type,
    "sc": "0muBXC3bHDRv06LDZguQ",
    "se": (new Date()).toISOString(),
    "so": "GJdEfhLTO",
    "va": messages.length === 0,
    "me": messages,
    "or": origin
  });
}


var _avo_debugger_log;
var _avo_debugger_events_during_boot = [];
var _avo_debugger_ready = false;

if (typeof window !== 'undefined') {
  window.addEventListener("message", function(event) {
    if (event.origin !== "https://www.avo.app") {
      return;
    }
    var iframe = document.getElementById("avo-debugger");
    if (iframe && event && event.data && event.data.type_ === "avo-debugger-update-style") {
      iframe.style = event.data.style;
    } else if (iframe && event && event.data && event.data.type_ === "avo-debugger-ready") {
      var message = {
        type_: "avo-debugger-boot-events",
        schemaId: "0muBXC3bHDRv06LDZguQ",
        href: window.location.href,
        events: _avo_debugger_events_during_boot
      };
      _avo_debugger_events_during_boot = [];
      _avo_debugger_ready = true;
      iframe.contentWindow.postMessage(message, "https://www.avo.app/_debugger")
    }
  });
}

_avo_debugger_log = function _avo_debugger_log(eventId, eventName, messages, eventProperties, userProperties) {
  if (typeof window === 'undefined') { return; }
  var event = {
    eventId: eventId,
    eventName: eventName,
    messages: messages,
    timestamp: Date.now(),
    eventProperties: eventProperties,
    userProperties: userProperties
  };

  if (_avo_debugger_ready) {
    var message = {type_: "avo-debugger-events", events: [event]};
    document.getElementById("avo-debugger").contentWindow.postMessage(message, "https://www.avo.app/_debugger")
  } else {
    _avo_debugger_events_during_boot.push(event);
  }
}


function assertOptionalFromInstantHire(fromInstantHire, label_) {
  var messages = [];
  if (fromInstantHire !== undefined && fromInstantHire !== null) {
    messages = messages.concat(AvoAssert.assertBool("5tS_7Tzvoz", label_ ? "from_instant_hire" + ": " + label_ : "from_instant_hire", fromInstantHire));
  }
  return messages;
}

function assertOptionalHitsInstantHireTest(hitsInstantHireTest, label_) {
  var messages = [];
  if (hitsInstantHireTest !== undefined && hitsInstantHireTest !== null) {
    messages = messages.concat(AvoAssert.assertBool("GCUkFeu83P", label_ ? "hits_instant_hire_test" + ": " + label_ : "hits_instant_hire_test", hitsInstantHireTest));
  }
  return messages;
}

function assertOptionalFromSalesFunnel(fromSalesFunnel, label_) {
  var messages = [];
  if (fromSalesFunnel !== undefined && fromSalesFunnel !== null) {
    messages = messages.concat(AvoAssert.assertString("K567fw6JC4", label_ ? "from_sales_funnel" + ": " + label_ : "from_sales_funnel", fromSalesFunnel));
  }
  return messages;
}

function assertFirstName(firstName, label_) {
  var messages = [];
  messages = messages.concat(AvoAssert.assertString("OItbG6M2wP", label_ ? "first_name" + ": " + label_ : "first_name", firstName));
  return messages;
}

function assertOptionalLengthOfPlay(lengthOfPlay, label_) {
  var messages = [];
  if (lengthOfPlay !== undefined && lengthOfPlay !== null) {
    messages = messages.concat(AvoAssert.assertString("Sx89NAeGts", label_ ? "length_of_play" + ": " + label_ : "length_of_play", lengthOfPlay));
  }
  return messages;
}

function assertAuthToken(authToken, label_) {
  var messages = [];
  messages = messages.concat(AvoAssert.assertString("WI6NG3P1Cn", label_ ? "auth_token" + ": " + label_ : "auth_token", authToken));
  return messages;
}

function assertOptionalAdCampaign(adCampaign, label_) {
  var messages = [];
  if (adCampaign !== undefined && adCampaign !== null) {
    messages = messages.concat(AvoAssert.assertString("XXCi2zHCl5", label_ ? "ad_campaign" + ": " + label_ : "ad_campaign", adCampaign));
  }
  return messages;
}

function assertOptionalFromSearchResults(fromSearchResults, label_) {
  var messages = [];
  if (fromSearchResults !== undefined && fromSearchResults !== null) {
    messages = messages.concat(AvoAssert.assertBool("_wQYgqv0Fk", label_ ? "from_search_results" + ": " + label_ : "from_search_results", fromSearchResults));
  }
  return messages;
}

function assertOptionalHoursPerWeek(hoursPerWeek, label_) {
  var messages = [];
  if (hoursPerWeek !== undefined && hoursPerWeek !== null) {
    messages = messages.concat(AvoAssert.assertInt("a1lnQQ6ZN6", label_ ? "hours_per_week" + ": " + label_ : "hours_per_week", hoursPerWeek));
  }
  return messages;
}

function assertCustomDestinationPageName_(customDestinationPageName_, label_) {
  var messages = [];
  messages = messages.concat(AvoAssert.assertString("avo-enriched-type-page-custom-name", label_ ? "Custom Destination Page Name" + ": " + label_ : "Custom Destination Page Name", customDestinationPageName_));
  return messages;
}

function assertOptionalPhoneNumber(phoneNumber, label_) {
  var messages = [];
  if (phoneNumber !== undefined && phoneNumber !== null) {
    messages = messages.concat(AvoAssert.assertString("cEdKkekqmQ", label_ ? "phone_number" + ": " + label_ : "phone_number", phoneNumber));
  }
  return messages;
}

function assertOnboardingQuizStep(onboardingQuizStep, label_) {
  var messages = [];
  messages = messages.concat(AvoAssert.assertInt("cluNg0yijN", label_ ? "onboarding_quiz_step" + ": " + label_ : "onboarding_quiz_step", onboardingQuizStep));
  return messages;
}

function assertHitsNormalSalesForm(hitsNormalSalesForm, label_) {
  var messages = [];
  messages = messages.concat(AvoAssert.assertString("eH6iwGfBlk", label_ ? "hits_normal_sales_form" + ": " + label_ : "hits_normal_sales_form", hitsNormalSalesForm));
  return messages;
}

function assertOptionalLinkedinUrl(linkedinUrl, label_) {
  var messages = [];
  if (linkedinUrl !== undefined && linkedinUrl !== null) {
    messages = messages.concat(AvoAssert.assertString("jBIDhymL_U", label_ ? "linkedin_url" + ": " + label_ : "linkedin_url", linkedinUrl));
  }
  return messages;
}

function assertOptionalSpecializationKeywords(specializationKeywords, label_) {
  var messages = [];
  if (specializationKeywords !== undefined && specializationKeywords !== null) {
    messages = messages.concat(AvoAssert.assertString("mjLg485_2k", label_ ? "specialization_keywords" + ": " + label_ : "specialization_keywords", specializationKeywords));
  }
  return messages;
}

function assertOptionalWorkerSchedule(workerSchedule, label_) {
  var messages = [];
  if (workerSchedule !== undefined && workerSchedule !== null) {
    messages = messages.concat(AvoAssert.assertString("oleJGIc8ao", label_ ? "worker_schedule" + ": " + label_ : "worker_schedule", workerSchedule));
  }
  return messages;
}

function assertOptionalLastName(lastName, label_) {
  var messages = [];
  if (lastName !== undefined && lastName !== null) {
    messages = messages.concat(AvoAssert.assertString("ryNkZg3j0O", label_ ? "last_name" + ": " + label_ : "last_name", lastName));
  }
  return messages;
}

function assertEmail(email, label_) {
  var messages = [];
  messages = messages.concat(AvoAssert.assertString("sHFf98OlOX", label_ ? "email" + ": " + label_ : "email", email));
  return messages;
}

function assertOptionalFromJobRequestError(fromJobRequestError, label_) {
  var messages = [];
  if (fromJobRequestError !== undefined && fromJobRequestError !== null) {
    messages = messages.concat(AvoAssert.assertBool("sgEzDA8X11", label_ ? "from_job_request_error" + ": " + label_ : "from_job_request_error", fromJobRequestError));
  }
  return messages;
}

function assertOptionalViewedProfiles(viewedProfiles, label_) {
  var messages = [];
  if (viewedProfiles !== undefined && viewedProfiles !== null) {
    messages = messages.concat(AvoAssert.assertString("sn_GfCJoqb", label_ ? "viewed_profiles" + ": " + label_ : "viewed_profiles", viewedProfiles));
  }
  return messages;
}

function assertOptionalWorkerTasks(workerTasks, label_) {
  var messages = [];
  if (workerTasks !== undefined && workerTasks !== null) {
    messages = messages.concat(AvoAssert.assertString("uWSizjRXB2", label_ ? "worker_tasks" + ": " + label_ : "worker_tasks", workerTasks));
  }
  return messages;
}

function assertOptionalPendingHire(pendingHire, label_) {
  var messages = [];
  if (pendingHire !== undefined && pendingHire !== null) {
    messages = messages.concat(AvoAssert.assertBool("xtyrrgHcmf", label_ ? "pending_hire" + ": " + label_ : "pending_hire", pendingHire));
  }
  return messages;
}

function assertOptionalStartDate(startDate, label_) {
  var messages = [];
  if (startDate !== undefined && startDate !== null) {
    messages = messages.concat(AvoAssert.assertString("yKdOneGar8", label_ ? "start_date" + ": " + label_ : "start_date", startDate));
  }
  return messages;
}

function setAvoLogger(avoLogger) {
  __AVO_LOGGER__ = avoLogger;
}


var Rudderstack;
function setup_(options, systemProperties, RudderstackDestination,
  destinationOptions) {
  __WEB_DEBUGGER__ = false;
  if (options.validateProperties === true) {
    __AVO_DEV__ = true;
  }

  destinationOptions = destinationOptions || {};

  if (!__AVO_NOOP__) {
    if (options.useProductionKey) {
    } else {
    }

    Rudderstack = RudderstackDestination;
    Rudderstack.make(options.useProductionKey);
    if (__AVO_DEV__) {
      // debug console in Avo
      _avo_invoke_meta(__AVO_ENV__, 'setup', [], 'setup');
    }
  }
}

function initAvo(options, systemProperties, destinationOptions,
  RudderstackDestination) {
  if (__AVO_ENV__ !== null) {
    return;
  }
  __AVO_ENV__ = options.env.toLowerCase();
  if (options.avoLogger !== undefined) {
    __AVO_LOGGER__ = options.avoLogger;
  }
  if (options.noop === true) {
    __AVO_NOOP__ = true;
  }
  if (__AVO_NOOP__ && __AVO_ENV__ == 'prod') {
    InternalAvoLogger.warn("****************************************************");
    InternalAvoLogger.warn("WARNING Avo cannot be initialized in noop mode in production:");
    InternalAvoLogger.warn("- Overwriting configuration with noop=false.");
    InternalAvoLogger.warn("- Please reach out if you want to be able to run Avo in production mode with noop=true");
    InternalAvoLogger.warn("****************************************************");
    __AVO_NOOP__ = false;
  }
  if (__AVO_NOOP__) {
    InternalAvoLogger.log("****************************************************");
    InternalAvoLogger.log("Avo is now initialized in noop mode. This means:");
    InternalAvoLogger.log("- No events will be sent");
    InternalAvoLogger.log("- No network requests are made");
    InternalAvoLogger.log("****************************************************");
  }
  if (options.strict !== undefined) {
    __STRICT__ = options.strict !== false;
  }
  if (options.reportFailureAs !== undefined) {
    __REPORT_FAILURE_AS__ = options.reportFailureAs;
  }
  __WEB_DEBUGGER__ = !__AVO_NOOP__ && ((typeof window !== 'undefined' && window.location.search.indexOf("avo_debug=1") > -1) || (options.webDebugger !== false && __AVO_ENV__ !== 'prod'));
  if (!__AVO_NOOP__ && options.inspector !== undefined) {
    __INSPECTOR__ = options.inspector;
  } else if (__AVO_ENV__ !== 'prod') {
    InternalAvoLogger.warn("Avo Inspector not provided in initAvo() call");
  }
  if (__AVO_ENV__ !== 'prod') {
    __AVO_DEV__ = true;
  }

  destinationOptions = destinationOptions || {};

  if (__WEB_DEBUGGER__ && !__AVO_NOOP__) {
(function() {
  if (typeof window === 'undefined') { return; }
  var init = function() {
    if (document.getElementById("avo-debugger") === null) {
      var iframe = document.createElement("iframe");
      document.body.appendChild(iframe);
      iframe.id = "avo-debugger";
      iframe.src = "https://www.avo.app/_debugger";
      iframe.style = "display: none;";
    }
  };

  if (document.body) {
    init();
  } else {
    document.addEventListener('DOMContentLoaded', init);
  }
})();

  }
  if (!__AVO_NOOP__) {
    if (__AVO_ENV__ === 'prod') {
    }
    if (__AVO_ENV__ === 'dev') {
    }

    Rudderstack = RudderstackDestination;
    if (__AVO_ENV__ === 'prod') {
      Rudderstack.make && Rudderstack.make(__AVO_ENV__, "26OPnkhqFeZkMtuhn8czWqaQkPN");
    } else if (__AVO_ENV__ === 'dev') {
      Rudderstack.make && Rudderstack.make(__AVO_ENV__, "26OPnkhqFeZkMtuhn8czWqaQkPN");
    } else {
      console[__REPORT_FAILURE_AS__ || 'error']("[avo] No staging key is set for Rudderstack. Head to destination settings in Avo to set a staging key.");
      Rudderstack.make && Rudderstack.make(__AVO_ENV__, null);
    }
    if (__AVO_DEV__) {
      // debug console in Avo
      _avo_invoke_meta(__AVO_ENV__, 'init', [], 'init');
    }
  }
}

/**
 * VISITS_MAIN_HOMEPAGE: When a user lands on https://getmagic.com
 *
 * @param {object} properties - the properties associatied with this event
 * @param {string} properties.adCampaign - no description
 *
 * @see {@link https://www.avo.app/schemas/0muBXC3bHDRv06LDZguQ/branches/8TPb5YtuF/events/Abx4JhfS0N}
 */
export function visitsMainHomepage(properties) {
  properties = properties || {};
  if (__AVO_DEV__ || __WEB_DEBUGGER__) {
    // assert properties
    var messages = [];
    messages = messages.concat(assertOptionalAdCampaign(properties.adCampaign));
    messages = messages.concat(AvoAssert.assertNoAdditionalProperties("VISITS_MAIN_HOMEPAGE", Object.keys(properties), [
      "adCampaign"
    ]));
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "Abx4JhfS0N", "b29d9dd6a507472a5ab5887165a6873358b1f61d3ee42005aaed74e04171eaae", messages.map(function(m) { return Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType}); }), 'event');
    }

    InternalAvoLogger.logEventSent("VISITS_MAIN_HOMEPAGE", {
      "ad_campaign": properties.adCampaign,
      }, {}, {});
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("Abx4JhfS0N", "VISITS_MAIN_HOMEPAGE", messages, [
      {id: "XXCi2zHCl5", name: "ad_campaign", value: properties.adCampaign},
      ], []);
    }
    if (__AVO_DEV__ && (__STRICT__ === null || __STRICT__)) {
      // throw exception if messages is not empty
      if (messages.length !== 0) {
        throw new Error("Error sending event 'VISITS_MAIN_HOMEPAGE': " + messages[0].message)
      }
    } else {
      messages.forEach(function(m) {
        console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
      });
    }
  }

  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("VISITS_MAIN_HOMEPAGE", {
        "ad_campaign": properties.adCampaign,
        }, "Abx4JhfS0N", "b29d9dd6a507472a5ab5887165a6873358b1f61d3ee42005aaed74e04171eaae");
    }
    // destination Rudderstack
    Rudderstack.logEvent("VISITS_MAIN_HOMEPAGE", {
    "ad_campaign": properties.adCampaign,
    });
  } else {
    // do nothing
  }
}

/**
 * CLICKS_GET_STARTED_BTN: When a user clicks on the button Get Started
 *
 * @param {object} properties - the properties associatied with this event
 * @param {bool} properties.hitsInstantHireTest - Is the user in the instant hire test bucket or not? Should be yes/no.
 * @param {string} properties.hitsNormalSalesForm - no description
 * @param {string} properties.adCampaign - no description
 *
 * @see {@link https://www.avo.app/schemas/0muBXC3bHDRv06LDZguQ/branches/8TPb5YtuF/events/CVYmrxLz2P}
 */
export function clicksGetStartedBtn(properties) {
  properties = properties || {};
  if (__AVO_DEV__ || __WEB_DEBUGGER__) {
    // assert properties
    var messages = [];
    messages = messages.concat(assertOptionalHitsInstantHireTest(properties.hitsInstantHireTest));
    messages = messages.concat(assertHitsNormalSalesForm(properties.hitsNormalSalesForm));
    messages = messages.concat(assertOptionalAdCampaign(properties.adCampaign));
    messages = messages.concat(AvoAssert.assertNoAdditionalProperties("CLICKS_GET_STARTED_BTN", Object.keys(properties), [
      "hitsInstantHireTest",
      "hitsNormalSalesForm",
      "adCampaign"
    ]));
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "CVYmrxLz2P", "315d35e58cce8c21e36fc77109ed2aea8e677ab6386950ccad2e0c76dbadf33c", messages.map(function(m) { return Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType}); }), 'event');
    }

    InternalAvoLogger.logEventSent("CLICKS_GET_STARTED_BTN", {
      "hits_instant_hire_test": properties.hitsInstantHireTest,
      "hits_normal_sales_form": properties.hitsNormalSalesForm,
      "ad_campaign": properties.adCampaign,
      }, {}, {});
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("CVYmrxLz2P", "CLICKS_GET_STARTED_BTN", messages, [
      {id: "GCUkFeu83P", name: "hits_instant_hire_test", value: properties.hitsInstantHireTest},
      {id: "XXCi2zHCl5", name: "ad_campaign", value: properties.adCampaign},
      {id: "eH6iwGfBlk", name: "hits_normal_sales_form", value: properties.hitsNormalSalesForm},
      ], []);
    }
    if (__AVO_DEV__ && (__STRICT__ === null || __STRICT__)) {
      // throw exception if messages is not empty
      if (messages.length !== 0) {
        throw new Error("Error sending event 'CLICKS_GET_STARTED_BTN': " + messages[0].message)
      }
    } else {
      messages.forEach(function(m) {
        console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
      });
    }
  }

  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("CLICKS_GET_STARTED_BTN", {
        "hits_instant_hire_test": properties.hitsInstantHireTest,
        "hits_normal_sales_form": properties.hitsNormalSalesForm,
        "ad_campaign": properties.adCampaign,
        }, "CVYmrxLz2P", "315d35e58cce8c21e36fc77109ed2aea8e677ab6386950ccad2e0c76dbadf33c");
    }
    // destination Rudderstack
    Rudderstack.logEvent("CLICKS_GET_STARTED_BTN", {
    "hits_instant_hire_test": properties.hitsInstantHireTest,
    "hits_normal_sales_form": properties.hitsNormalSalesForm,
    "ad_campaign": properties.adCampaign,
    });
  } else {
    // do nothing
  }
}

/**
 * VISITS_INSTANT_MATCH_SEARCH: Views and unique users who see the initial search page of Instant Match

 *
 * @param {object} properties - the properties associatied with this event
 * @param {string} properties.customDestinationPageName_ - Name of the page or screen
 * @param {string} properties.adCampaign - no description
 *
 * @see {@link https://www.avo.app/schemas/0muBXC3bHDRv06LDZguQ/branches/8TPb5YtuF/events/iXSe6pPJrN}
 */
export function visitsInstantMatchSearch(properties) {
  properties = properties || {};
  if (__AVO_DEV__ || __WEB_DEBUGGER__) {
    // assert properties
    var messages = [];
    messages = messages.concat(assertCustomDestinationPageName_(properties.customDestinationPageName_));
    messages = messages.concat(assertOptionalAdCampaign(properties.adCampaign));
    messages = messages.concat(AvoAssert.assertNoAdditionalProperties("VISITS_INSTANT_MATCH_SEARCH", Object.keys(properties), [
      "customDestinationPageName_",
      "adCampaign"
    ]));
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "iXSe6pPJrN", "c449695158ef2becdd0a707f1220561215afeaa582038fc76a2d2466d3c9ad1f", messages.map(function(m) { return Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType}); }), 'event');
    }

    InternalAvoLogger.logEventSent("VISITS_INSTANT_MATCH_SEARCH", {
      "ad_campaign": properties.adCampaign,
      }, {}, {});
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("iXSe6pPJrN", "VISITS_INSTANT_MATCH_SEARCH", messages, [
      {id: "XXCi2zHCl5", name: "ad_campaign", value: properties.adCampaign},
      ], []);
    }
    if (__AVO_DEV__ && (__STRICT__ === null || __STRICT__)) {
      // throw exception if messages is not empty
      if (messages.length !== 0) {
        throw new Error("Error sending event 'VISITS_INSTANT_MATCH_SEARCH': " + messages[0].message)
      }
    } else {
      messages.forEach(function(m) {
        console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
      });
    }
  }

  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("VISITS_INSTANT_MATCH_SEARCH", {
        "ad_campaign": properties.adCampaign,
        }, "iXSe6pPJrN", "c449695158ef2becdd0a707f1220561215afeaa582038fc76a2d2466d3c9ad1f");
    }
    // destination Rudderstack
    Rudderstack.page ? Rudderstack.page(properties.customDestinationPageName_, {
      "ad_campaign": properties.adCampaign,
      }) : InternalAvoLogger.error('Page method is not implemented in the destination', '');
    Rudderstack.logEvent("VISITS_INSTANT_MATCH_SEARCH", {
    "ad_campaign": properties.adCampaign,
    });
  } else {
    // do nothing
  }
}

/**
 * MATCHING_NEW_SEARCH: TO MIGRATE. Number of clicks on the search for talent button.
 *
 * @param {object} properties - the properties associatied with this event
 * @param {string} properties.specializationKeywords - no description
 *
 * @see {@link https://www.avo.app/schemas/0muBXC3bHDRv06LDZguQ/branches/8TPb5YtuF/events/5SpHQ4-Zmc}
 */
export function matchingNewSearch(properties) {
  properties = properties || {};
  if (__AVO_DEV__ || __WEB_DEBUGGER__) {
    // assert properties
    var messages = [];
    messages = messages.concat(assertOptionalSpecializationKeywords(properties.specializationKeywords));
    messages = messages.concat(AvoAssert.assertNoAdditionalProperties("MATCHING_NEW_SEARCH", Object.keys(properties), [
      "specializationKeywords"
    ]));
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "5SpHQ4-Zmc", "be24294a31f41c226bbd795f6b236f8405558a764c192b5fcea6f074be2e927e", messages.map(function(m) { return Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType}); }), 'event');
    }

    InternalAvoLogger.logEventSent("MATCHING_NEW_SEARCH", {
      "specialization_keywords": properties.specializationKeywords,
      }, {}, {});
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("5SpHQ4-Zmc", "MATCHING_NEW_SEARCH", messages, [
      {id: "mjLg485_2k", name: "specialization_keywords", value: properties.specializationKeywords},
      ], []);
    }
    if (__AVO_DEV__ && (__STRICT__ === null || __STRICT__)) {
      // throw exception if messages is not empty
      if (messages.length !== 0) {
        throw new Error("Error sending event 'MATCHING_NEW_SEARCH': " + messages[0].message)
      }
    } else {
      messages.forEach(function(m) {
        console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
      });
    }
  }

  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("MATCHING_NEW_SEARCH", {
        "specialization_keywords": properties.specializationKeywords,
        }, "5SpHQ4-Zmc", "be24294a31f41c226bbd795f6b236f8405558a764c192b5fcea6f074be2e927e");
    }
    // destination Rudderstack
    Rudderstack.logEvent("MATCHING_NEW_SEARCH", {
    "specialization_keywords": properties.specializationKeywords,
    });
  } else {
    // do nothing
  }
}

/**
 * VISITS_INSTANT_MATCH_RESULTS: Results page(s) of profiles that users scroll through
 *
 * @param {object} properties - the properties associatied with this event
 * @param {string} properties.customDestinationPageName_ - Name of the page or screen
 * @param {string} properties.viewedProfiles - no description
 *
 * @see {@link https://www.avo.app/schemas/0muBXC3bHDRv06LDZguQ/branches/8TPb5YtuF/events/piNulTNsEe}
 */
export function visitsInstantMatchResults(properties) {
  properties = properties || {};
  if (__AVO_DEV__ || __WEB_DEBUGGER__) {
    // assert properties
    var messages = [];
    messages = messages.concat(assertCustomDestinationPageName_(properties.customDestinationPageName_));
    messages = messages.concat(assertOptionalViewedProfiles(properties.viewedProfiles));
    messages = messages.concat(AvoAssert.assertNoAdditionalProperties("VISITS_INSTANT_MATCH_RESULTS", Object.keys(properties), [
      "customDestinationPageName_",
      "viewedProfiles"
    ]));
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "piNulTNsEe", "7dc474bd4895b00b5c98ae74ce75f413f7a18c5fdc3c0c965e74ae65ba913f07", messages.map(function(m) { return Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType}); }), 'event');
    }

    InternalAvoLogger.logEventSent("VISITS_INSTANT_MATCH_RESULTS", {
      "viewed_profiles": properties.viewedProfiles,
      }, {}, {});
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("piNulTNsEe", "VISITS_INSTANT_MATCH_RESULTS", messages, [
      {id: "sn_GfCJoqb", name: "viewed_profiles", value: properties.viewedProfiles},
      ], []);
    }
    if (__AVO_DEV__ && (__STRICT__ === null || __STRICT__)) {
      // throw exception if messages is not empty
      if (messages.length !== 0) {
        throw new Error("Error sending event 'VISITS_INSTANT_MATCH_RESULTS': " + messages[0].message)
      }
    } else {
      messages.forEach(function(m) {
        console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
      });
    }
  }

  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("VISITS_INSTANT_MATCH_RESULTS", {
        "viewed_profiles": properties.viewedProfiles,
        }, "piNulTNsEe", "7dc474bd4895b00b5c98ae74ce75f413f7a18c5fdc3c0c965e74ae65ba913f07");
    }
    // destination Rudderstack
    Rudderstack.page ? Rudderstack.page(properties.customDestinationPageName_, {
      "viewed_profiles": properties.viewedProfiles,
      }) : InternalAvoLogger.error('Page method is not implemented in the destination', '');
    Rudderstack.logEvent("VISITS_INSTANT_MATCH_RESULTS", {
    "viewed_profiles": properties.viewedProfiles,
    });
  } else {
    // do nothing
  }
}

/**
 * MATCHING_NEXT_ASSISTANT: TO MIGRATE. When a user decides to pass on a worker and clicks on the X
 *
 * @param {object} properties - the properties associatied with this event
 * @param {string} properties.adCampaign - no description
 *
 * @see {@link https://www.avo.app/schemas/0muBXC3bHDRv06LDZguQ/branches/8TPb5YtuF/events/G7OTWbmcwq}
 */
export function matchingNextAssistant(properties) {
  properties = properties || {};
  if (__AVO_DEV__ || __WEB_DEBUGGER__) {
    // assert properties
    var messages = [];
    messages = messages.concat(assertOptionalAdCampaign(properties.adCampaign));
    messages = messages.concat(AvoAssert.assertNoAdditionalProperties("MATCHING_NEXT_ASSISTANT", Object.keys(properties), [
      "adCampaign"
    ]));
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "G7OTWbmcwq", "51dac0d7193df8da438476f7f49d529f6410ef177486d5920852c74bfa47e3dd", messages.map(function(m) { return Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType}); }), 'event');
    }

    InternalAvoLogger.logEventSent("MATCHING_NEXT_ASSISTANT", {
      "ad_campaign": properties.adCampaign,
      }, {}, {});
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("G7OTWbmcwq", "MATCHING_NEXT_ASSISTANT", messages, [
      {id: "XXCi2zHCl5", name: "ad_campaign", value: properties.adCampaign},
      ], []);
    }
    if (__AVO_DEV__ && (__STRICT__ === null || __STRICT__)) {
      // throw exception if messages is not empty
      if (messages.length !== 0) {
        throw new Error("Error sending event 'MATCHING_NEXT_ASSISTANT': " + messages[0].message)
      }
    } else {
      messages.forEach(function(m) {
        console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
      });
    }
  }

  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("MATCHING_NEXT_ASSISTANT", {
        "ad_campaign": properties.adCampaign,
        }, "G7OTWbmcwq", "51dac0d7193df8da438476f7f49d529f6410ef177486d5920852c74bfa47e3dd");
    }
    // destination Rudderstack
    Rudderstack.logEvent("MATCHING_NEXT_ASSISTANT", {
    "ad_campaign": properties.adCampaign,
    });
  } else {
    // do nothing
  }
}

/**
 * CLICKS_ACCEPT_PROFILE_BTN: User agrees and wants to work with worker by clicking on the check button
 *
 * @param {object} properties - the properties associatied with this event
 * @param {string} properties.adCampaign - no description
 *
 * @see {@link https://www.avo.app/schemas/0muBXC3bHDRv06LDZguQ/branches/8TPb5YtuF/events/qVt3xabd98}
 */
export function clicksAcceptProfileBtn(properties) {
  properties = properties || {};
  if (__AVO_DEV__ || __WEB_DEBUGGER__) {
    // assert properties
    var messages = [];
    messages = messages.concat(assertOptionalAdCampaign(properties.adCampaign));
    messages = messages.concat(AvoAssert.assertNoAdditionalProperties("CLICKS_ACCEPT_PROFILE_BTN", Object.keys(properties), [
      "adCampaign"
    ]));
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "qVt3xabd98", "049d8a8351f5e6d3deb8daf4948c33b17c1ea250ab20540075db4b743fd18fa5", messages.map(function(m) { return Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType}); }), 'event');
    }

    InternalAvoLogger.logEventSent("CLICKS_ACCEPT_PROFILE_BTN", {
      "ad_campaign": properties.adCampaign,
      }, {}, {});
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("qVt3xabd98", "CLICKS_ACCEPT_PROFILE_BTN", messages, [
      {id: "XXCi2zHCl5", name: "ad_campaign", value: properties.adCampaign},
      ], []);
    }
    if (__AVO_DEV__ && (__STRICT__ === null || __STRICT__)) {
      // throw exception if messages is not empty
      if (messages.length !== 0) {
        throw new Error("Error sending event 'CLICKS_ACCEPT_PROFILE_BTN': " + messages[0].message)
      }
    } else {
      messages.forEach(function(m) {
        console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
      });
    }
  }

  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("CLICKS_ACCEPT_PROFILE_BTN", {
        "ad_campaign": properties.adCampaign,
        }, "qVt3xabd98", "049d8a8351f5e6d3deb8daf4948c33b17c1ea250ab20540075db4b743fd18fa5");
    }
    // destination Rudderstack
    Rudderstack.logEvent("CLICKS_ACCEPT_PROFILE_BTN", {
    "ad_campaign": properties.adCampaign,
    });
  } else {
    // do nothing
  }
}

/**
 * CLICKS_PROFILE_VIDEO: When a user clicks and plays the worker's video on their profile
 *
 * @param {object} properties - the properties associatied with this event
 * @param {string} properties.lengthOfPlay - no description
 *
 * @see {@link https://www.avo.app/schemas/0muBXC3bHDRv06LDZguQ/branches/8TPb5YtuF/events/hTmXuYcrXi}
 */
export function clicksProfileVideo(properties) {
  properties = properties || {};
  if (__AVO_DEV__ || __WEB_DEBUGGER__) {
    // assert properties
    var messages = [];
    messages = messages.concat(assertOptionalLengthOfPlay(properties.lengthOfPlay));
    messages = messages.concat(AvoAssert.assertNoAdditionalProperties("CLICKS_PROFILE_VIDEO", Object.keys(properties), [
      "lengthOfPlay"
    ]));
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "hTmXuYcrXi", "d9b560039546301dfab5d2b87715c7c5b49d2595220da5c0e09e7f347ef833d7", messages.map(function(m) { return Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType}); }), 'event');
    }

    InternalAvoLogger.logEventSent("CLICKS_PROFILE_VIDEO", {
      "length_of_play": properties.lengthOfPlay,
      }, {}, {});
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("hTmXuYcrXi", "CLICKS_PROFILE_VIDEO", messages, [
      {id: "Sx89NAeGts", name: "length_of_play", value: properties.lengthOfPlay},
      ], []);
    }
    if (__AVO_DEV__ && (__STRICT__ === null || __STRICT__)) {
      // throw exception if messages is not empty
      if (messages.length !== 0) {
        throw new Error("Error sending event 'CLICKS_PROFILE_VIDEO': " + messages[0].message)
      }
    } else {
      messages.forEach(function(m) {
        console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
      });
    }
  }

  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("CLICKS_PROFILE_VIDEO", {
        "length_of_play": properties.lengthOfPlay,
        }, "hTmXuYcrXi", "d9b560039546301dfab5d2b87715c7c5b49d2595220da5c0e09e7f347ef833d7");
    }
    // destination Rudderstack
    Rudderstack.logEvent("CLICKS_PROFILE_VIDEO", {
    "length_of_play": properties.lengthOfPlay,
    });
  } else {
    // do nothing
  }
}

/**
 * VISITS_JOB_REQUEST_FORM: When a potential client lands on the form to request an available assistant
 *
 * @see {@link https://www.avo.app/schemas/0muBXC3bHDRv06LDZguQ/branches/8TPb5YtuF/events/V3EdQm06TO}
 */
export function visitsJobRequestForm(properties) {
  properties = properties || {};
  if (__AVO_DEV__ || __WEB_DEBUGGER__) {
    // assert properties
    var messages = [];
    messages = messages.concat(AvoAssert.assertNoAdditionalProperties("VISITS_JOB_REQUEST_FORM", Object.keys(properties), [
    ]));
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "V3EdQm06TO", "5c1a69561e4140f6060a5b992ebcfbe3b9e6c8d77b3c0fbbcccb3f58f73bae5d", messages.map(function(m) { return Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType}); }), 'event');
    }

    InternalAvoLogger.logEventSent("VISITS_JOB_REQUEST_FORM", {}, {}, {});
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("V3EdQm06TO", "VISITS_JOB_REQUEST_FORM", messages, [], []);
    }
    if (__AVO_DEV__ && (__STRICT__ === null || __STRICT__)) {
      // throw exception if messages is not empty
      if (messages.length !== 0) {
        throw new Error("Error sending event 'VISITS_JOB_REQUEST_FORM': " + messages[0].message)
      }
    } else {
      messages.forEach(function(m) {
        console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
      });
    }
  }

  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("VISITS_JOB_REQUEST_FORM", {}, "V3EdQm06TO", "5c1a69561e4140f6060a5b992ebcfbe3b9e6c8d77b3c0fbbcccb3f58f73bae5d");
    }
    // destination Rudderstack
    Rudderstack.logEvent("VISITS_JOB_REQUEST_FORM", {});
  } else {
    // do nothing
  }
}

/**
 * CLICKS_REQUEST_WORKER_BTN: When a user clicks on the button Request Worker to send their inputed information to Magic
 *
 * @param {object} properties - the properties associatied with this event
 * @param {string} properties.startDate - desired start date by client
 * @param {int} properties.hoursPerWeek - hours client needs assistant to work per week
 * @param {string} properties.specializationKeywords - no description
 * @param {string} properties.workerTasks - no description
 * @param {string} properties.workerSchedule - no description
 * @param {string} properties.linkedinUrl - no description
 *
 * @see {@link https://www.avo.app/schemas/0muBXC3bHDRv06LDZguQ/branches/8TPb5YtuF/events/q1HfxBhnoe}
 */
export function clicksRequestWorkerBtn(properties) {
  properties = properties || {};
  if (__AVO_DEV__ || __WEB_DEBUGGER__) {
    // assert properties
    var messages = [];
    messages = messages.concat(assertOptionalStartDate(properties.startDate));
    messages = messages.concat(assertOptionalHoursPerWeek(properties.hoursPerWeek));
    messages = messages.concat(assertOptionalSpecializationKeywords(properties.specializationKeywords));
    messages = messages.concat(assertOptionalWorkerTasks(properties.workerTasks));
    messages = messages.concat(assertOptionalWorkerSchedule(properties.workerSchedule));
    messages = messages.concat(assertOptionalLinkedinUrl(properties.linkedinUrl));
    messages = messages.concat(AvoAssert.assertNoAdditionalProperties("CLICKS_REQUEST_WORKER_BTN", Object.keys(properties), [
      "startDate",
      "hoursPerWeek",
      "specializationKeywords",
      "workerTasks",
      "workerSchedule",
      "linkedinUrl"
    ]));
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "q1HfxBhnoe", "c502290641ce46f1889cbafe06e1afe6bbec601e9952e25a670fd4db7f6ab3ca", messages.map(function(m) { return Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType}); }), 'event');
    }

    InternalAvoLogger.logEventSent("CLICKS_REQUEST_WORKER_BTN", {
      "start_date": properties.startDate,
      "hours_per_week": properties.hoursPerWeek,
      "specialization_keywords": properties.specializationKeywords,
      "worker_tasks": properties.workerTasks,
      "worker_schedule": properties.workerSchedule,
      "linkedin_url": properties.linkedinUrl,
      }, {}, {});
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("q1HfxBhnoe", "CLICKS_REQUEST_WORKER_BTN", messages, [
      {id: "a1lnQQ6ZN6", name: "hours_per_week", value: properties.hoursPerWeek},
      {id: "jBIDhymL_U", name: "linkedin_url", value: properties.linkedinUrl},
      {id: "mjLg485_2k", name: "specialization_keywords", value: properties.specializationKeywords},
      {id: "oleJGIc8ao", name: "worker_schedule", value: properties.workerSchedule},
      {id: "uWSizjRXB2", name: "worker_tasks", value: properties.workerTasks},
      {id: "yKdOneGar8", name: "start_date", value: properties.startDate},
      ], []);
    }
    if (__AVO_DEV__ && (__STRICT__ === null || __STRICT__)) {
      // throw exception if messages is not empty
      if (messages.length !== 0) {
        throw new Error("Error sending event 'CLICKS_REQUEST_WORKER_BTN': " + messages[0].message)
      }
    } else {
      messages.forEach(function(m) {
        console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
      });
    }
  }

  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("CLICKS_REQUEST_WORKER_BTN", {
        "start_date": properties.startDate,
        "hours_per_week": properties.hoursPerWeek,
        "specialization_keywords": properties.specializationKeywords,
        "worker_tasks": properties.workerTasks,
        "worker_schedule": properties.workerSchedule,
        "linkedin_url": properties.linkedinUrl,
        }, "q1HfxBhnoe", "c502290641ce46f1889cbafe06e1afe6bbec601e9952e25a670fd4db7f6ab3ca");
    }
    // destination Rudderstack
    Rudderstack.logEvent("CLICKS_REQUEST_WORKER_BTN", {
    "start_date": properties.startDate,
    "hours_per_week": properties.hoursPerWeek,
    "specialization_keywords": properties.specializationKeywords,
    "worker_tasks": properties.workerTasks,
    "worker_schedule": properties.workerSchedule,
    "linkedin_url": properties.linkedinUrl,
    });
  } else {
    // do nothing
  }
}

/**
 * CLICKS_BACK_JOB_REQUEST: User who click out of a job request and back to the worker profile
 *
 * @see {@link https://www.avo.app/schemas/0muBXC3bHDRv06LDZguQ/branches/8TPb5YtuF/events/9o5EqyYJ8-}
 */
export function clicksBackJobRequest(properties) {
  properties = properties || {};
  if (__AVO_DEV__ || __WEB_DEBUGGER__) {
    // assert properties
    var messages = [];
    messages = messages.concat(AvoAssert.assertNoAdditionalProperties("CLICKS_BACK_JOB_REQUEST", Object.keys(properties), [
    ]));
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "9o5EqyYJ8-", "3dd127fbdf8afa1de7dabaf54ae6e5fc9248f5feb39be248a3d792b926f5a095", messages.map(function(m) { return Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType}); }), 'event');
    }

    InternalAvoLogger.logEventSent("CLICKS_BACK_JOB_REQUEST", {}, {}, {});
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("9o5EqyYJ8-", "CLICKS_BACK_JOB_REQUEST", messages, [], []);
    }
    if (__AVO_DEV__ && (__STRICT__ === null || __STRICT__)) {
      // throw exception if messages is not empty
      if (messages.length !== 0) {
        throw new Error("Error sending event 'CLICKS_BACK_JOB_REQUEST': " + messages[0].message)
      }
    } else {
      messages.forEach(function(m) {
        console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
      });
    }
  }

  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("CLICKS_BACK_JOB_REQUEST", {}, "9o5EqyYJ8-", "3dd127fbdf8afa1de7dabaf54ae6e5fc9248f5feb39be248a3d792b926f5a095");
    }
    // destination Rudderstack
    Rudderstack.logEvent("CLICKS_BACK_JOB_REQUEST", {});
  } else {
    // do nothing
  }
}

/**
 * CLICKS_BACK_PROFILE: Users who go back to search from a profile result
 *
 * @see {@link https://www.avo.app/schemas/0muBXC3bHDRv06LDZguQ/branches/8TPb5YtuF/events/Nt27wluTZ8}
 */
export function clicksBackProfile(properties) {
  properties = properties || {};
  if (__AVO_DEV__ || __WEB_DEBUGGER__) {
    // assert properties
    var messages = [];
    messages = messages.concat(AvoAssert.assertNoAdditionalProperties("CLICKS_BACK_PROFILE", Object.keys(properties), [
    ]));
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "Nt27wluTZ8", "23f43f73e322485427616ed04012a10c7b1c4126ed7be019f0dadef44389c0c4", messages.map(function(m) { return Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType}); }), 'event');
    }

    InternalAvoLogger.logEventSent("CLICKS_BACK_PROFILE", {}, {}, {});
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("Nt27wluTZ8", "CLICKS_BACK_PROFILE", messages, [], []);
    }
    if (__AVO_DEV__ && (__STRICT__ === null || __STRICT__)) {
      // throw exception if messages is not empty
      if (messages.length !== 0) {
        throw new Error("Error sending event 'CLICKS_BACK_PROFILE': " + messages[0].message)
      }
    } else {
      messages.forEach(function(m) {
        console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
      });
    }
  }

  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("CLICKS_BACK_PROFILE", {}, "Nt27wluTZ8", "23f43f73e322485427616ed04012a10c7b1c4126ed7be019f0dadef44389c0c4");
    }
    // destination Rudderstack
    Rudderstack.logEvent("CLICKS_BACK_PROFILE", {});
  } else {
    // do nothing
  }
}

/**
 * CLICKS_BACK_SEARCH: Users who exit from Instant Hire search to go back to the main homepage
 *
 * @see {@link https://www.avo.app/schemas/0muBXC3bHDRv06LDZguQ/branches/8TPb5YtuF/events/8gWvE2yX2D}
 */
export function clicksBackSearch(properties) {
  properties = properties || {};
  if (__AVO_DEV__ || __WEB_DEBUGGER__) {
    // assert properties
    var messages = [];
    messages = messages.concat(AvoAssert.assertNoAdditionalProperties("CLICKS_BACK_SEARCH", Object.keys(properties), [
    ]));
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "8gWvE2yX2D", "48cf7de3da1eda9c3527fcc48ce4c7dd157dc6c2882c7e1df99035dd9a8e5601", messages.map(function(m) { return Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType}); }), 'event');
    }

    InternalAvoLogger.logEventSent("CLICKS_BACK_SEARCH", {}, {}, {});
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("8gWvE2yX2D", "CLICKS_BACK_SEARCH", messages, [], []);
    }
    if (__AVO_DEV__ && (__STRICT__ === null || __STRICT__)) {
      // throw exception if messages is not empty
      if (messages.length !== 0) {
        throw new Error("Error sending event 'CLICKS_BACK_SEARCH': " + messages[0].message)
      }
    } else {
      messages.forEach(function(m) {
        console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
      });
    }
  }

  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("CLICKS_BACK_SEARCH", {}, "8gWvE2yX2D", "48cf7de3da1eda9c3527fcc48ce4c7dd157dc6c2882c7e1df99035dd9a8e5601");
    }
    // destination Rudderstack
    Rudderstack.logEvent("CLICKS_BACK_SEARCH", {});
  } else {
    // do nothing
  }
}

/**
 * CLICKS_SPEAK_TO_SALES: Users who opt to talk to sales instead of finding workers via Instant Search
 *
 * @param {object} properties - the properties associatied with this event
 * @param {bool} properties.fromSearchResults - no description
 * @param {bool} properties.fromJobRequestError - no description
 *
 * @see {@link https://www.avo.app/schemas/0muBXC3bHDRv06LDZguQ/branches/8TPb5YtuF/events/3C-dNENDSh}
 */
export function clicksSpeakToSales(properties) {
  properties = properties || {};
  if (__AVO_DEV__ || __WEB_DEBUGGER__) {
    // assert properties
    var messages = [];
    messages = messages.concat(assertOptionalFromSearchResults(properties.fromSearchResults));
    messages = messages.concat(assertOptionalFromJobRequestError(properties.fromJobRequestError));
    messages = messages.concat(AvoAssert.assertNoAdditionalProperties("CLICKS_SPEAK_TO_SALES", Object.keys(properties), [
      "fromSearchResults",
      "fromJobRequestError"
    ]));
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "3C-dNENDSh", "bab34b131c2e9e1e8fc3432f0a8d24670b742aad3d22d57d4795584da4ff1f8d", messages.map(function(m) { return Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType}); }), 'event');
    }

    InternalAvoLogger.logEventSent("CLICKS_SPEAK_TO_SALES", {
      "from_search_results": properties.fromSearchResults,
      "from_job_request_error": properties.fromJobRequestError,
      }, {}, {});
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("3C-dNENDSh", "CLICKS_SPEAK_TO_SALES", messages, [
      {id: "_wQYgqv0Fk", name: "from_search_results", value: properties.fromSearchResults},
      {id: "sgEzDA8X11", name: "from_job_request_error", value: properties.fromJobRequestError},
      ], []);
    }
    if (__AVO_DEV__ && (__STRICT__ === null || __STRICT__)) {
      // throw exception if messages is not empty
      if (messages.length !== 0) {
        throw new Error("Error sending event 'CLICKS_SPEAK_TO_SALES': " + messages[0].message)
      }
    } else {
      messages.forEach(function(m) {
        console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
      });
    }
  }

  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("CLICKS_SPEAK_TO_SALES", {
        "from_search_results": properties.fromSearchResults,
        "from_job_request_error": properties.fromJobRequestError,
        }, "3C-dNENDSh", "bab34b131c2e9e1e8fc3432f0a8d24670b742aad3d22d57d4795584da4ff1f8d");
    }
    // destination Rudderstack
    Rudderstack.logEvent("CLICKS_SPEAK_TO_SALES", {
    "from_search_results": properties.fromSearchResults,
    "from_job_request_error": properties.fromJobRequestError,
    });
  } else {
    // do nothing
  }
}

/**
 * VISITS_SIGN_UP: Users who view the Sign Up Screen
 *
 * @param {object} properties - the properties associatied with this event
 * @param {string} properties.customDestinationPageName_ - Name of the page or screen
 *
 * @see {@link https://www.avo.app/schemas/0muBXC3bHDRv06LDZguQ/branches/8TPb5YtuF/events/zyWTRI_oJF}
 */
export function visitsSignUp(properties) {
  properties = properties || {};
  if (__AVO_DEV__ || __WEB_DEBUGGER__) {
    // assert properties
    var messages = [];
    messages = messages.concat(assertCustomDestinationPageName_(properties.customDestinationPageName_));
    messages = messages.concat(AvoAssert.assertNoAdditionalProperties("VISITS_SIGN_UP", Object.keys(properties), [
      "customDestinationPageName_"
    ]));
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "zyWTRI_oJF", "bc5025e758ea5558a06f5bf83780be3517d4a173cf204a920d18799f690f13bb", messages.map(function(m) { return Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType}); }), 'event');
    }

    InternalAvoLogger.logEventSent("VISITS_SIGN_UP", {}, {}, {});
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("zyWTRI_oJF", "VISITS_SIGN_UP", messages, [], []);
    }
    if (__AVO_DEV__ && (__STRICT__ === null || __STRICT__)) {
      // throw exception if messages is not empty
      if (messages.length !== 0) {
        throw new Error("Error sending event 'VISITS_SIGN_UP': " + messages[0].message)
      }
    } else {
      messages.forEach(function(m) {
        console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
      });
    }
  }

  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("VISITS_SIGN_UP", {}, "zyWTRI_oJF", "bc5025e758ea5558a06f5bf83780be3517d4a173cf204a920d18799f690f13bb");
    }
    // destination Rudderstack
    Rudderstack.page ? Rudderstack.page(properties.customDestinationPageName_, {}) : InternalAvoLogger.error('Page method is not implemented in the destination', '');
    Rudderstack.logEvent("VISITS_SIGN_UP", {});
  } else {
    // do nothing
  }
}

/**
 * CLICKS_LOGIN_NOW: Existing users who click login now on the Sign Up Screen
 *
 * @see {@link https://www.avo.app/schemas/0muBXC3bHDRv06LDZguQ/branches/8TPb5YtuF/events/b2rcrlJnpK}
 */
export function clicksLoginNow(properties) {
  properties = properties || {};
  if (__AVO_DEV__ || __WEB_DEBUGGER__) {
    // assert properties
    var messages = [];
    messages = messages.concat(AvoAssert.assertNoAdditionalProperties("CLICKS_LOGIN_NOW", Object.keys(properties), [
    ]));
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "b2rcrlJnpK", "d027fd59edde25fcb8704f879afd90a4a17fc8451cf049deac542ef6d643bdb3", messages.map(function(m) { return Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType}); }), 'event');
    }

    InternalAvoLogger.logEventSent("CLICKS_LOGIN_NOW", {}, {}, {});
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("b2rcrlJnpK", "CLICKS_LOGIN_NOW", messages, [], []);
    }
    if (__AVO_DEV__ && (__STRICT__ === null || __STRICT__)) {
      // throw exception if messages is not empty
      if (messages.length !== 0) {
        throw new Error("Error sending event 'CLICKS_LOGIN_NOW': " + messages[0].message)
      }
    } else {
      messages.forEach(function(m) {
        console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
      });
    }
  }

  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("CLICKS_LOGIN_NOW", {}, "b2rcrlJnpK", "d027fd59edde25fcb8704f879afd90a4a17fc8451cf049deac542ef6d643bdb3");
    }
    // destination Rudderstack
    Rudderstack.logEvent("CLICKS_LOGIN_NOW", {});
  } else {
    // do nothing
  }
}

/**
 * CLICKS_LOGIN_BTN: Users who click to login
 *
 * @param {object} properties - the properties associatied with this event
 * @param {bool} properties.fromInstantHire - no description
 * @param {string} properties.fromSalesFunnel - no description
 * @param {string} properties.email - no description
 *
 * @see {@link https://www.avo.app/schemas/0muBXC3bHDRv06LDZguQ/branches/8TPb5YtuF/events/Eo_re06Tbn}
 */
export function clicksLoginBtn(properties) {
  properties = properties || {};
  if (__AVO_DEV__ || __WEB_DEBUGGER__) {
    // assert properties
    var messages = [];
    messages = messages.concat(assertOptionalFromInstantHire(properties.fromInstantHire));
    messages = messages.concat(assertOptionalFromSalesFunnel(properties.fromSalesFunnel));
    messages = messages.concat(assertEmail(properties.email));
    messages = messages.concat(AvoAssert.assertNoAdditionalProperties("CLICKS_LOGIN_BTN", Object.keys(properties), [
      "fromInstantHire",
      "fromSalesFunnel",
      "email"
    ]));
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "Eo_re06Tbn", "8091f891be0882b17726becd9c805e62de4c4742ae78cce0e3cd02dd7ff872ca", messages.map(function(m) { return Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType}); }), 'event');
    }

    InternalAvoLogger.logEventSent("CLICKS_LOGIN_BTN", {
      "from_instant_hire": properties.fromInstantHire,
      "from_sales_funnel": properties.fromSalesFunnel,
      "email": properties.email,
      }, {}, {});
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("Eo_re06Tbn", "CLICKS_LOGIN_BTN", messages, [
      {id: "5tS_7Tzvoz", name: "from_instant_hire", value: properties.fromInstantHire},
      {id: "K567fw6JC4", name: "from_sales_funnel", value: properties.fromSalesFunnel},
      {id: "sHFf98OlOX", name: "email", value: properties.email},
      ], []);
    }
    if (__AVO_DEV__ && (__STRICT__ === null || __STRICT__)) {
      // throw exception if messages is not empty
      if (messages.length !== 0) {
        throw new Error("Error sending event 'CLICKS_LOGIN_BTN': " + messages[0].message)
      }
    } else {
      messages.forEach(function(m) {
        console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
      });
    }
  }

  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("CLICKS_LOGIN_BTN", {
        "from_instant_hire": properties.fromInstantHire,
        "from_sales_funnel": properties.fromSalesFunnel,
        "email": properties.email,
        }, "Eo_re06Tbn", "8091f891be0882b17726becd9c805e62de4c4742ae78cce0e3cd02dd7ff872ca");
    }
    // destination Rudderstack
    Rudderstack.logEvent("CLICKS_LOGIN_BTN", {
    "from_instant_hire": properties.fromInstantHire,
    "from_sales_funnel": properties.fromSalesFunnel,
    "email": properties.email,
    });
  } else {
    // do nothing
  }
}

/**
 * CLICKS_CREATE_ACCOUNT_BTN: Client who signs up and creates a Portal account
 *
 * @param {object} properties - the properties associatied with this event
 * @param {string} properties.adCampaign - no description
 * @param {string} properties.firstName - no description
 * @param {string} properties.lastName - no description
 * @param {string} properties.email - no description
 * @param {string} properties.phoneNumber - no description
 *
 * @see {@link https://www.avo.app/schemas/0muBXC3bHDRv06LDZguQ/branches/8TPb5YtuF/events/dTZJJlK-b}
 */
export function clicksCreateAccountBtn(properties) {
  properties = properties || {};
  if (__AVO_DEV__ || __WEB_DEBUGGER__) {
    // assert properties
    var messages = [];
    messages = messages.concat(assertOptionalAdCampaign(properties.adCampaign));
    messages = messages.concat(assertFirstName(properties.firstName));
    messages = messages.concat(assertOptionalLastName(properties.lastName));
    messages = messages.concat(assertEmail(properties.email));
    messages = messages.concat(assertOptionalPhoneNumber(properties.phoneNumber));
    messages = messages.concat(AvoAssert.assertNoAdditionalProperties("CLICKS_CREATE_ACCOUNT_BTN", Object.keys(properties), [
      "adCampaign",
      "firstName",
      "lastName",
      "email",
      "phoneNumber"
    ]));
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "dTZJJlK-b", "f17644ae19d9a828cde839564523a72716c014dc2bfd902ffaa55ce7bbd9a2bc", messages.map(function(m) { return Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType}); }), 'event');
    }

    InternalAvoLogger.logEventSent("CLICKS_CREATE_ACCOUNT_BTN", {
      "ad_campaign": properties.adCampaign,
      "first_name": properties.firstName,
      "last_name": properties.lastName,
      "email": properties.email,
      "phone_number": properties.phoneNumber,
      }, {}, {});
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("dTZJJlK-b", "CLICKS_CREATE_ACCOUNT_BTN", messages, [
      {id: "OItbG6M2wP", name: "first_name", value: properties.firstName},
      {id: "XXCi2zHCl5", name: "ad_campaign", value: properties.adCampaign},
      {id: "cEdKkekqmQ", name: "phone_number", value: properties.phoneNumber},
      {id: "ryNkZg3j0O", name: "last_name", value: properties.lastName},
      {id: "sHFf98OlOX", name: "email", value: properties.email},
      ], []);
    }
    if (__AVO_DEV__ && (__STRICT__ === null || __STRICT__)) {
      // throw exception if messages is not empty
      if (messages.length !== 0) {
        throw new Error("Error sending event 'CLICKS_CREATE_ACCOUNT_BTN': " + messages[0].message)
      }
    } else {
      messages.forEach(function(m) {
        console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
      });
    }
  }

  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("CLICKS_CREATE_ACCOUNT_BTN", {
        "ad_campaign": properties.adCampaign,
        "first_name": properties.firstName,
        "last_name": properties.lastName,
        "email": properties.email,
        "phone_number": properties.phoneNumber,
        }, "dTZJJlK-b", "f17644ae19d9a828cde839564523a72716c014dc2bfd902ffaa55ce7bbd9a2bc");
    }
    // destination Rudderstack
    Rudderstack.logEvent("CLICKS_CREATE_ACCOUNT_BTN", {
    "ad_campaign": properties.adCampaign,
    "first_name": properties.firstName,
    "last_name": properties.lastName,
    "email": properties.email,
    "phone_number": properties.phoneNumber,
    });
  } else {
    // do nothing
  }
}

/**
 * VISITS_PAYMENT_INFO: Clients who sees the credit card entry screen
 *
 * @param {object} properties - the properties associatied with this event
 * @param {string} properties.customDestinationPageName_ - Name of the page or screen
 * @param {string} properties.adCampaign - no description
 *
 * @see {@link https://www.avo.app/schemas/0muBXC3bHDRv06LDZguQ/branches/8TPb5YtuF/events/2-pxY6qDCC}
 */
export function visitsPaymentInfo(properties) {
  properties = properties || {};
  if (__AVO_DEV__ || __WEB_DEBUGGER__) {
    // assert properties
    var messages = [];
    messages = messages.concat(assertCustomDestinationPageName_(properties.customDestinationPageName_));
    messages = messages.concat(assertOptionalAdCampaign(properties.adCampaign));
    messages = messages.concat(AvoAssert.assertNoAdditionalProperties("VISITS_PAYMENT_INFO", Object.keys(properties), [
      "customDestinationPageName_",
      "adCampaign"
    ]));
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "2-pxY6qDCC", "17583c69318f9112bb34cd199e7e0c3484aa3e87aa63b117d7b9fa419aa5e8d6", messages.map(function(m) { return Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType}); }), 'event');
    }

    InternalAvoLogger.logEventSent("VISITS_PAYMENT_INFO", {
      "ad_campaign": properties.adCampaign,
      }, {}, {});
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("2-pxY6qDCC", "VISITS_PAYMENT_INFO", messages, [
      {id: "XXCi2zHCl5", name: "ad_campaign", value: properties.adCampaign},
      ], []);
    }
    if (__AVO_DEV__ && (__STRICT__ === null || __STRICT__)) {
      // throw exception if messages is not empty
      if (messages.length !== 0) {
        throw new Error("Error sending event 'VISITS_PAYMENT_INFO': " + messages[0].message)
      }
    } else {
      messages.forEach(function(m) {
        console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
      });
    }
  }

  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("VISITS_PAYMENT_INFO", {
        "ad_campaign": properties.adCampaign,
        }, "2-pxY6qDCC", "17583c69318f9112bb34cd199e7e0c3484aa3e87aa63b117d7b9fa419aa5e8d6");
    }
    // destination Rudderstack
    Rudderstack.page ? Rudderstack.page(properties.customDestinationPageName_, {
      "ad_campaign": properties.adCampaign,
      }) : InternalAvoLogger.error('Page method is not implemented in the destination', '');
    Rudderstack.logEvent("VISITS_PAYMENT_INFO", {
    "ad_campaign": properties.adCampaign,
    });
  } else {
    // do nothing
  }
}

/**
 * CLICKS_CONFIRM_REQUEST_BTN: Users who input their credit card and wants to proceed with the job request
 *
 * @param {object} properties - the properties associatied with this event
 * @param {string} properties.adCampaign - no description
 *
 * @see {@link https://www.avo.app/schemas/0muBXC3bHDRv06LDZguQ/branches/8TPb5YtuF/events/k-qD8G-pNt}
 */
export function clicksConfirmRequestBtn(properties) {
  properties = properties || {};
  if (__AVO_DEV__ || __WEB_DEBUGGER__) {
    // assert properties
    var messages = [];
    messages = messages.concat(assertOptionalAdCampaign(properties.adCampaign));
    messages = messages.concat(AvoAssert.assertNoAdditionalProperties("CLICKS_CONFIRM_REQUEST_BTN", Object.keys(properties), [
      "adCampaign"
    ]));
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "k-qD8G-pNt", "616c1e5562f581f0df691661bd920f7e1d1ff841225e5d6fdfee6645cef378de", messages.map(function(m) { return Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType}); }), 'event');
    }

    InternalAvoLogger.logEventSent("CLICKS_CONFIRM_REQUEST_BTN", {
      "ad_campaign": properties.adCampaign,
      }, {}, {});
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("k-qD8G-pNt", "CLICKS_CONFIRM_REQUEST_BTN", messages, [
      {id: "XXCi2zHCl5", name: "ad_campaign", value: properties.adCampaign},
      ], []);
    }
    if (__AVO_DEV__ && (__STRICT__ === null || __STRICT__)) {
      // throw exception if messages is not empty
      if (messages.length !== 0) {
        throw new Error("Error sending event 'CLICKS_CONFIRM_REQUEST_BTN': " + messages[0].message)
      }
    } else {
      messages.forEach(function(m) {
        console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
      });
    }
  }

  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("CLICKS_CONFIRM_REQUEST_BTN", {
        "ad_campaign": properties.adCampaign,
        }, "k-qD8G-pNt", "616c1e5562f581f0df691661bd920f7e1d1ff841225e5d6fdfee6645cef378de");
    }
    // destination Rudderstack
    Rudderstack.logEvent("CLICKS_CONFIRM_REQUEST_BTN", {
    "ad_campaign": properties.adCampaign,
    });
  } else {
    // do nothing
  }
}

/**
 * CLICKS_LEARN_MORE_BILLING: Clients who are interested in learning more about how Magic bills
 *
 * @see {@link https://www.avo.app/schemas/0muBXC3bHDRv06LDZguQ/branches/8TPb5YtuF/events/SN59DFOSwb}
 */
export function clicksLearnMoreBilling(properties) {
  properties = properties || {};
  if (__AVO_DEV__ || __WEB_DEBUGGER__) {
    // assert properties
    var messages = [];
    messages = messages.concat(AvoAssert.assertNoAdditionalProperties("CLICKS_LEARN_MORE_BILLING", Object.keys(properties), [
    ]));
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "SN59DFOSwb", "c345bf935d57cedca1f6037e11e1a83b6b849176a9fd8e5d492dc79e7fadf8cd", messages.map(function(m) { return Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType}); }), 'event');
    }

    InternalAvoLogger.logEventSent("CLICKS_LEARN_MORE_BILLING", {}, {}, {});
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("SN59DFOSwb", "CLICKS_LEARN_MORE_BILLING", messages, [], []);
    }
    if (__AVO_DEV__ && (__STRICT__ === null || __STRICT__)) {
      // throw exception if messages is not empty
      if (messages.length !== 0) {
        throw new Error("Error sending event 'CLICKS_LEARN_MORE_BILLING': " + messages[0].message)
      }
    } else {
      messages.forEach(function(m) {
        console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
      });
    }
  }

  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("CLICKS_LEARN_MORE_BILLING", {}, "SN59DFOSwb", "c345bf935d57cedca1f6037e11e1a83b6b849176a9fd8e5d492dc79e7fadf8cd");
    }
    // destination Rudderstack
    Rudderstack.logEvent("CLICKS_LEARN_MORE_BILLING", {});
  } else {
    // do nothing
  }
}

/**
 * VISITS_PORTAL_DEFAULT: Users who land on the default view of the portal
 *
 * @param {object} properties - the properties associatied with this event
 * @param {string} properties.customDestinationPageName_ - Name of the page or screen
 * @param {bool} properties.hitsInstantHireTest - Is the user in the instant hire test bucket or not? Should be yes/no.
 * @param {bool} properties.pendingHire - worker profile displayed as pending hire when client has requested their services
 *
 * @see {@link https://www.avo.app/schemas/0muBXC3bHDRv06LDZguQ/branches/8TPb5YtuF/events/z8ridTX4fb}
 */
export function visitsPortalDefault(properties) {
  properties = properties || {};
  if (__AVO_DEV__ || __WEB_DEBUGGER__) {
    // assert properties
    var messages = [];
    messages = messages.concat(assertCustomDestinationPageName_(properties.customDestinationPageName_));
    messages = messages.concat(assertOptionalHitsInstantHireTest(properties.hitsInstantHireTest));
    messages = messages.concat(assertOptionalPendingHire(properties.pendingHire));
    messages = messages.concat(AvoAssert.assertNoAdditionalProperties("VISITS_PORTAL_DEFAULT", Object.keys(properties), [
      "customDestinationPageName_",
      "hitsInstantHireTest",
      "pendingHire"
    ]));
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "z8ridTX4fb", "7b0e0c9dff8780f00a6233a8571f8d2bbe9e15ecf960cb089f20e7d4daf65a62", messages.map(function(m) { return Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType}); }), 'event');
    }

    InternalAvoLogger.logEventSent("VISITS_PORTAL_DEFAULT", {
      "hits_instant_hire_test": properties.hitsInstantHireTest,
      "pending_hire": properties.pendingHire,
      }, {}, {});
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("z8ridTX4fb", "VISITS_PORTAL_DEFAULT", messages, [
      {id: "GCUkFeu83P", name: "hits_instant_hire_test", value: properties.hitsInstantHireTest},
      {id: "xtyrrgHcmf", name: "pending_hire", value: properties.pendingHire},
      ], []);
    }
    if (__AVO_DEV__ && (__STRICT__ === null || __STRICT__)) {
      // throw exception if messages is not empty
      if (messages.length !== 0) {
        throw new Error("Error sending event 'VISITS_PORTAL_DEFAULT': " + messages[0].message)
      }
    } else {
      messages.forEach(function(m) {
        console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
      });
    }
  }

  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("VISITS_PORTAL_DEFAULT", {
        "hits_instant_hire_test": properties.hitsInstantHireTest,
        "pending_hire": properties.pendingHire,
        }, "z8ridTX4fb", "7b0e0c9dff8780f00a6233a8571f8d2bbe9e15ecf960cb089f20e7d4daf65a62");
    }
    // destination Rudderstack
    Rudderstack.page ? Rudderstack.page(properties.customDestinationPageName_, {
      "hits_instant_hire_test": properties.hitsInstantHireTest,
      "pending_hire": properties.pendingHire,
      }) : InternalAvoLogger.error('Page method is not implemented in the destination', '');
    Rudderstack.logEvent("VISITS_PORTAL_DEFAULT", {
    "hits_instant_hire_test": properties.hitsInstantHireTest,
    "pending_hire": properties.pendingHire,
    });
  } else {
    // do nothing
  }
}

/**
 * CLICKS_SETUP_PROFILE: Clients who click on the setup profile reminder in the info notice
 *
 * @param {object} properties - the properties associatied with this event
 * @param {bool} properties.hitsInstantHireTest - Is the user in the instant hire test bucket or not? Should be yes/no.
 *
 * @see {@link https://www.avo.app/schemas/0muBXC3bHDRv06LDZguQ/branches/8TPb5YtuF/events/kkFNEhfpzO}
 */
export function clicksSetupProfile(properties) {
  properties = properties || {};
  if (__AVO_DEV__ || __WEB_DEBUGGER__) {
    // assert properties
    var messages = [];
    messages = messages.concat(assertOptionalHitsInstantHireTest(properties.hitsInstantHireTest));
    messages = messages.concat(AvoAssert.assertNoAdditionalProperties("CLICKS_SETUP_PROFILE", Object.keys(properties), [
      "hitsInstantHireTest"
    ]));
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "kkFNEhfpzO", "66c42db2e32ae646137ed70dee6d0a4962e9ca4398d95472d6c87a251343c0b2", messages.map(function(m) { return Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType}); }), 'event');
    }

    InternalAvoLogger.logEventSent("CLICKS_SETUP_PROFILE", {
      "hits_instant_hire_test": properties.hitsInstantHireTest,
      }, {}, {});
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("kkFNEhfpzO", "CLICKS_SETUP_PROFILE", messages, [
      {id: "GCUkFeu83P", name: "hits_instant_hire_test", value: properties.hitsInstantHireTest},
      ], []);
    }
    if (__AVO_DEV__ && (__STRICT__ === null || __STRICT__)) {
      // throw exception if messages is not empty
      if (messages.length !== 0) {
        throw new Error("Error sending event 'CLICKS_SETUP_PROFILE': " + messages[0].message)
      }
    } else {
      messages.forEach(function(m) {
        console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
      });
    }
  }

  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("CLICKS_SETUP_PROFILE", {
        "hits_instant_hire_test": properties.hitsInstantHireTest,
        }, "kkFNEhfpzO", "66c42db2e32ae646137ed70dee6d0a4962e9ca4398d95472d6c87a251343c0b2");
    }
    // destination Rudderstack
    Rudderstack.logEvent("CLICKS_SETUP_PROFILE", {
    "hits_instant_hire_test": properties.hitsInstantHireTest,
    });
  } else {
    // do nothing
  }
}

/**
 * CLICKS_MAIN_MENU: Users who navigate to and clicks on the icon to see the Client Portal Menu
 *
 * @see {@link https://www.avo.app/schemas/0muBXC3bHDRv06LDZguQ/branches/8TPb5YtuF/events/JBMRa7WaKo}
 */
export function clicksMainMenu(properties) {
  properties = properties || {};
  if (__AVO_DEV__ || __WEB_DEBUGGER__) {
    // assert properties
    var messages = [];
    messages = messages.concat(AvoAssert.assertNoAdditionalProperties("CLICKS_MAIN_MENU", Object.keys(properties), [
    ]));
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "JBMRa7WaKo", "78104db9d9961545d7b3aa23ada8fd917d312c7323fde684c91fdb1dce3b511e", messages.map(function(m) { return Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType}); }), 'event');
    }

    InternalAvoLogger.logEventSent("CLICKS_MAIN_MENU", {}, {}, {});
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("JBMRa7WaKo", "CLICKS_MAIN_MENU", messages, [], []);
    }
    if (__AVO_DEV__ && (__STRICT__ === null || __STRICT__)) {
      // throw exception if messages is not empty
      if (messages.length !== 0) {
        throw new Error("Error sending event 'CLICKS_MAIN_MENU': " + messages[0].message)
      }
    } else {
      messages.forEach(function(m) {
        console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
      });
    }
  }

  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("CLICKS_MAIN_MENU", {}, "JBMRa7WaKo", "78104db9d9961545d7b3aa23ada8fd917d312c7323fde684c91fdb1dce3b511e");
    }
    // destination Rudderstack
    Rudderstack.logEvent("CLICKS_MAIN_MENU", {});
  } else {
    // do nothing
  }
}

/**
 * AUTH_VIEW_PARTIAL_REGISTRATION: No description
 *
 * @see {@link https://www.avo.app/schemas/0muBXC3bHDRv06LDZguQ/branches/8TPb5YtuF/events/NroysU33_C}
 */
export function authViewPartialRegistration(properties) {
  properties = properties || {};
  if (__AVO_DEV__ || __WEB_DEBUGGER__) {
    // assert properties
    var messages = [];
    messages = messages.concat(AvoAssert.assertNoAdditionalProperties("AUTH_VIEW_PARTIAL_REGISTRATION", Object.keys(properties), [
    ]));
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "NroysU33_C", "e52fbe4396be7a6e0140ff19ea43db831fce1d38978a71a2ceb8548b48adc82d", messages.map(function(m) { return Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType}); }), 'event');
    }

    InternalAvoLogger.logEventSent("AUTH_VIEW_PARTIAL_REGISTRATION", {}, {}, {});
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("NroysU33_C", "AUTH_VIEW_PARTIAL_REGISTRATION", messages, [], []);
    }
    if (__AVO_DEV__ && (__STRICT__ === null || __STRICT__)) {
      // throw exception if messages is not empty
      if (messages.length !== 0) {
        throw new Error("Error sending event 'AUTH_VIEW_PARTIAL_REGISTRATION': " + messages[0].message)
      }
    } else {
      messages.forEach(function(m) {
        console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
      });
    }
  }

  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("AUTH_VIEW_PARTIAL_REGISTRATION", {}, "NroysU33_C", "e52fbe4396be7a6e0140ff19ea43db831fce1d38978a71a2ceb8548b48adc82d");
    }
    // destination Rudderstack
    Rudderstack.logEvent("AUTH_VIEW_PARTIAL_REGISTRATION", {});
  } else {
    // do nothing
  }
}

/**
 * AUTH_INVALID_PARTIAL_REGISTRATION: No description
 *
 * @see {@link https://www.avo.app/schemas/0muBXC3bHDRv06LDZguQ/branches/8TPb5YtuF/events/BxvRfOiyvR}
 */
export function authInvalidPartialRegistration(properties) {
  properties = properties || {};
  if (__AVO_DEV__ || __WEB_DEBUGGER__) {
    // assert properties
    var messages = [];
    messages = messages.concat(AvoAssert.assertNoAdditionalProperties("AUTH_INVALID_PARTIAL_REGISTRATION", Object.keys(properties), [
    ]));
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "BxvRfOiyvR", "64761a6ee488925dc2792cad0014c876cb71e60e8fcb50cb25e1fde0556aabad", messages.map(function(m) { return Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType}); }), 'event');
    }

    InternalAvoLogger.logEventSent("AUTH_INVALID_PARTIAL_REGISTRATION", {}, {}, {});
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("BxvRfOiyvR", "AUTH_INVALID_PARTIAL_REGISTRATION", messages, [], []);
    }
    if (__AVO_DEV__ && (__STRICT__ === null || __STRICT__)) {
      // throw exception if messages is not empty
      if (messages.length !== 0) {
        throw new Error("Error sending event 'AUTH_INVALID_PARTIAL_REGISTRATION': " + messages[0].message)
      }
    } else {
      messages.forEach(function(m) {
        console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
      });
    }
  }

  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("AUTH_INVALID_PARTIAL_REGISTRATION", {}, "BxvRfOiyvR", "64761a6ee488925dc2792cad0014c876cb71e60e8fcb50cb25e1fde0556aabad");
    }
    // destination Rudderstack
    Rudderstack.logEvent("AUTH_INVALID_PARTIAL_REGISTRATION", {});
  } else {
    // do nothing
  }
}

/**
 * AUTH_COMPLETE_PARTIAL_REGISTRATION: No description
 *
 * @see {@link https://www.avo.app/schemas/0muBXC3bHDRv06LDZguQ/branches/8TPb5YtuF/events/bRY8p5ZwFI}
 */
export function authCompletePartialRegistration(properties) {
  properties = properties || {};
  if (__AVO_DEV__ || __WEB_DEBUGGER__) {
    // assert properties
    var messages = [];
    messages = messages.concat(AvoAssert.assertNoAdditionalProperties("AUTH_COMPLETE_PARTIAL_REGISTRATION", Object.keys(properties), [
    ]));
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "bRY8p5ZwFI", "5e267aa85e1012a082d92be977c0b4017dcb78f76233abcd7321ad4a9cb713c5", messages.map(function(m) { return Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType}); }), 'event');
    }

    InternalAvoLogger.logEventSent("AUTH_COMPLETE_PARTIAL_REGISTRATION", {}, {}, {});
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("bRY8p5ZwFI", "AUTH_COMPLETE_PARTIAL_REGISTRATION", messages, [], []);
    }
    if (__AVO_DEV__ && (__STRICT__ === null || __STRICT__)) {
      // throw exception if messages is not empty
      if (messages.length !== 0) {
        throw new Error("Error sending event 'AUTH_COMPLETE_PARTIAL_REGISTRATION': " + messages[0].message)
      }
    } else {
      messages.forEach(function(m) {
        console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
      });
    }
  }

  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("AUTH_COMPLETE_PARTIAL_REGISTRATION", {}, "bRY8p5ZwFI", "5e267aa85e1012a082d92be977c0b4017dcb78f76233abcd7321ad4a9cb713c5");
    }
    // destination Rudderstack
    Rudderstack.logEvent("AUTH_COMPLETE_PARTIAL_REGISTRATION", {});
  } else {
    // do nothing
  }
}

/**
 * VIEW_BILL: No description
 *
 * @see {@link https://www.avo.app/schemas/0muBXC3bHDRv06LDZguQ/branches/8TPb5YtuF/events/M1EdM1IG5W}
 */
export function viewBill(properties) {
  properties = properties || {};
  if (__AVO_DEV__ || __WEB_DEBUGGER__) {
    // assert properties
    var messages = [];
    messages = messages.concat(AvoAssert.assertNoAdditionalProperties("VIEW_BILL", Object.keys(properties), [
    ]));
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "M1EdM1IG5W", "975479c79eaa733d966a984d1c78a9a68ed6395fe2fbec34f4d720c7734cb02b", messages.map(function(m) { return Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType}); }), 'event');
    }

    InternalAvoLogger.logEventSent("VIEW_BILL", {}, {}, {});
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("M1EdM1IG5W", "VIEW_BILL", messages, [], []);
    }
    if (__AVO_DEV__ && (__STRICT__ === null || __STRICT__)) {
      // throw exception if messages is not empty
      if (messages.length !== 0) {
        throw new Error("Error sending event 'VIEW_BILL': " + messages[0].message)
      }
    } else {
      messages.forEach(function(m) {
        console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
      });
    }
  }

  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("VIEW_BILL", {}, "M1EdM1IG5W", "975479c79eaa733d966a984d1c78a9a68ed6395fe2fbec34f4d720c7734cb02b");
    }
    // destination Rudderstack
    Rudderstack.logEvent("VIEW_BILL", {});
  } else {
    // do nothing
  }
}

/**
 * DOWNLOAD_BILL: No description
 *
 * @see {@link https://www.avo.app/schemas/0muBXC3bHDRv06LDZguQ/branches/8TPb5YtuF/events/okrX1EIzUC}
 */
export function downloadBill(properties) {
  properties = properties || {};
  if (__AVO_DEV__ || __WEB_DEBUGGER__) {
    // assert properties
    var messages = [];
    messages = messages.concat(AvoAssert.assertNoAdditionalProperties("DOWNLOAD_BILL", Object.keys(properties), [
    ]));
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "okrX1EIzUC", "86b1c2afeb44aeecc88203f353cac86637c210e7187bf24b2fb7d5c36158285f", messages.map(function(m) { return Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType}); }), 'event');
    }

    InternalAvoLogger.logEventSent("DOWNLOAD_BILL", {}, {}, {});
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("okrX1EIzUC", "DOWNLOAD_BILL", messages, [], []);
    }
    if (__AVO_DEV__ && (__STRICT__ === null || __STRICT__)) {
      // throw exception if messages is not empty
      if (messages.length !== 0) {
        throw new Error("Error sending event 'DOWNLOAD_BILL': " + messages[0].message)
      }
    } else {
      messages.forEach(function(m) {
        console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
      });
    }
  }

  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("DOWNLOAD_BILL", {}, "okrX1EIzUC", "86b1c2afeb44aeecc88203f353cac86637c210e7187bf24b2fb7d5c36158285f");
    }
    // destination Rudderstack
    Rudderstack.logEvent("DOWNLOAD_BILL", {});
  } else {
    // do nothing
  }
}

/**
 * MATCHING_BOOKED_KICKOFF_CALL: No description
 *
 * @see {@link https://www.avo.app/schemas/0muBXC3bHDRv06LDZguQ/branches/8TPb5YtuF/events/y9GjYDT0iR}
 */
export function matchingBookedKickoffCall(properties) {
  properties = properties || {};
  if (__AVO_DEV__ || __WEB_DEBUGGER__) {
    // assert properties
    var messages = [];
    messages = messages.concat(AvoAssert.assertNoAdditionalProperties("MATCHING_BOOKED_KICKOFF_CALL", Object.keys(properties), [
    ]));
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "y9GjYDT0iR", "2250098da64a2b5bd6f9761fb19c47df06a6c63bfd92318111674630e25ea112", messages.map(function(m) { return Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType}); }), 'event');
    }

    InternalAvoLogger.logEventSent("MATCHING_BOOKED_KICKOFF_CALL", {}, {}, {});
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("y9GjYDT0iR", "MATCHING_BOOKED_KICKOFF_CALL", messages, [], []);
    }
    if (__AVO_DEV__ && (__STRICT__ === null || __STRICT__)) {
      // throw exception if messages is not empty
      if (messages.length !== 0) {
        throw new Error("Error sending event 'MATCHING_BOOKED_KICKOFF_CALL': " + messages[0].message)
      }
    } else {
      messages.forEach(function(m) {
        console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
      });
    }
  }

  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("MATCHING_BOOKED_KICKOFF_CALL", {}, "y9GjYDT0iR", "2250098da64a2b5bd6f9761fb19c47df06a6c63bfd92318111674630e25ea112");
    }
    // destination Rudderstack
    Rudderstack.logEvent("MATCHING_BOOKED_KICKOFF_CALL", {});
  } else {
    // do nothing
  }
}

/**
 * MATCHING_SEARCH: No description
 *
 * @see {@link https://www.avo.app/schemas/0muBXC3bHDRv06LDZguQ/branches/8TPb5YtuF/events/WVyFxPXY64}
 */
export function matchingSearch(properties) {
  properties = properties || {};
  if (__AVO_DEV__ || __WEB_DEBUGGER__) {
    // assert properties
    var messages = [];
    messages = messages.concat(AvoAssert.assertNoAdditionalProperties("MATCHING_SEARCH", Object.keys(properties), [
    ]));
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "WVyFxPXY64", "95f79cf3050ccab6da8a373ba89f5e66e05665123bbb027c47b71988ba49d4ca", messages.map(function(m) { return Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType}); }), 'event');
    }

    InternalAvoLogger.logEventSent("MATCHING_SEARCH", {}, {}, {});
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("WVyFxPXY64", "MATCHING_SEARCH", messages, [], []);
    }
    if (__AVO_DEV__ && (__STRICT__ === null || __STRICT__)) {
      // throw exception if messages is not empty
      if (messages.length !== 0) {
        throw new Error("Error sending event 'MATCHING_SEARCH': " + messages[0].message)
      }
    } else {
      messages.forEach(function(m) {
        console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
      });
    }
  }

  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("MATCHING_SEARCH", {}, "WVyFxPXY64", "95f79cf3050ccab6da8a373ba89f5e66e05665123bbb027c47b71988ba49d4ca");
    }
    // destination Rudderstack
    Rudderstack.logEvent("MATCHING_SEARCH", {});
  } else {
    // do nothing
  }
}

/**
 * MATCHING_NO_RESULT: No description
 *
 * @see {@link https://www.avo.app/schemas/0muBXC3bHDRv06LDZguQ/branches/8TPb5YtuF/events/oNZkdfsUPZ}
 */
export function matchingNoResult(properties) {
  properties = properties || {};
  if (__AVO_DEV__ || __WEB_DEBUGGER__) {
    // assert properties
    var messages = [];
    messages = messages.concat(AvoAssert.assertNoAdditionalProperties("MATCHING_NO_RESULT", Object.keys(properties), [
    ]));
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "oNZkdfsUPZ", "97974ad6a213039727fd872de2f2e1c5f28389769f6b18308c06cfd7172c6296", messages.map(function(m) { return Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType}); }), 'event');
    }

    InternalAvoLogger.logEventSent("MATCHING_NO_RESULT", {}, {}, {});
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("oNZkdfsUPZ", "MATCHING_NO_RESULT", messages, [], []);
    }
    if (__AVO_DEV__ && (__STRICT__ === null || __STRICT__)) {
      // throw exception if messages is not empty
      if (messages.length !== 0) {
        throw new Error("Error sending event 'MATCHING_NO_RESULT': " + messages[0].message)
      }
    } else {
      messages.forEach(function(m) {
        console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
      });
    }
  }

  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("MATCHING_NO_RESULT", {}, "oNZkdfsUPZ", "97974ad6a213039727fd872de2f2e1c5f28389769f6b18308c06cfd7172c6296");
    }
    // destination Rudderstack
    Rudderstack.logEvent("MATCHING_NO_RESULT", {});
  } else {
    // do nothing
  }
}

/**
 * OT_REQUEST_VIEWED: No description
 *
 * @see {@link https://www.avo.app/schemas/0muBXC3bHDRv06LDZguQ/branches/8TPb5YtuF/events/v_Wds2nVbN}
 */
export function otRequestViewed(properties) {
  properties = properties || {};
  if (__AVO_DEV__ || __WEB_DEBUGGER__) {
    // assert properties
    var messages = [];
    messages = messages.concat(AvoAssert.assertNoAdditionalProperties("OT_REQUEST_VIEWED", Object.keys(properties), [
    ]));
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "v_Wds2nVbN", "9f02a5f8f4f6e4e4d8125b37ae4b1854578ecf092a2546fc156c44255ad157ff", messages.map(function(m) { return Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType}); }), 'event');
    }

    InternalAvoLogger.logEventSent("OT_REQUEST_VIEWED", {}, {}, {});
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("v_Wds2nVbN", "OT_REQUEST_VIEWED", messages, [], []);
    }
    if (__AVO_DEV__ && (__STRICT__ === null || __STRICT__)) {
      // throw exception if messages is not empty
      if (messages.length !== 0) {
        throw new Error("Error sending event 'OT_REQUEST_VIEWED': " + messages[0].message)
      }
    } else {
      messages.forEach(function(m) {
        console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
      });
    }
  }

  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("OT_REQUEST_VIEWED", {}, "v_Wds2nVbN", "9f02a5f8f4f6e4e4d8125b37ae4b1854578ecf092a2546fc156c44255ad157ff");
    }
    // destination Rudderstack
    Rudderstack.logEvent("OT_REQUEST_VIEWED", {});
  } else {
    // do nothing
  }
}

/**
 * OT_REQUEST_APPROVED: No description
 *
 * @see {@link https://www.avo.app/schemas/0muBXC3bHDRv06LDZguQ/branches/8TPb5YtuF/events/KGYoa6NKHX}
 */
export function otRequestApproved(properties) {
  properties = properties || {};
  if (__AVO_DEV__ || __WEB_DEBUGGER__) {
    // assert properties
    var messages = [];
    messages = messages.concat(AvoAssert.assertNoAdditionalProperties("OT_REQUEST_APPROVED", Object.keys(properties), [
    ]));
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "KGYoa6NKHX", "0883ed1e79c019d741b9568e2c7b9306d09c8845da1c922ac72131f18cb86167", messages.map(function(m) { return Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType}); }), 'event');
    }

    InternalAvoLogger.logEventSent("OT_REQUEST_APPROVED", {}, {}, {});
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("KGYoa6NKHX", "OT_REQUEST_APPROVED", messages, [], []);
    }
    if (__AVO_DEV__ && (__STRICT__ === null || __STRICT__)) {
      // throw exception if messages is not empty
      if (messages.length !== 0) {
        throw new Error("Error sending event 'OT_REQUEST_APPROVED': " + messages[0].message)
      }
    } else {
      messages.forEach(function(m) {
        console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
      });
    }
  }

  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("OT_REQUEST_APPROVED", {}, "KGYoa6NKHX", "0883ed1e79c019d741b9568e2c7b9306d09c8845da1c922ac72131f18cb86167");
    }
    // destination Rudderstack
    Rudderstack.logEvent("OT_REQUEST_APPROVED", {});
  } else {
    // do nothing
  }
}

/**
 * OT_REQUEST_DENIED: No description
 *
 * @see {@link https://www.avo.app/schemas/0muBXC3bHDRv06LDZguQ/branches/8TPb5YtuF/events/hasOO8_UZ8}
 */
export function otRequestDenied(properties) {
  properties = properties || {};
  if (__AVO_DEV__ || __WEB_DEBUGGER__) {
    // assert properties
    var messages = [];
    messages = messages.concat(AvoAssert.assertNoAdditionalProperties("OT_REQUEST_DENIED", Object.keys(properties), [
    ]));
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "hasOO8_UZ8", "37ff1be2e454ccc84a5ad84626792877f75f0f190c27941eff19fec75d454bfa", messages.map(function(m) { return Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType}); }), 'event');
    }

    InternalAvoLogger.logEventSent("OT_REQUEST_DENIED", {}, {}, {});
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("hasOO8_UZ8", "OT_REQUEST_DENIED", messages, [], []);
    }
    if (__AVO_DEV__ && (__STRICT__ === null || __STRICT__)) {
      // throw exception if messages is not empty
      if (messages.length !== 0) {
        throw new Error("Error sending event 'OT_REQUEST_DENIED': " + messages[0].message)
      }
    } else {
      messages.forEach(function(m) {
        console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
      });
    }
  }

  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("OT_REQUEST_DENIED", {}, "hasOO8_UZ8", "37ff1be2e454ccc84a5ad84626792877f75f0f190c27941eff19fec75d454bfa");
    }
    // destination Rudderstack
    Rudderstack.logEvent("OT_REQUEST_DENIED", {});
  } else {
    // do nothing
  }
}

/**
 * ASSISTANT_REVIEW_UPDATED: No description
 *
 * @see {@link https://www.avo.app/schemas/0muBXC3bHDRv06LDZguQ/branches/8TPb5YtuF/events/EUhXWKGzLV}
 */
export function assistantReviewUpdated(properties) {
  properties = properties || {};
  if (__AVO_DEV__ || __WEB_DEBUGGER__) {
    // assert properties
    var messages = [];
    messages = messages.concat(AvoAssert.assertNoAdditionalProperties("ASSISTANT_REVIEW_UPDATED", Object.keys(properties), [
    ]));
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "EUhXWKGzLV", "b4db16b8d309b283477546923c41c45582bc5fde67890035693bf8a056d85e79", messages.map(function(m) { return Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType}); }), 'event');
    }

    InternalAvoLogger.logEventSent("ASSISTANT_REVIEW_UPDATED", {}, {}, {});
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("EUhXWKGzLV", "ASSISTANT_REVIEW_UPDATED", messages, [], []);
    }
    if (__AVO_DEV__ && (__STRICT__ === null || __STRICT__)) {
      // throw exception if messages is not empty
      if (messages.length !== 0) {
        throw new Error("Error sending event 'ASSISTANT_REVIEW_UPDATED': " + messages[0].message)
      }
    } else {
      messages.forEach(function(m) {
        console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
      });
    }
  }

  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("ASSISTANT_REVIEW_UPDATED", {}, "EUhXWKGzLV", "b4db16b8d309b283477546923c41c45582bc5fde67890035693bf8a056d85e79");
    }
    // destination Rudderstack
    Rudderstack.logEvent("ASSISTANT_REVIEW_UPDATED", {});
  } else {
    // do nothing
  }
}

/**
 * ASSISTANT_REVIEW_SUBMITTED: No description
 *
 * @see {@link https://www.avo.app/schemas/0muBXC3bHDRv06LDZguQ/branches/8TPb5YtuF/events/4EOlMCbWiH}
 */
export function assistantReviewSubmitted(properties) {
  properties = properties || {};
  if (__AVO_DEV__ || __WEB_DEBUGGER__) {
    // assert properties
    var messages = [];
    messages = messages.concat(AvoAssert.assertNoAdditionalProperties("ASSISTANT_REVIEW_SUBMITTED", Object.keys(properties), [
    ]));
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "4EOlMCbWiH", "028ae9cf3efa629f315cfc41d540198811f6029816285f0f4a7f47b53b2ee549", messages.map(function(m) { return Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType}); }), 'event');
    }

    InternalAvoLogger.logEventSent("ASSISTANT_REVIEW_SUBMITTED", {}, {}, {});
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("4EOlMCbWiH", "ASSISTANT_REVIEW_SUBMITTED", messages, [], []);
    }
    if (__AVO_DEV__ && (__STRICT__ === null || __STRICT__)) {
      // throw exception if messages is not empty
      if (messages.length !== 0) {
        throw new Error("Error sending event 'ASSISTANT_REVIEW_SUBMITTED': " + messages[0].message)
      }
    } else {
      messages.forEach(function(m) {
        console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
      });
    }
  }

  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("ASSISTANT_REVIEW_SUBMITTED", {}, "4EOlMCbWiH", "028ae9cf3efa629f315cfc41d540198811f6029816285f0f4a7f47b53b2ee549");
    }
    // destination Rudderstack
    Rudderstack.logEvent("ASSISTANT_REVIEW_SUBMITTED", {});
  } else {
    // do nothing
  }
}

/**
 * ASSISTANT_REVIEW_DISMISSED: No description
 *
 * @see {@link https://www.avo.app/schemas/0muBXC3bHDRv06LDZguQ/branches/8TPb5YtuF/events/Q_miREK_3i}
 */
export function assistantReviewDismissed(properties) {
  properties = properties || {};
  if (__AVO_DEV__ || __WEB_DEBUGGER__) {
    // assert properties
    var messages = [];
    messages = messages.concat(AvoAssert.assertNoAdditionalProperties("ASSISTANT_REVIEW_DISMISSED", Object.keys(properties), [
    ]));
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "Q_miREK_3i", "c59a5ec2c3b09d8dab3015996792af47cf07ce7dc8859b713f2e5a9ad92d5297", messages.map(function(m) { return Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType}); }), 'event');
    }

    InternalAvoLogger.logEventSent("ASSISTANT_REVIEW_DISMISSED", {}, {}, {});
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("Q_miREK_3i", "ASSISTANT_REVIEW_DISMISSED", messages, [], []);
    }
    if (__AVO_DEV__ && (__STRICT__ === null || __STRICT__)) {
      // throw exception if messages is not empty
      if (messages.length !== 0) {
        throw new Error("Error sending event 'ASSISTANT_REVIEW_DISMISSED': " + messages[0].message)
      }
    } else {
      messages.forEach(function(m) {
        console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
      });
    }
  }

  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("ASSISTANT_REVIEW_DISMISSED", {}, "Q_miREK_3i", "c59a5ec2c3b09d8dab3015996792af47cf07ce7dc8859b713f2e5a9ad92d5297");
    }
    // destination Rudderstack
    Rudderstack.logEvent("ASSISTANT_REVIEW_DISMISSED", {});
  } else {
    // do nothing
  }
}

/**
 * VIEW_CLIENT_FEEDBACK_HISTORY: No description
 *
 * @see {@link https://www.avo.app/schemas/0muBXC3bHDRv06LDZguQ/branches/8TPb5YtuF/events/d3o-PJk9cR}
 */
export function viewClientFeedbackHistory(properties) {
  properties = properties || {};
  if (__AVO_DEV__ || __WEB_DEBUGGER__) {
    // assert properties
    var messages = [];
    messages = messages.concat(AvoAssert.assertNoAdditionalProperties("VIEW_CLIENT_FEEDBACK_HISTORY", Object.keys(properties), [
    ]));
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "d3o-PJk9cR", "fc2f18b6a2f6ba0ea66cf56140859eddf7cb75c7ad978003acd00d7634dc7a8e", messages.map(function(m) { return Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType}); }), 'event');
    }

    InternalAvoLogger.logEventSent("VIEW_CLIENT_FEEDBACK_HISTORY", {}, {}, {});
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("d3o-PJk9cR", "VIEW_CLIENT_FEEDBACK_HISTORY", messages, [], []);
    }
    if (__AVO_DEV__ && (__STRICT__ === null || __STRICT__)) {
      // throw exception if messages is not empty
      if (messages.length !== 0) {
        throw new Error("Error sending event 'VIEW_CLIENT_FEEDBACK_HISTORY': " + messages[0].message)
      }
    } else {
      messages.forEach(function(m) {
        console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
      });
    }
  }

  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("VIEW_CLIENT_FEEDBACK_HISTORY", {}, "d3o-PJk9cR", "fc2f18b6a2f6ba0ea66cf56140859eddf7cb75c7ad978003acd00d7634dc7a8e");
    }
    // destination Rudderstack
    Rudderstack.logEvent("VIEW_CLIENT_FEEDBACK_HISTORY", {});
  } else {
    // do nothing
  }
}

/**
 * CLOCK_IN: No description
 *
 * @see {@link https://www.avo.app/schemas/0muBXC3bHDRv06LDZguQ/branches/8TPb5YtuF/events/FZnkcLJN62}
 */
export function clockIn(properties) {
  properties = properties || {};
  if (__AVO_DEV__ || __WEB_DEBUGGER__) {
    // assert properties
    var messages = [];
    messages = messages.concat(AvoAssert.assertNoAdditionalProperties("CLOCK_IN", Object.keys(properties), [
    ]));
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "FZnkcLJN62", "1e2f24e211076e037af29998eb910820f331df1024e40a69249da2db94ec9732", messages.map(function(m) { return Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType}); }), 'event');
    }

    InternalAvoLogger.logEventSent("CLOCK_IN", {}, {}, {});
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("FZnkcLJN62", "CLOCK_IN", messages, [], []);
    }
    if (__AVO_DEV__ && (__STRICT__ === null || __STRICT__)) {
      // throw exception if messages is not empty
      if (messages.length !== 0) {
        throw new Error("Error sending event 'CLOCK_IN': " + messages[0].message)
      }
    } else {
      messages.forEach(function(m) {
        console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
      });
    }
  }

  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("CLOCK_IN", {}, "FZnkcLJN62", "1e2f24e211076e037af29998eb910820f331df1024e40a69249da2db94ec9732");
    }
    // destination Rudderstack
    Rudderstack.logEvent("CLOCK_IN", {});
  } else {
    // do nothing
  }
}

/**
 * CLOCK_OUT: No description
 *
 * @see {@link https://www.avo.app/schemas/0muBXC3bHDRv06LDZguQ/branches/8TPb5YtuF/events/ADje6AXjX1}
 */
export function clockOut(properties) {
  properties = properties || {};
  if (__AVO_DEV__ || __WEB_DEBUGGER__) {
    // assert properties
    var messages = [];
    messages = messages.concat(AvoAssert.assertNoAdditionalProperties("CLOCK_OUT", Object.keys(properties), [
    ]));
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "ADje6AXjX1", "7a1cb0bc881c86b6cc87cef2e4116b4ec2c3e57523525249571a222b26fa9c9d", messages.map(function(m) { return Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType}); }), 'event');
    }

    InternalAvoLogger.logEventSent("CLOCK_OUT", {}, {}, {});
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("ADje6AXjX1", "CLOCK_OUT", messages, [], []);
    }
    if (__AVO_DEV__ && (__STRICT__ === null || __STRICT__)) {
      // throw exception if messages is not empty
      if (messages.length !== 0) {
        throw new Error("Error sending event 'CLOCK_OUT': " + messages[0].message)
      }
    } else {
      messages.forEach(function(m) {
        console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
      });
    }
  }

  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("CLOCK_OUT", {}, "ADje6AXjX1", "7a1cb0bc881c86b6cc87cef2e4116b4ec2c3e57523525249571a222b26fa9c9d");
    }
    // destination Rudderstack
    Rudderstack.logEvent("CLOCK_OUT", {});
  } else {
    // do nothing
  }
}

/**
 * UPDATE_CLOCK_OUT: No description
 *
 * @see {@link https://www.avo.app/schemas/0muBXC3bHDRv06LDZguQ/branches/8TPb5YtuF/events/ZpQoNbh0DP}
 */
export function updateClockOut(properties) {
  properties = properties || {};
  if (__AVO_DEV__ || __WEB_DEBUGGER__) {
    // assert properties
    var messages = [];
    messages = messages.concat(AvoAssert.assertNoAdditionalProperties("UPDATE_CLOCK_OUT", Object.keys(properties), [
    ]));
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "ZpQoNbh0DP", "d26345c65f2e54520c3986177c5526b8d2c27df774979539a33ca9ff695ea95e", messages.map(function(m) { return Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType}); }), 'event');
    }

    InternalAvoLogger.logEventSent("UPDATE_CLOCK_OUT", {}, {}, {});
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("ZpQoNbh0DP", "UPDATE_CLOCK_OUT", messages, [], []);
    }
    if (__AVO_DEV__ && (__STRICT__ === null || __STRICT__)) {
      // throw exception if messages is not empty
      if (messages.length !== 0) {
        throw new Error("Error sending event 'UPDATE_CLOCK_OUT': " + messages[0].message)
      }
    } else {
      messages.forEach(function(m) {
        console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
      });
    }
  }

  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("UPDATE_CLOCK_OUT", {}, "ZpQoNbh0DP", "d26345c65f2e54520c3986177c5526b8d2c27df774979539a33ca9ff695ea95e");
    }
    // destination Rudderstack
    Rudderstack.logEvent("UPDATE_CLOCK_OUT", {});
  } else {
    // do nothing
  }
}

/**
 * ONBOARDING_INTRO_PAGE_TRACKED: No description
 *
 * @see {@link https://www.avo.app/schemas/0muBXC3bHDRv06LDZguQ/branches/8TPb5YtuF/events/lgGjVXlFcP}
 */
export function onboardingIntroPageTracked(properties) {
  properties = properties || {};
  if (__AVO_DEV__ || __WEB_DEBUGGER__) {
    // assert properties
    var messages = [];
    messages = messages.concat(AvoAssert.assertNoAdditionalProperties("ONBOARDING_INTRO_PAGE_TRACKED", Object.keys(properties), [
    ]));
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "lgGjVXlFcP", "62d3b4559d2a7ee4514ac0558d9c928eabf97d7843941bfb7c80c379ceb5a62e", messages.map(function(m) { return Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType}); }), 'event');
    }

    InternalAvoLogger.logEventSent("ONBOARDING_INTRO_PAGE_TRACKED", {}, {}, {});
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("lgGjVXlFcP", "ONBOARDING_INTRO_PAGE_TRACKED", messages, [], []);
    }
    if (__AVO_DEV__ && (__STRICT__ === null || __STRICT__)) {
      // throw exception if messages is not empty
      if (messages.length !== 0) {
        throw new Error("Error sending event 'ONBOARDING_INTRO_PAGE_TRACKED': " + messages[0].message)
      }
    } else {
      messages.forEach(function(m) {
        console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
      });
    }
  }

  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("ONBOARDING_INTRO_PAGE_TRACKED", {}, "lgGjVXlFcP", "62d3b4559d2a7ee4514ac0558d9c928eabf97d7843941bfb7c80c379ceb5a62e");
    }
    // destination Rudderstack
    Rudderstack.logEvent("ONBOARDING_INTRO_PAGE_TRACKED", {});
  } else {
    // do nothing
  }
}

/**
 * ONBOARDING_QUIZ_TRACKED: No description
 *
 * @param {object} properties - the properties associatied with this event
 * @param {int} properties.onboardingQuizStep - no description
 *
 * @see {@link https://www.avo.app/schemas/0muBXC3bHDRv06LDZguQ/branches/8TPb5YtuF/events/towY9181Aa}
 */
export function onboardingQuizTracked(properties) {
  properties = properties || {};
  if (__AVO_DEV__ || __WEB_DEBUGGER__) {
    // assert properties
    var messages = [];
    messages = messages.concat(assertOnboardingQuizStep(properties.onboardingQuizStep));
    messages = messages.concat(AvoAssert.assertNoAdditionalProperties("ONBOARDING_QUIZ_TRACKED", Object.keys(properties), [
      "onboardingQuizStep"
    ]));
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "towY9181Aa", "1947e8868d2f0d483594a12191b5a6af9b05092f2bd81c25efdef9bd7d85e5ec", messages.map(function(m) { return Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType}); }), 'event');
    }

    InternalAvoLogger.logEventSent("ONBOARDING_QUIZ_TRACKED", {
      "onboarding_quiz_step": properties.onboardingQuizStep,
      }, {}, {});
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("towY9181Aa", "ONBOARDING_QUIZ_TRACKED", messages, [
      {id: "cluNg0yijN", name: "onboarding_quiz_step", value: properties.onboardingQuizStep},
      ], []);
    }
    if (__AVO_DEV__ && (__STRICT__ === null || __STRICT__)) {
      // throw exception if messages is not empty
      if (messages.length !== 0) {
        throw new Error("Error sending event 'ONBOARDING_QUIZ_TRACKED': " + messages[0].message)
      }
    } else {
      messages.forEach(function(m) {
        console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
      });
    }
  }

  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("ONBOARDING_QUIZ_TRACKED", {
        "onboarding_quiz_step": properties.onboardingQuizStep,
        }, "towY9181Aa", "1947e8868d2f0d483594a12191b5a6af9b05092f2bd81c25efdef9bd7d85e5ec");
    }
    // destination Rudderstack
    Rudderstack.logEvent("ONBOARDING_QUIZ_TRACKED", {
    "onboarding_quiz_step": properties.onboardingQuizStep,
    });
  } else {
    // do nothing
  }
}

/**
 * ONBOARDING_WHY_PAGE_TRACKED: No description
 *
 * @see {@link https://www.avo.app/schemas/0muBXC3bHDRv06LDZguQ/branches/8TPb5YtuF/events/opeXUyBl26}
 */
export function onboardingWhyPageTracked(properties) {
  properties = properties || {};
  if (__AVO_DEV__ || __WEB_DEBUGGER__) {
    // assert properties
    var messages = [];
    messages = messages.concat(AvoAssert.assertNoAdditionalProperties("ONBOARDING_WHY_PAGE_TRACKED", Object.keys(properties), [
    ]));
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "opeXUyBl26", "7ccde594b3f734d337880a90450fd835b19c95c71c8c9346cd8c7129ae6ac64c", messages.map(function(m) { return Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType}); }), 'event');
    }

    InternalAvoLogger.logEventSent("ONBOARDING_WHY_PAGE_TRACKED", {}, {}, {});
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("opeXUyBl26", "ONBOARDING_WHY_PAGE_TRACKED", messages, [], []);
    }
    if (__AVO_DEV__ && (__STRICT__ === null || __STRICT__)) {
      // throw exception if messages is not empty
      if (messages.length !== 0) {
        throw new Error("Error sending event 'ONBOARDING_WHY_PAGE_TRACKED': " + messages[0].message)
      }
    } else {
      messages.forEach(function(m) {
        console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
      });
    }
  }

  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("ONBOARDING_WHY_PAGE_TRACKED", {}, "opeXUyBl26", "7ccde594b3f734d337880a90450fd835b19c95c71c8c9346cd8c7129ae6ac64c");
    }
    // destination Rudderstack
    Rudderstack.logEvent("ONBOARDING_WHY_PAGE_TRACKED", {});
  } else {
    // do nothing
  }
}

/**
 * ONBOARDING_PAYMENT_PAGE_TRACKED: No description
 *
 * @see {@link https://www.avo.app/schemas/0muBXC3bHDRv06LDZguQ/branches/8TPb5YtuF/events/i4efPAk4XI}
 */
export function onboardingPaymentPageTracked(properties) {
  properties = properties || {};
  if (__AVO_DEV__ || __WEB_DEBUGGER__) {
    // assert properties
    var messages = [];
    messages = messages.concat(AvoAssert.assertNoAdditionalProperties("ONBOARDING_PAYMENT_PAGE_TRACKED", Object.keys(properties), [
    ]));
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "i4efPAk4XI", "7a871a14874c3a7751b62b13b69815dfc5b570bd4cb389efc2cb63df7232aab5", messages.map(function(m) { return Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType}); }), 'event');
    }

    InternalAvoLogger.logEventSent("ONBOARDING_PAYMENT_PAGE_TRACKED", {}, {}, {});
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("i4efPAk4XI", "ONBOARDING_PAYMENT_PAGE_TRACKED", messages, [], []);
    }
    if (__AVO_DEV__ && (__STRICT__ === null || __STRICT__)) {
      // throw exception if messages is not empty
      if (messages.length !== 0) {
        throw new Error("Error sending event 'ONBOARDING_PAYMENT_PAGE_TRACKED': " + messages[0].message)
      }
    } else {
      messages.forEach(function(m) {
        console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
      });
    }
  }

  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("ONBOARDING_PAYMENT_PAGE_TRACKED", {}, "i4efPAk4XI", "7a871a14874c3a7751b62b13b69815dfc5b570bd4cb389efc2cb63df7232aab5");
    }
    // destination Rudderstack
    Rudderstack.logEvent("ONBOARDING_PAYMENT_PAGE_TRACKED", {});
  } else {
    // do nothing
  }
}

/**
 * PREVIEW_PROFILE_SUCCESS: No description
 *
 * @see {@link https://www.avo.app/schemas/0muBXC3bHDRv06LDZguQ/branches/8TPb5YtuF/events/nii1X9n6JZ}
 */
export function previewProfileSuccess(properties) {
  properties = properties || {};
  if (__AVO_DEV__ || __WEB_DEBUGGER__) {
    // assert properties
    var messages = [];
    messages = messages.concat(AvoAssert.assertNoAdditionalProperties("PREVIEW_PROFILE_SUCCESS", Object.keys(properties), [
    ]));
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "nii1X9n6JZ", "3ea7c30fc707cd1ac90f250e36485c5498d773a37218ebfc9d95273c78bbd87b", messages.map(function(m) { return Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType}); }), 'event');
    }

    InternalAvoLogger.logEventSent("PREVIEW_PROFILE_SUCCESS", {}, {}, {});
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("nii1X9n6JZ", "PREVIEW_PROFILE_SUCCESS", messages, [], []);
    }
    if (__AVO_DEV__ && (__STRICT__ === null || __STRICT__)) {
      // throw exception if messages is not empty
      if (messages.length !== 0) {
        throw new Error("Error sending event 'PREVIEW_PROFILE_SUCCESS': " + messages[0].message)
      }
    } else {
      messages.forEach(function(m) {
        console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
      });
    }
  }

  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("PREVIEW_PROFILE_SUCCESS", {}, "nii1X9n6JZ", "3ea7c30fc707cd1ac90f250e36485c5498d773a37218ebfc9d95273c78bbd87b");
    }
    // destination Rudderstack
    Rudderstack.logEvent("PREVIEW_PROFILE_SUCCESS", {});
  } else {
    // do nothing
  }
}

/**
 * EDIT_PROFILE_SUCCESS: No description
 *
 * @see {@link https://www.avo.app/schemas/0muBXC3bHDRv06LDZguQ/branches/8TPb5YtuF/events/azvx6BJmh5}
 */
export function editProfileSuccess(properties) {
  properties = properties || {};
  if (__AVO_DEV__ || __WEB_DEBUGGER__) {
    // assert properties
    var messages = [];
    messages = messages.concat(AvoAssert.assertNoAdditionalProperties("EDIT_PROFILE_SUCCESS", Object.keys(properties), [
    ]));
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "azvx6BJmh5", "3c42329f859ac92d85155d133c13cb690acfc9cf1cb7fd9371b8c3776ebc040d", messages.map(function(m) { return Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType}); }), 'event');
    }

    InternalAvoLogger.logEventSent("EDIT_PROFILE_SUCCESS", {}, {}, {});
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("azvx6BJmh5", "EDIT_PROFILE_SUCCESS", messages, [], []);
    }
    if (__AVO_DEV__ && (__STRICT__ === null || __STRICT__)) {
      // throw exception if messages is not empty
      if (messages.length !== 0) {
        throw new Error("Error sending event 'EDIT_PROFILE_SUCCESS': " + messages[0].message)
      }
    } else {
      messages.forEach(function(m) {
        console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
      });
    }
  }

  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("EDIT_PROFILE_SUCCESS", {}, "azvx6BJmh5", "3c42329f859ac92d85155d133c13cb690acfc9cf1cb7fd9371b8c3776ebc040d");
    }
    // destination Rudderstack
    Rudderstack.logEvent("EDIT_PROFILE_SUCCESS", {});
  } else {
    // do nothing
  }
}

/**
 * SAVE_PROFILE_DRAFT_SUCCESS: No description
 *
 * @see {@link https://www.avo.app/schemas/0muBXC3bHDRv06LDZguQ/branches/8TPb5YtuF/events/q19UIDxcG2}
 */
export function saveProfileDraftSuccess(properties) {
  properties = properties || {};
  if (__AVO_DEV__ || __WEB_DEBUGGER__) {
    // assert properties
    var messages = [];
    messages = messages.concat(AvoAssert.assertNoAdditionalProperties("SAVE_PROFILE_DRAFT_SUCCESS", Object.keys(properties), [
    ]));
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "q19UIDxcG2", "3fde344310a4058a5ea78250c685feeb616d32c16f71a0f459e391780f666c6a", messages.map(function(m) { return Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType}); }), 'event');
    }

    InternalAvoLogger.logEventSent("SAVE_PROFILE_DRAFT_SUCCESS", {}, {}, {});
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("q19UIDxcG2", "SAVE_PROFILE_DRAFT_SUCCESS", messages, [], []);
    }
    if (__AVO_DEV__ && (__STRICT__ === null || __STRICT__)) {
      // throw exception if messages is not empty
      if (messages.length !== 0) {
        throw new Error("Error sending event 'SAVE_PROFILE_DRAFT_SUCCESS': " + messages[0].message)
      }
    } else {
      messages.forEach(function(m) {
        console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
      });
    }
  }

  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("SAVE_PROFILE_DRAFT_SUCCESS", {}, "q19UIDxcG2", "3fde344310a4058a5ea78250c685feeb616d32c16f71a0f459e391780f666c6a");
    }
    // destination Rudderstack
    Rudderstack.logEvent("SAVE_PROFILE_DRAFT_SUCCESS", {});
  } else {
    // do nothing
  }
}

/**
 * REQUEST_OVERTIME: No description
 *
 * @see {@link https://www.avo.app/schemas/0muBXC3bHDRv06LDZguQ/branches/8TPb5YtuF/events/WKbfnm5GV}
 */
export function requestOvertime(properties) {
  properties = properties || {};
  if (__AVO_DEV__ || __WEB_DEBUGGER__) {
    // assert properties
    var messages = [];
    messages = messages.concat(AvoAssert.assertNoAdditionalProperties("REQUEST_OVERTIME", Object.keys(properties), [
    ]));
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "WKbfnm5GV", "d7e87d56051131b565505d1bcc6636bece353fe4794ef94ab882626e3eb2466f", messages.map(function(m) { return Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType}); }), 'event');
    }

    InternalAvoLogger.logEventSent("REQUEST_OVERTIME", {}, {}, {});
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("WKbfnm5GV", "REQUEST_OVERTIME", messages, [], []);
    }
    if (__AVO_DEV__ && (__STRICT__ === null || __STRICT__)) {
      // throw exception if messages is not empty
      if (messages.length !== 0) {
        throw new Error("Error sending event 'REQUEST_OVERTIME': " + messages[0].message)
      }
    } else {
      messages.forEach(function(m) {
        console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
      });
    }
  }

  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("REQUEST_OVERTIME", {}, "WKbfnm5GV", "d7e87d56051131b565505d1bcc6636bece353fe4794ef94ab882626e3eb2466f");
    }
    // destination Rudderstack
    Rudderstack.logEvent("REQUEST_OVERTIME", {});
  } else {
    // do nothing
  }
}

/**
 * OPEN_HELP_CENTER_LINK: No description
 *
 * @see {@link https://www.avo.app/schemas/0muBXC3bHDRv06LDZguQ/branches/8TPb5YtuF/events/5_4LC-pWjw}
 */
export function openHelpCenterLink(properties) {
  properties = properties || {};
  if (__AVO_DEV__ || __WEB_DEBUGGER__) {
    // assert properties
    var messages = [];
    messages = messages.concat(AvoAssert.assertNoAdditionalProperties("OPEN_HELP_CENTER_LINK", Object.keys(properties), [
    ]));
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "5_4LC-pWjw", "82f6c45858f23a6adbf6e144016db0ac3fb4b6e4036d70537dfa59a16cda367b", messages.map(function(m) { return Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType}); }), 'event');
    }

    InternalAvoLogger.logEventSent("OPEN_HELP_CENTER_LINK", {}, {}, {});
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("5_4LC-pWjw", "OPEN_HELP_CENTER_LINK", messages, [], []);
    }
    if (__AVO_DEV__ && (__STRICT__ === null || __STRICT__)) {
      // throw exception if messages is not empty
      if (messages.length !== 0) {
        throw new Error("Error sending event 'OPEN_HELP_CENTER_LINK': " + messages[0].message)
      }
    } else {
      messages.forEach(function(m) {
        console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
      });
    }
  }

  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("OPEN_HELP_CENTER_LINK", {}, "5_4LC-pWjw", "82f6c45858f23a6adbf6e144016db0ac3fb4b6e4036d70537dfa59a16cda367b");
    }
    // destination Rudderstack
    Rudderstack.logEvent("OPEN_HELP_CENTER_LINK", {});
  } else {
    // do nothing
  }
}

/**
 * ASSISTANT_REVIEW_VIEWED: No description
 *
 * @see {@link https://www.avo.app/schemas/0muBXC3bHDRv06LDZguQ/branches/8TPb5YtuF/events/ebEi6hxl5}
 */
export function assistantReviewViewed(properties) {
  properties = properties || {};
  if (__AVO_DEV__ || __WEB_DEBUGGER__) {
    // assert properties
    var messages = [];
    messages = messages.concat(AvoAssert.assertNoAdditionalProperties("ASSISTANT_REVIEW_VIEWED", Object.keys(properties), [
    ]));
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "ebEi6hxl5", "6c406665cdec2dbe6ae1b23a97d5d65f4671003ebfe7bc5e16685f1fa6140597", messages.map(function(m) { return Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType}); }), 'event');
    }

    InternalAvoLogger.logEventSent("ASSISTANT_REVIEW_VIEWED", {}, {}, {});
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("ebEi6hxl5", "ASSISTANT_REVIEW_VIEWED", messages, [], []);
    }
    if (__AVO_DEV__ && (__STRICT__ === null || __STRICT__)) {
      // throw exception if messages is not empty
      if (messages.length !== 0) {
        throw new Error("Error sending event 'ASSISTANT_REVIEW_VIEWED': " + messages[0].message)
      }
    } else {
      messages.forEach(function(m) {
        console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
      });
    }
  }

  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("ASSISTANT_REVIEW_VIEWED", {}, "ebEi6hxl5", "6c406665cdec2dbe6ae1b23a97d5d65f4671003ebfe7bc5e16685f1fa6140597");
    }
    // destination Rudderstack
    Rudderstack.logEvent("ASSISTANT_REVIEW_VIEWED", {});
  } else {
    // do nothing
  }
}

/**
 * AUTH_ASSISTANT_VIEW_PARTIAL_REGISTRATION: When assistant visits complete registration page
 *
 * @param {object} properties - the properties associatied with this event
 * @param {string} properties.email - no description
 * @param {string} properties.firstName - no description
 * @param {string} properties.lastName - no description
 *
 * @see {@link https://www.avo.app/schemas/0muBXC3bHDRv06LDZguQ/branches/8TPb5YtuF/events/sztej_5sKp}
 */
export function authAssistantViewPartialRegistration(properties) {
  properties = properties || {};
  if (__AVO_DEV__ || __WEB_DEBUGGER__) {
    // assert properties
    var messages = [];
    messages = messages.concat(assertEmail(properties.email));
    messages = messages.concat(assertFirstName(properties.firstName));
    messages = messages.concat(assertOptionalLastName(properties.lastName));
    messages = messages.concat(AvoAssert.assertNoAdditionalProperties("AUTH_ASSISTANT_VIEW_PARTIAL_REGISTRATION", Object.keys(properties), [
      "email",
      "firstName",
      "lastName"
    ]));
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "sztej_5sKp", "1463cd311cee048dc1149ed04dc64e13b82f026089173f3eeabea66f886287c2", messages.map(function(m) { return Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType}); }), 'event');
    }

    InternalAvoLogger.logEventSent("AUTH_ASSISTANT_VIEW_PARTIAL_REGISTRATION", {
      "email": properties.email,
      "first_name": properties.firstName,
      "last_name": properties.lastName,
      }, {}, {});
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("sztej_5sKp", "AUTH_ASSISTANT_VIEW_PARTIAL_REGISTRATION", messages, [
      {id: "OItbG6M2wP", name: "first_name", value: properties.firstName},
      {id: "ryNkZg3j0O", name: "last_name", value: properties.lastName},
      {id: "sHFf98OlOX", name: "email", value: properties.email},
      ], []);
    }
    if (__AVO_DEV__ && (__STRICT__ === null || __STRICT__)) {
      // throw exception if messages is not empty
      if (messages.length !== 0) {
        throw new Error("Error sending event 'AUTH_ASSISTANT_VIEW_PARTIAL_REGISTRATION': " + messages[0].message)
      }
    } else {
      messages.forEach(function(m) {
        console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
      });
    }
  }

  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("AUTH_ASSISTANT_VIEW_PARTIAL_REGISTRATION", {
        "email": properties.email,
        "first_name": properties.firstName,
        "last_name": properties.lastName,
        }, "sztej_5sKp", "1463cd311cee048dc1149ed04dc64e13b82f026089173f3eeabea66f886287c2");
    }
    // destination Rudderstack
    Rudderstack.logEvent("AUTH_ASSISTANT_VIEW_PARTIAL_REGISTRATION", {
    "email": properties.email,
    "first_name": properties.firstName,
    "last_name": properties.lastName,
    });
  } else {
    // do nothing
  }
}

/**
 * AUTH_ASSISTANT_INVALID_PARTIAL_REGISTRATION: When assistants visits the complete registration page with an invalid token
 *
 * @param {object} properties - the properties associatied with this event
 * @param {string} properties.authToken - Complete registration token
 *
 * @see {@link https://www.avo.app/schemas/0muBXC3bHDRv06LDZguQ/branches/8TPb5YtuF/events/CqUcgsZh4m}
 */
export function authAssistantInvalidPartialRegistration(properties) {
  properties = properties || {};
  if (__AVO_DEV__ || __WEB_DEBUGGER__) {
    // assert properties
    var messages = [];
    messages = messages.concat(assertAuthToken(properties.authToken));
    messages = messages.concat(AvoAssert.assertNoAdditionalProperties("AUTH_ASSISTANT_INVALID_PARTIAL_REGISTRATION", Object.keys(properties), [
      "authToken"
    ]));
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "CqUcgsZh4m", "008114c71341a0e51d6278aa53b1359ccb5c574e2542580143656617de4ba358", messages.map(function(m) { return Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType}); }), 'event');
    }

    InternalAvoLogger.logEventSent("AUTH_ASSISTANT_INVALID_PARTIAL_REGISTRATION", {
      "auth_token": properties.authToken,
      }, {}, {});
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("CqUcgsZh4m", "AUTH_ASSISTANT_INVALID_PARTIAL_REGISTRATION", messages, [
      {id: "WI6NG3P1Cn", name: "auth_token", value: properties.authToken},
      ], []);
    }
    if (__AVO_DEV__ && (__STRICT__ === null || __STRICT__)) {
      // throw exception if messages is not empty
      if (messages.length !== 0) {
        throw new Error("Error sending event 'AUTH_ASSISTANT_INVALID_PARTIAL_REGISTRATION': " + messages[0].message)
      }
    } else {
      messages.forEach(function(m) {
        console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
      });
    }
  }

  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("AUTH_ASSISTANT_INVALID_PARTIAL_REGISTRATION", {
        "auth_token": properties.authToken,
        }, "CqUcgsZh4m", "008114c71341a0e51d6278aa53b1359ccb5c574e2542580143656617de4ba358");
    }
    // destination Rudderstack
    Rudderstack.logEvent("AUTH_ASSISTANT_INVALID_PARTIAL_REGISTRATION", {
    "auth_token": properties.authToken,
    });
  } else {
    // do nothing
  }
}

/**
 * AUTH_ASSISTANT_COMPLETE_PARTIAL_REGISTRATION: When assistant successfully completed registration and changed their password
 *
 * @param {object} properties - the properties associatied with this event
 * @param {string} properties.firstName - no description
 * @param {string} properties.lastName - no description
 * @param {string} properties.email - no description
 *
 * @see {@link https://www.avo.app/schemas/0muBXC3bHDRv06LDZguQ/branches/8TPb5YtuF/events/lgKbMruHnH}
 */
export function authAssistantCompletePartialRegistration(properties) {
  properties = properties || {};
  if (__AVO_DEV__ || __WEB_DEBUGGER__) {
    // assert properties
    var messages = [];
    messages = messages.concat(assertFirstName(properties.firstName));
    messages = messages.concat(assertOptionalLastName(properties.lastName));
    messages = messages.concat(assertEmail(properties.email));
    messages = messages.concat(AvoAssert.assertNoAdditionalProperties("AUTH_ASSISTANT_COMPLETE_PARTIAL_REGISTRATION", Object.keys(properties), [
      "firstName",
      "lastName",
      "email"
    ]));
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "lgKbMruHnH", "89267bf6693234bb525afd50214889182e445b45aa1278641559886069a52363", messages.map(function(m) { return Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType}); }), 'event');
    }

    InternalAvoLogger.logEventSent("AUTH_ASSISTANT_COMPLETE_PARTIAL_REGISTRATION", {
      "first_name": properties.firstName,
      "last_name": properties.lastName,
      "email": properties.email,
      }, {}, {});
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("lgKbMruHnH", "AUTH_ASSISTANT_COMPLETE_PARTIAL_REGISTRATION", messages, [
      {id: "OItbG6M2wP", name: "first_name", value: properties.firstName},
      {id: "ryNkZg3j0O", name: "last_name", value: properties.lastName},
      {id: "sHFf98OlOX", name: "email", value: properties.email},
      ], []);
    }
    if (__AVO_DEV__ && (__STRICT__ === null || __STRICT__)) {
      // throw exception if messages is not empty
      if (messages.length !== 0) {
        throw new Error("Error sending event 'AUTH_ASSISTANT_COMPLETE_PARTIAL_REGISTRATION': " + messages[0].message)
      }
    } else {
      messages.forEach(function(m) {
        console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
      });
    }
  }

  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("AUTH_ASSISTANT_COMPLETE_PARTIAL_REGISTRATION", {
        "first_name": properties.firstName,
        "last_name": properties.lastName,
        "email": properties.email,
        }, "lgKbMruHnH", "89267bf6693234bb525afd50214889182e445b45aa1278641559886069a52363");
    }
    // destination Rudderstack
    Rudderstack.logEvent("AUTH_ASSISTANT_COMPLETE_PARTIAL_REGISTRATION", {
    "first_name": properties.firstName,
    "last_name": properties.lastName,
    "email": properties.email,
    });
  } else {
    // do nothing
  }
}

export default {
  setAvoLogger: setAvoLogger,
  visitsMainHomepage: visitsMainHomepage,
  clicksGetStartedBtn: clicksGetStartedBtn,
  visitsInstantMatchSearch: visitsInstantMatchSearch,
  matchingNewSearch: matchingNewSearch,
  visitsInstantMatchResults: visitsInstantMatchResults,
  matchingNextAssistant: matchingNextAssistant,
  clicksAcceptProfileBtn: clicksAcceptProfileBtn,
  clicksProfileVideo: clicksProfileVideo,
  visitsJobRequestForm: visitsJobRequestForm,
  clicksRequestWorkerBtn: clicksRequestWorkerBtn,
  clicksBackJobRequest: clicksBackJobRequest,
  clicksBackProfile: clicksBackProfile,
  clicksBackSearch: clicksBackSearch,
  clicksSpeakToSales: clicksSpeakToSales,
  visitsSignUp: visitsSignUp,
  clicksLoginNow: clicksLoginNow,
  clicksLoginBtn: clicksLoginBtn,
  clicksCreateAccountBtn: clicksCreateAccountBtn,
  visitsPaymentInfo: visitsPaymentInfo,
  clicksConfirmRequestBtn: clicksConfirmRequestBtn,
  clicksLearnMoreBilling: clicksLearnMoreBilling,
  visitsPortalDefault: visitsPortalDefault,
  clicksSetupProfile: clicksSetupProfile,
  clicksMainMenu: clicksMainMenu,
  authViewPartialRegistration: authViewPartialRegistration,
  authInvalidPartialRegistration: authInvalidPartialRegistration,
  authCompletePartialRegistration: authCompletePartialRegistration,
  viewBill: viewBill,
  downloadBill: downloadBill,
  matchingBookedKickoffCall: matchingBookedKickoffCall,
  matchingSearch: matchingSearch,
  matchingNoResult: matchingNoResult,
  otRequestViewed: otRequestViewed,
  otRequestApproved: otRequestApproved,
  otRequestDenied: otRequestDenied,
  assistantReviewUpdated: assistantReviewUpdated,
  assistantReviewSubmitted: assistantReviewSubmitted,
  assistantReviewDismissed: assistantReviewDismissed,
  viewClientFeedbackHistory: viewClientFeedbackHistory,
  clockIn: clockIn,
  clockOut: clockOut,
  updateClockOut: updateClockOut,
  onboardingIntroPageTracked: onboardingIntroPageTracked,
  onboardingQuizTracked: onboardingQuizTracked,
  onboardingWhyPageTracked: onboardingWhyPageTracked,
  onboardingPaymentPageTracked: onboardingPaymentPageTracked,
  previewProfileSuccess: previewProfileSuccess,
  editProfileSuccess: editProfileSuccess,
  saveProfileDraftSuccess: saveProfileDraftSuccess,
  requestOvertime: requestOvertime,
  openHelpCenterLink: openHelpCenterLink,
  assistantReviewViewed: assistantReviewViewed,
  authAssistantViewPartialRegistration: authAssistantViewPartialRegistration,
  authAssistantInvalidPartialRegistration: authAssistantInvalidPartialRegistration,
  authAssistantCompletePartialRegistration: authAssistantCompletePartialRegistration,
  initAvo: initAvo,
  avoInspectorApiKey: avoInspectorApiKey,
}

// AVOMODULEMAP:"Avo"
// AVOEVENTMAP:["visitsMainHomepage","clicksGetStartedBtn","visitsInstantMatchSearch","matchingNewSearch","visitsInstantMatchResults","matchingNextAssistant","clicksAcceptProfileBtn","clicksProfileVideo","visitsJobRequestForm","clicksRequestWorkerBtn","clicksBackJobRequest","clicksBackProfile","clicksBackSearch","clicksSpeakToSales","visitsSignUp","clicksLoginNow","clicksLoginBtn","clicksCreateAccountBtn","visitsPaymentInfo","clicksConfirmRequestBtn","clicksLearnMoreBilling","visitsPortalDefault","clicksSetupProfile","clicksMainMenu","authViewPartialRegistration","authInvalidPartialRegistration","authCompletePartialRegistration","viewBill","downloadBill","matchingBookedKickoffCall","matchingSearch","matchingNoResult","otRequestViewed","otRequestApproved","otRequestDenied","assistantReviewUpdated","assistantReviewSubmitted","assistantReviewDismissed","viewClientFeedbackHistory","clockIn","clockOut","updateClockOut","onboardingIntroPageTracked","onboardingQuizTracked","onboardingWhyPageTracked","onboardingPaymentPageTracked","previewProfileSuccess","editProfileSuccess","saveProfileDraftSuccess","requestOvertime","openHelpCenterLink","assistantReviewViewed","authAssistantViewPartialRegistration","authAssistantInvalidPartialRegistration","authAssistantCompletePartialRegistration"]
