import React, { useState, useEffect } from "react";
import Amplify, { Hub } from "aws-amplify";
import { Auth } from "@aws-amplify/auth";
import * as Sentry from "@sentry/browser";
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import "./App.scss";

import api from "./libs/api";
import { Loading } from "@magic-engineering/components";

import AppWrapper from "./pages/AppWrapper/AppWrapper";
import FeedbackPage from "./pages/FeedbackPage/FeedbackPage.lazy";
import IntroPage from "./pages/IntroPage/IntroPage.lazy";
import Navbar from "./components/Navbar/Navbar";
import OnboardingPage from "./pages/OnboardingPage/OnboardingPage.lazy";
import ProfilePage from "./pages/ProfilePage/ProfilePage.lazy";
import SettingsPage from "./pages/SettingsPage/SettingsPage.lazy";
import Sidebar from "./components/Sidebar/Sidebar";
import TimeTrackerHistoryPage from "./pages/TimeTrackerHistoryPage/TimeTrackerHistoryPage.lazy";
import Authenticator from "./components/Authentication/Authenticator.js";
import JobRequestsPage from "./pages/JobRequestsPage/JobRequestsPage.lazy";
import PayoutsPage from "./pages/PayoutsPage/PayoutsPage.lazy";

import Tracking from "@magic-engineering/magic-tracking";
import { useClients, setClients } from "./redux/clients";

import * as profileRedux from "../src/redux/profile";
import { getConfiguration, setStage, getAssistantStage, stages } from "../src/redux/configuration";
import FeatureFlagPage from "./pages/FeatureFlagPage/FeatureFlagPage";

const config = {
  aws_project_region: process.env.REACT_APP_AWS_PROJECT_REGION,
  aws_cognito_identity_pool_id: process.env.REACT_APP_AWS_COGNITO_IDENTITY_POOL_ID,
  aws_cognito_region: process.env.REACT_APP_AWS_COGNITO_REGION,
  aws_user_pools_id: process.env.REACT_APP_AWS_USER_POOLS_ID,
  aws_user_pools_web_client_id: process.env.REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID,
  oauth: {},
};

Amplify.configure(config);
Auth.configure(config);

/**
 * Wrapper to initialize sentry and tracking library
 */
function trackUser(user) {
  Tracking.initialize(user, {
    googleAnalytics: {
      measurementId: process.env.REACT_APP_GA_MEASUREMENT_ID_ASSISTANT_PORTAL,
    },
    rudderStack: {
      configuredDestinations: {
        All: process.env.REACT_APP_DEBUG_ANALYTICS || process.env.REACT_APP_STAGE === "prod",
      },
    },
    env: process.env.REACT_APP_STAGE,
    debug_mode: process.env.REACT_APP_DEBUG_ANALYTICS,
  });
  try {
    const { attributes, username } = user;
    const email = attributes.email;
    const first_name = attributes["custom:first_name"];
    const last_name = attributes["custom:last_name"];

    Sentry.setUser({
      email,
      username: `${first_name} ${last_name}`,
      id: username,
    });
  } catch (e) {
    console.warn("Failed to set sentry user");
  }
}
function App() {
  const appConfig = getConfiguration();
  const [hasUser, setHasUser] = useState(false);
  const [profile, setProfile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const clients = useClients();
  const userProfile = profileRedux.useProfile();
  const [refetchProfile, setRefetchProfile] = useState(false);

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        setHasUser(true);
        if (user) {
          trackUser(user);
        }
      })
      .catch(() => {
        setHasUser(false);
        Tracking.initialize(undefined);
      });

    Hub.listen("auth", (data) => {
      const { event, data: cognitoData } = (data && data.payload) || {};

      switch (event) {
        case "signIn":
          if (cognitoData) {
            trackUser(cognitoData);
          }
          setHasUser(true);
          break;
        case "signOut":
          setHasUser(false);
          // Reset state on signout
          setProfile(null);
          setClients([]);
          profileRedux.setProfile({});
          break;
        default:
          break;
      }
    });
  }, []);

  useEffect(() => {
    async function init() {
      setIsLoading(true);
      try {
        const [{ data: assistant } = {}, { data: quiz } = []] = await Promise.all([
          api.get("/assistants/profile"),
          api.get("/cms/assistant-quiz"),
        ]).catch((error) => {
          Sentry.captureException(error);
          return [];
        });

        assistant.profile.availableForHire =
          !!assistant.profile.open_to_work_exp_date &&
          new Date() < new Date(assistant.profile.open_to_work_exp_date);

        setProfile(assistant);
        setClients(assistant.clients);
        profileRedux.setProfile(assistant.profile);

        const stage = getAssistantStage({
          profile: assistant.profile,
          clients: assistant.clients,
          quiz: quiz,
        });
        setStage(stage);
      } catch (e) {
        console.warn(e);
      } finally {
        setIsLoading(false);
      }
    }

    if (hasUser && (refetchProfile || !profile)) {
      init();
      setRefetchProfile(false);
    }
  }, [hasUser, profile, clients, refetchProfile]);

  if (!hasUser || isLoading) {
    return (
      <Router>
        <div className="App" data-testid="AppId">
          <Switch>
            <Route exact path="/profile/:url">
              <div className="d-flex">
                <div className="content w-100">
                  <ProfilePage isPublic={true} />
                </div>
              </div>
            </Route>
            <Route>
              <Navbar />
              <div className="content w-100">{hasUser ? <Loading /> : <Authenticator />}</div>
            </Route>
          </Switch>
        </div>
        <ToastContainer position="bottom-right" />
      </Router>
    );
  }

  let homeRoute;

  switch (appConfig.stage) {
    case stages.ONBOARDING:
      homeRoute = <Redirect to="/intro" />;
      break;
    case stages.REQUIRE_PROFILE:
      homeRoute = <Redirect to="/onboarding/profile" />;
      break;
    case stages.WORKING:
    case stages.ONBOARDING_DONE:
    default:
      homeRoute = <AppWrapper profile={profile} />;
      break;
  }

  return (
    <Router>
      <div className="App" data-testid="AppId">
        <Navbar profile={userProfile} hasUser stage={appConfig.stage} />
        <div className="d-flex">
          <Sidebar profile={userProfile} clients={clients} stage={appConfig.stage} />
          <div className="content w-100">
            <Switch>
              <Route exact path="/enable-feature-flag">
                <FeatureFlagPage enable={true} />
              </Route>
              <Route exact path="/disable-feature-flag">
                <FeatureFlagPage enable={false} />
              </Route>
              <Route exact path="/">
                {homeRoute}
              </Route>
              <Route exact path="/feedbacks">
                <FeedbackPage profile={profile} isComponentLoading={!profile} />
              </Route>
              <Route exact path="/intro">
                <IntroPage profile={profile} />
              </Route>
              <Route exact path="/onboarding">
                <OnboardingPage profile={profile} />
              </Route>
              <Route exact path="/onboarding/profile">
                {appConfig.stage === stages.ONBOARDING ? (
                  <Redirect to="/intro" />
                ) : (
                  <ProfilePage defaultView="EDIT_PROFILE" isOnboarding={true} />
                )}
              </Route>
              <Route exact path="/profile">
                <ProfilePage stage={appConfig.stage} />
              </Route>
              <Route exact path="/profile/:url">
                <ProfilePage isPublic={true} />
              </Route>
              <Route exact path="/timetracker">
                <TimeTrackerHistoryPage profile={profile} isComponentLoading={!profile} />
              </Route>
              <Route exact path="/settings">
                <SettingsPage profile={profile} isComponentLoading={!profile} />
              </Route>
              <Route exact path="/payouts">
                <PayoutsPage />
              </Route>
              <Redirect
                from="/instanthire/disable"
                to={{ pathname: "/profile", state: { disableInstantHire: true } }}
              />
              <Redirect
                from="/instanthire/refresh"
                to={{ pathname: "/profile", state: { refreshInstantHire: true } }}
              />
              <Route exact path="/jobrequests/:jobRequestId/:response">
                {/* accept/reject */}
                <JobRequestsPage
                  profile={profile}
                  isComponentLoading={!profile}
                  onSuccess={setRefetchProfile}
                />
              </Route>
            </Switch>
          </div>
        </div>
        <ToastContainer position="bottom-right" />
      </div>
    </Router>
  );
}

export default Tracking.FeatureFlagFactory(App);
