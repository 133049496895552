import React, { useState } from "react";
import "./common.scss";

export default function PeekablePasswordInput({ handlePasswordChange, ...props }) {
  const [peekPassword, setPeekPassword] = useState(false);

  const handlePeekPassword = (event, state) => {
    event.preventDefault();
    event.stopPropagation();
    setPeekPassword(state);
  };

  return (
    <>
      <input type={peekPassword ? "text" : "password"} onChange={handlePasswordChange} {...props} />
      <button
        type="button" //Explicit to prevent form.submit
        className="peek-password"
        onMouseDown={(event) => handlePeekPassword(event, true)}
        onTouchStart={(event) => handlePeekPassword(event, true)}
        onMouseUp={(event) => handlePeekPassword(event, false)}
        onTouchEnd={(event) => handlePeekPassword(event, false)}
      >
        👁
      </button>
    </>
  );
}
