import { createReduxModule } from "hooks-for-redux";

const defaultClientsState = [];

export const [useClients, { setClients }] = createReduxModule("clients", defaultClientsState, {
  setClients: (state, data) => {
    if (!data) return state;
    return [...data];
  },
});
