import { createReduxModule } from "hooks-for-redux";
import { isProfileComplete } from "./profile";

export const stages = {
  ONBOARDING: "ONBOARDING",
  ONBOARDING_DONE: "ONBOARDING_DONE",
  REQUIRE_PROFILE: "REQUIRE_PROFILE",
  WORKING: "WORKING",
};

const defaultConfiguration = {
  stage: stages.WORKING,
};

export const [getConfiguration, { setStage }] = createReduxModule(
  "configuration",
  defaultConfiguration,
  {
    setStage: (state, newStage) => {
      if (!stages[newStage]) return state;

      return {
        ...state,
        stage: newStage,
      };
    },
  }
);

export function getAssistantStage({ quiz = [], profile, clients = [] }) {
  const quizComplete = quiz.reduce((a, b) => a && b.status === "ok", true);
  const profileComplete = isProfileComplete(profile);

  if (!quizComplete && !clients.length) {
    return stages.ONBOARDING;
  } else if (!profileComplete) {
    return stages.REQUIRE_PROFILE;
  } else if (!clients.length) {
    return stages.ONBOARDING_DONE;
  } else {
    return stages.WORKING;
  }
}
