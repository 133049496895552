import React, { useState, useEffect } from "react";

import api from "../../libs/api";

import { ButtonLink, Container, Loading } from "@magic-engineering/components";
import ResourceCard, { ResourceCompactCard } from "../ResourceCard/ResourceCard";

import { ReactComponent as HeadphonesIcon } from "../../assets/images/headphones.svg";
import { ReactComponent as BugIcon } from "../../assets/images/mosquito.svg";

import { useProfile } from "../../redux/profile";

const HelpCenter = () => {
  const [resources, setResources] = useState();
  const userProfile = useProfile();

  const bugSubmitLink = `https://airtable.com/shrOQ3Ht0qn2PYg4v?prefill_Name=${
    userProfile.first_name + " " + userProfile.last_name
  }&prefill_cognito_email=${userProfile.email}&prefill_cognito_user_id=${
    userProfile.user_id
  }&prefill_user_type=assistant&prefill_assistant_id=${userProfile.assistant_id}`;

  useEffect(() => {
    async function getResources() {
      const result = await api.get("/assistants/resources");
      setResources(result ? result.data : []);
    }

    if (!resources) {
      getResources();
    }
  }, [resources]);

  if (!resources) {
    return <Loading />;
  }

  return (
    <Container className="p-0" dataTestId="HelpCenter">
      <h3 className="module-spacing-bottom pl-2">Help Center</h3>
      <section className="row">
        {(resources.slice(0, 2) || []).map((resource) => {
          return (
            <div
              className="col-12 col-xl-6 col-lg-6 col-md-6 h-100 pb-5 mb-xl-0"
              key={resource.id}
              data-testid="resource-container"
            >
              <ResourceCard resource={resource} />
            </div>
          );
        })}
        {(resources.slice(2, resources.length) || []).map((resource) => {
          return (
            <div
              className="col-12 col-xl-3 col-lg-6 col-md-6 h-100 pb-5 mb-xl-0"
              key={resource.id}
              data-testid="resource-container"
            >
              <ResourceCompactCard resource={resource} />
            </div>
          );
        })}
      </section>
      <div className="d-block d-xl-flex text-center mx-5 mx-xl-0">
        <ButtonLink
          anchorProps={{
            rel: "noreferrer",
            target: "_blank",
          }}
          className="d-block d-xl-flex mb-3 mb-xl-0 mx-5 mx-xl-0"
          href="https://getmagic.com/help/assistants/"
          label="View all support articles"
          textClassName="justify-content-center"
        />
        <ButtonLink
          anchorProps={{
            rel: "noreferrer",
            target: "_blank",
          }}
          className="d-block d-xl-flex ml-0 ml-xl-3 mb-3 mb-xl-0 mx-5 mx-xl-0"
          href="mailto:hey@getmagicnow.com?subject=Dedicated%20Assistant%20Support"
          icon={<HeadphonesIcon className="mr-3 mb-1" />}
          label="Magic Support"
          textClassName="justify-content-center"
          type="PrimaryInverse"
        />
        <ButtonLink
          dataTestId="BugReportButton"
          anchorProps={{
            rel: "noreferrer",
            target: "_blank",
          }}
          className="d-block d-xl-flex ml-0 ml-xl-3 mx-5 mx-xl-0"
          href={bugSubmitLink}
          icon={<BugIcon className="mr-3 mb-1" />}
          label="Report A Bug"
          textClassName="justify-content-center"
          type="PrimaryInverse"
        />
      </div>
    </Container>
  );
};

export default HelpCenter;
