import axios from "axios";

const baseURL = process.env.REACT_APP_API_HOST;

const instance = axios.create({
  baseURL,
  timeout: 10000,
});

export default instance;
