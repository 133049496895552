import React, { useState, useEffect } from "react";
import * as Sentry from "@sentry/browser";
import { Auth } from "aws-amplify";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";

import {
  Alert,
  Loading,
  Password,
  TextField,
  Button,
  Container,
} from "@magic-engineering/components";

import api from "../../libs/public-api";
import Tracking from "@magic-engineering/magic-tracking";

const views = {
  LOADING: "LOADING",
  CHANGE_PASSWORD: "CHANGE_PASSWORD",
  INVALID_TOKEN: "INVALID_TOKEN",
};
export const ERROR_MESSAGES = {
  INVALID_TOKEN: "This link is no longer valid. Please request for a new registration link.",
  EXPIRED_TOKEN: "This link has expired. Please request for a new registration link.",
  INVALID_PASSWORD_LENGTH: "Password must be at least 8 characters",
  INVALID_PASSWORD_FORMAT: "Please enter a valid password",
  DEFAULT_MESSAGE: "Failed to save changes. Please try again.",
};
const minPasswordLength = 8;

const AuthCompleteRegistration = ({ token, setMode }) => {
  const [view, setView] = useState(views.LOADING);
  const [tempData, setTempData] = useState();
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  useEffect(() => {
    async function getTempData() {
      try {
        const { data } = await api.get(`/auth/registration/${token}`);

        setTempData(data);

        Sentry.setUser({
          email: data.email,
          username: `${data.first_name} ${data.last_name}`,
          id: data.user_id,
        });

        setView(views.CHANGE_PASSWORD);
        Tracking.authAssistantViewPartialRegistration({
          firstName: data.first_name,
          lastName: data.last_name,
          email: data.email,
        });
      } catch (e) {
        Tracking.authAssistantInvalidPartialRegistration({ authToken: token });
        setView(views.INVALID_TOKEN);
      }
    }

    if (!tempData) {
      getTempData();
    }
  }, [tempData, token]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    event.stopPropagation();

    setLoading(true);

    try {
      await api.post(`/auth/complete-registration/assistant/${token}`, { password });
      Tracking.authAssistantCompletePartialRegistration({
        firstName: tempData.first_name,
        lastName: tempData.last_name,
        email: tempData.email,
      });

      await Auth.signIn(tempData.email, password);
      history.push("/intro");
    } catch (e) {
      const message = e.response?.data;
      toast.error(ERROR_MESSAGES[message] || ERROR_MESSAGES.DEFAULT_MESSAGE);
      if (["EXPIRED_TOKEN", "INVALID_TOKEN"].includes(message)) {
        setView(views.INVALID_TOKEN);
      }
    } finally {
      setLoading(false);
    }
  };

  switch (view) {
    case views.LOADING:
      return <Loading size="Medium" />;
    case views.CHANGE_PASSWORD:
      return (
        <div className="container" data-testid="AuthCompleteRegistration">
          <div className="row">
            <div className="col"></div>
            <form className="col sign-in-container" onSubmit={handleSubmit}>
              <div className="pb-2 header">Complete registration</div>
              <TextField
                label="First name"
                disabled={true}
                placeholder={tempData.first_name}
                inputProps={{
                  "data-testid": "FirstName",
                  id: "first_name",
                }}
              />
              <TextField
                label="Last name"
                disabled={true}
                placeholder={tempData.last_name}
                inputProps={{
                  "data-testid": "LastName",
                  id: "last_name",
                }}
              />
              <TextField
                label="Email"
                disabled={true}
                placeholder={tempData.email}
                inputProps={{
                  "data-testid": "Email",
                  id: "email",
                }}
              />
              <Password
                label={"Password"}
                placeholder="**********"
                onChange={handlePasswordChange}
                required
                dataTestId="password"
                name="password"
                minLength={minPasswordLength}
              />
              <Button
                isLoading={loading}
                disabled={loading}
                type="Primary"
                className="float-right"
                label="SET NEW PASSWORD"
              />
            </form>
            <div className="col"></div>
          </div>
        </div>
      );
    default:
      return (
        <Container dataTestId="AuthCompleteRegistration_invalid_token">
          <Alert
            title="Invalid/Expired Registration Token"
            text="Something didn't work. Reset your password below to log in to your account"
            buttonLabel="Reset password"
            buttonTestId="ResetPasswordButton"
            onClick={() => {
              history.push("/");
              setMode({ mode: "forgot-password" });
            }}
          />
        </Container>
      );
  }
};

export default AuthCompleteRegistration;
