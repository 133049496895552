import React from "react";
import { ActionModal, Progress } from "@magic-engineering/components";
import format from "date-fns-tz/format";

import { ReactComponent as AssistantLevelIcon } from "../../assets/images/assistant-level-icon.svg";
import styles from "./AssistantLevelModal.module.scss";

const AssistantLevelModal = ({
  levels = [],
  level = {},
  nextLevel,
  points = 0,
  pointEvents = {},
  pointEventDefinitions = {},
  quarter = {},
  isOpen = false,
  onDismiss,
}) => {
  const sortedLevels = Object.keys(levels)
    .sort((a, b) => levels[a].max_points - levels[b].max_points)
    .map((l) => levels[l]);

  const progressSize = (1 / (sortedLevels.length - 1)) * 100;
  const currentIndex = sortedLevels.findIndex((l) => l.level_id === level.level_id);
  const currentProgress =
    currentIndex < sortedLevels.length - 1
      ? (points - level.min_points) / (level.max_points - level.min_points)
      : 1;
  const overallProgress =
    currentIndex < sortedLevels.length - 1
      ? (currentIndex / (sortedLevels.length - 1)) * 100 + currentProgress * progressSize
      : 100;

  const badges = sortedLevels.map((l, i) => (
    <div className={styles.BadgeIconContainer} key={i}>
      <div
        className={`${styles.BadgeIcon} ${
          i <= currentIndex ? styles.BadgeIconActive : ""
        } d-flex align-items-center justify-content-center`}
        data-testid={`Badge-${i}`}
      >
        {l.icon_alt && <img src={l.icon_alt} alt="" />}
        <span className={styles.BadgeIconText}>{l.label}</span>
      </div>
    </div>
  ));

  return (
    <ActionModal
      center={true}
      closeClassName={styles.Close}
      contentClassName={styles.ActionModalContent}
      icon={<AssistantLevelIcon height={124} width={124} />}
      iconClassName={`d-block ${styles.Icon}`}
      isOpen={isOpen}
      modalProps={{ ariaHideApp: false }}
      onDismiss={onDismiss}
      title="Level Up!"
      titleClassName={styles.Title}
      width="70%"
    >
      <div className={styles.AssistantLevelModal} data-testid="AssistantLevelModal">
        <div className={styles.Padding}>
          <span className="text-display4 d-block pb-2">
            Earn points and give a great service to unlock a new title and perks like faster
            re-matching.
          </span>
          {quarter.start_date && quarter.end_date && (
            <span
              className="text-display6 color-secondary d-block"
              data-testid="AssistantLevelQuarterDescription"
            >
              <small>
                Maintain your level by earning enough points between{" "}
                <span className="color-primary">
                  {format(new Date(quarter.start_date), "MMM dd")} -{" "}
                  {format(new Date(quarter.end_date), "MMM dd")}
                </span>
                .
              </small>
            </span>
          )}
          {badges.length > 0 && (
            <div
              className={`${styles.BadgeIcons} d-flex align-items-center justify-content-between`}
            >
              {badges}
            </div>
          )}
          <div
            className={`progress ${styles.ProgressBar} ${
              currentIndex > 0 && styles.ProgressBarElevated
            }`}
          >
            <div
              className={`progress-bar ${styles.Progress}`}
              data-testid="ProgressBar"
              role="progressbar"
              style={{ width: `${overallProgress}%` }}
            ></div>
          </div>
        </div>
        <hr />
        <div className={styles.Padding}>
          <div className="d-flex" data-testid="CurrentLevelText">
            <div
              className={`d-inline-flex ${styles.Badge} justify-content-center align-items-center mr-3`}
              style={{ backgroundColor: level.color || "transparent" }}
            >
              {level.icon && <img src={level.icon} alt="" />}
              {level.label && <span className="ml-1">{level.label}</span>}
            </div>
            {level.title && (
              <span className={`d-inline-flex text-display3 align-items-center ${styles.Level}`}>
                <strong>{level.title}</strong>
              </span>
            )}
          </div>
          <div className="mt-5" />
          {quarter.start_date && quarter.end_date && (
            <span
              className="d-block mb-2 color-secondary text-display3 text-left"
              data-testid="AssistantLevelQuarterText"
            >
              {format(new Date(quarter.start_date), "MMM dd")} -{" "}
              {format(new Date(quarter.end_date), "MMM dd")}
            </span>
          )}
          <Progress className="mb-2" value={parseInt(`${currentProgress * 100}`)} />
          <div className="d-flex justify-content-between">
            <h4 data-testid="Progress">
              {(points || 0).toFixed(2)} /{" "}
              {nextLevel
                ? ((level.max_points || 0) + 1).toFixed(2)
                : (level.max_points || 0).toFixed(2)}
            </h4>
            {nextLevel ? (
              <h4 data-testid="NextLevelText">
                {nextLevel.label}: {nextLevel.title}
              </h4>
            ) : null}
          </div>
          {nextLevel ? (
            <div className="text-left mt-4" data-testid="NextLevelDescription">
              <span>
                Get {(level.max_points + 1 - points).toFixed(2)} more points to earn the{" "}
                {nextLevel.label} Tier {nextLevel.title} badge.
              </span>
            </div>
          ) : null}
        </div>
        {Object.keys(pointEventDefinitions).length > 0 ? (
          <div>
            <hr />
            <div
              className={`row d-flex justify-content-center ${styles.Padding}`}
              data-testid="PointEvents"
            >
              {Object.keys(pointEventDefinitions).map((p, i) => (
                <div className="col-12 col-xl-4 col-lg-4 mt-5 mb-5" key={i}>
                  <span className={`d-block text-display4 color-primary ${styles.SectionTitle}`}>
                    {pointEventDefinitions[p]}
                  </span>
                  <span className={`d-block text-display1 ${styles.SectionNumber}`}>
                    <strong>{Math.floor(pointEvents[p] || 0)}</strong>
                  </span>
                  <span className={`d-block text-display2 ${styles.SectionPoints}`}>
                    <strong>Points</strong>
                  </span>
                </div>
              ))}
            </div>
          </div>
        ) : null}
      </div>
    </ActionModal>
  );
};

export default AssistantLevelModal;
