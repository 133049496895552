export function getLocalData(key) {
  try {
    const itemStr = localStorage.getItem(key);
    if (!itemStr) return null;
    const item = JSON.parse(itemStr);
    return item;
  } catch (error) {
    console.error(`Failed to get data from localStorage`, error.message, { key });
  }
}

export function saveLocalData(key, value) {
  try {
    localStorage.setItem(key, JSON.stringify(value));
  } catch (error) {
    console.error(`Failed to push data to localStorage`, error.message, { key, value });
  }
}
