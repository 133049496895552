import React, { useEffect, useState, Fragment } from "react";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import moment from "moment-timezone";

import api from "../../libs/api";

import ClockIn from "../../components/ClockIn/ClockIn";
import EditHoursForm, { isEditableDtr } from "../../components/EditHoursForm/EditHoursForm";
import GroupedTimeReports from "../../components/GroupedTimeReports/GroupedTimeReports";
import { ReactComponent as ArrowIcon } from "../../assets/images/chevron-left.svg";
import styles from "./DailyTimeReports.module.scss";
import { withLoader, Loading } from "@magic-engineering/components";

const DailyTimeReports = ({ clients, history }) => {
  const [dtr, setDtr] = useState();
  const [dtrs, setDtrs] = useState();
  const [client, setClient] = useState((clients && clients[0]) || {});

  const [isLoading, setIsLoading] = useState(false);

  const getDtrs = async () => {
    try {
      setIsLoading(true);
      const { start_date, end_date } = getWeekRange();
      const filter = {
        start_date,
        end_date,
        order: "DESC",
      };

      const { data } = await api.get("/dtrs", { params: filter });

      const formattedResult = (data || []).map((dtr) => {
        dtr.client = clients.find((c) => c.mongo_client_id === dtr.mongo_client_id);
        return dtr;
      });

      setDtrs(formattedResult);
    } catch (error) {
      toast.error("Something went wrong while fetching daily time reports.");
    } finally {
      setIsLoading(false);
    }
  };

  async function onRefresh() {
    window.scrollTo(0, 0);
    await getDtrs();
  }

  useEffect(() => {
    if (dtrs === undefined) {
      if (clients && clients.length > 0) {
        getDtrs();
      } else {
        setDtrs([]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dtrs, clients]);

  if (dtrs === undefined) {
    return <Loading />;
  }

  if (dtr) return <EditHoursForm {...dtr} onDismiss={() => setDtr(null)} onRefresh={onRefresh} />;

  const dtrHistory = (dtrs || []).filter(
    (d) =>
      (d.status !== "RUNNING" || new Date(d.clocked_out_at) <= new Date()) &&
      d.mongo_client_id === client.mongo_client_id
  );

  return (
    <div className={styles.DailyTimeReports} data-testid="DailyTimeReports">
      <ClockIn
        clients={clients}
        dtrs={dtrs || []}
        onRefresh={onRefresh}
        client={client}
        setClient={setClient}
      />
      <div className="mb-5" />
      <DailyTimeReportHistory
        dtrs={dtrHistory}
        setDtr={setDtr}
        client={client}
        isClockin={() =>
          (dtrs || []).find(
            (d) => d.status === "RUNNING" && new Date(d.clocked_out_at) > new Date()
          )
        }
        history={history}
        isComponentLoading={isLoading}
      />
    </div>
  );
};

const DailyTimeReportHistory = withLoader(({ dtrs = [], setDtr, isClockin, history }) => {
  const [showFullHistory, setShowFullHistory] = useState(false);
  const [editableDtrs, setEditableDtrs] = useState([]);

  useEffect(() => {
    setShowFullHistory(false);
    const editableDTRs = dtrs.filter((d) => isEditableDtr(d.clocked_out_at));
    setEditableDtrs(editableDTRs);
  }, [dtrs]);

  const groupedTimeReports = groupDtrsByDay(
    editableDtrs.length > 0 && !showFullHistory ? editableDtrs : dtrs
  );

  return (
    <Fragment>
      <div>
        {Object.keys(groupedTimeReports).map((g) => (
          <GroupedTimeReports
            date={g}
            group={groupedTimeReports[g]}
            key={g}
            onClick={(dtr) => {
              if (isClockin()) {
                toast.error("Please clock-out first before editing a Daily Time Record.");
              } else {
                setDtr(dtr);
              }
            }}
          />
        ))}
      </div>
      <div className={`container-fluid d-flex justify-content-between mt-2 ${styles.History}`}>
        <div>
          {editableDtrs.length > 0 && dtrs.length !== editableDtrs.length ? (
            <span
              className={`${styles.Links}`}
              onClick={() => setShowFullHistory(!showFullHistory)}
              data-testid="ToggleHistory"
            >
              <ArrowIcon
                className={`${styles.Icon} ${showFullHistory ? styles.Less : styles.More}`}
              />
              {showFullHistory ? "Hide Full Week" : "Full History"}
            </span>
          ) : null}
        </div>
        <span
          className={`${styles.Links}`}
          onClick={() => history.push("/timetracker")}
          data-testid="AllHistory"
        >
          All History
          <ArrowIcon className={`${styles.Icon} ${styles.Forward}`} />
        </span>
      </div>
    </Fragment>
  );
});

const groupDtrsByDay = (dtrs) =>
  dtrs.reduce((a, b) => {
    const date = moment(b.clocked_in_at).format("MM/DD/YYYY");
    (a[date] = a[date] || []).push(b);
    return a;
  }, {});

export const getWeekRange = () => {
  // default payroll cutoff time every Friday PHT 7AM
  const today = moment().tz("Asia/Manila");

  let weekStart = today
    .clone()
    .startOf("week")
    .weekday(-2) // billing week's PHT Friday or billingWeekStart friday
    .set({
      hour: 7,
      minutes: 0,
      seconds: 0,
    });

  let weekEnd = today
    .clone()
    .endOf("week")
    .weekday(5) //  billing week's PHT Friday or billingWeekEnd friday
    .set({
      hour: 7,
      minutes: 0,
      seconds: 0,
    });

  if (today.weekday() >= 5 && today.isAfter(weekEnd)) {
    weekStart.add(1, "week");
    weekEnd.add(1, "week");
  }

  return {
    start_date: weekStart.toDate(),
    end_date: weekEnd.toDate(),
  };
};

DailyTimeReports.propTypes = {};

DailyTimeReports.defaultProps = {};

export default withRouter(DailyTimeReports);
