import React from "react";

const AboutBuilder = ({ data }) => {
  if (!data["role"]) {
    return (
      <div data-testid="empty-about">
        Ask your client to fill out their profile so you can learn more about them, their preferred
        tools and their working preferences.
      </div>
    );
  }

  const getValue = (value, placeholder) => {
    return <span className={!value ? `placeholder` : null}>{value || `[${placeholder}]`}</span>;
  };

  const getSelectedValue = (data, key, keyOthers) => {
    return data[key] === keyOthers ? data[keyOthers] || "" : data[key] || "";
  };

  const getArticle = (value) => {
    var pattern = /^([aeiou])/i;
    return pattern.test(value) ? "an" : "a";
  };

  const buildAbout = (data) => {
    const role = getSelectedValue(data, "role", "role_others");
    const article = getArticle(role);

    const goal = getSelectedValue(data, "goal", "goal_others").toLowerCase();
    const need = getSelectedValue(data, "need", "need_others").toLowerCase();

    return (
      <div data-testid="about">
        {data.nickname ? `Call me ${data.nickname}. ` : ""}I am {article}{" "}
        {getValue(role, "role/title")} at {getValue(data.company, "company name")} with{" "}
        {getValue(data.company_size, "company size")} full time employees
        {need && goal ? ` and I am looking for assistant(s) who can ${need} to ${goal}` : ""}.
      </div>
    );
  };

  return buildAbout(data);
};

export default AboutBuilder;
