import React, { lazy, Suspense } from "react";
import { Loading } from "@magic-engineering/components";

const LazyTimeTrackerHistoryPage = lazy(() => import("./TimeTrackerHistoryPage"));

const TimeTrackerHistoryPage = (props) => (
  <Suspense fallback={<Loading />}>
    <LazyTimeTrackerHistoryPage {...props} />
  </Suspense>
);

export default TimeTrackerHistoryPage;
