import React, { lazy, Suspense } from "react";
import { Loading } from "@magic-engineering/components";

const LazySettingsPage = lazy(() => import("./SettingsPage"));

const SettingsPage = (props) => (
  <Suspense fallback={<Loading />}>
    <LazySettingsPage {...props} />
  </Suspense>
);

export default SettingsPage;
