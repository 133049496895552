import React from "react";
import moment from "moment";
import styles from "./DailyTimeReport.module.scss";
import { ClientCard } from "../ClientSelector/ClientSelector";

const DailyTimeReport = (props) => {
  const { client = {}, clocked_in_at, clocked_out_at, hours_worked = 0, onClick } = props;

  return (
    <ClientCard
      dataTestId="DailyTimeReport"
      className={styles.DailyTimeReport}
      onClick={() => onClick(reshapeDtr(props))}
      name={client.name}
      label={
        <div className="d-flex flex-grow justify-content-between align-items-center">
          <div className="d-flex flex-column">
            <div className="d-none d-xl-block d-lg-block d-md-block">
              <span>
                {displayTimeRange(clocked_in_at, clocked_out_at)} - <span>{client.name}</span>
              </span>
            </div>
            <div className="d-block d-xl-none d-lg-none d-md-none">
              <div className="d-flex flex-column">
                <span>{displayTimeRange(clocked_in_at, clocked_out_at)}</span>
                <span>{client.name}</span>
              </div>
            </div>
          </div>
          <span className={styles.TimeWorked} data-testid="TimeWorked">
            {parseHoursWorked(hours_worked)}
          </span>
        </div>
      }
      image={client.photo_url}
      showNextIcon
      avatarSize="Small"
    />
  );
};

const reshapeDtr = ({
  dtr_id,
  client = {},
  clocked_in_at,
  clocked_out_at,
  hours_worked = 0,
  notes,
}) => ({
  client: client,
  clockInDate: moment(clocked_in_at),
  clockOutDate: moment(clocked_out_at),
  dtrId: dtr_id,
  hoursWorked: hours_worked,
  notes: notes,
});

const displayTimeRange = (clocked_in_at, clocked_out_at) =>
  `${moment(clocked_in_at).format("hh:mm A")} to ${moment(clocked_out_at).format("hh:mm A")}`;

const parseHoursWorked = (hours_worked) => {
  if (hours_worked < 1) {
    const minutes_worked = hours_worked * 60;
    if (minutes_worked < 1) {
      const seconds_worked = minutes_worked * 60;
      return `${seconds_worked.toFixed(1)}s`;
    }
    return `${minutes_worked.toFixed(1)}m`;
  } else {
    return `${hours_worked.toFixed(1)}h`;
  }
};

DailyTimeReport.propTypes = {};

DailyTimeReport.defaultProps = {};

export default DailyTimeReport;
