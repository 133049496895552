import React, { useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import moment from "moment-timezone";
import {
  Alert,
  Button,
  ButtonLink,
  Container,
  RadioButtonSet,
  DatePickerField,
  TextField,
  TextArea,
} from "@magic-engineering/components";

import styles from "./HoursForm.module.scss";

import accountIcon from "../../assets/images/account.svg";
import calendarIcon from "../../assets/images/calendar.svg";

const HoursForm = ({
  alertProps,
  canEdit,
  client = {},
  clockInDate,
  clockOutDate,
  customActionLink,
  customActionText,
  isSubmitting = false,
  notes,
  onDismiss,
  onSubmit,
  submitText,
  submittingText,
  title,
}) => {
  const { control, formState, errors, handleSubmit, register, watch } = useForm();
  const data = watch();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const _onSubmit = (payload) => {
    if (payload["notes_other"]) {
      payload["notes"] = payload["notes_other"];
      delete payload["notes_other"];
    }

    return onSubmit(payload);
  };

  return (
    <Container className={`p-0`} onDismiss={onDismiss} dataTestId="HoursForm">
      <h3 className="pd-y-m">{title}</h3>
      {alertProps ? <Alert {...alertProps} center className="mg-b-s" /> : null}
      <form
        className={`${styles.Form} d-flex flex-column mx-auto`}
        onSubmit={handleSubmit(_onSubmit)}
      >
        <div>
          <TextField
            defaultValue={client.name}
            disabled={true}
            image={client.photo_url}
            icon={accountIcon}
            label="Client"
            labelColor="#8888B5"
            name="client"
            type="text"
            useAvatar={true}
          />
          <div>
            <div className="d-flex flex-column">
              <TextField
                defaultValue={formatTime(clockInDate)}
                disabled={true}
                icon={calendarIcon}
                label="Clocked in"
                labelColor="#8888B5"
                name="clocked_in_at"
                type="text"
              />
              {canEdit ? (
                <Controller
                  name="clocked_out_at"
                  control={control}
                  defaultValue={clockOutDate || ""}
                  rules={{
                    required: true,
                    validate: {
                      isAfter: (value) => value < new Date() || "invalid_clockout",
                    },
                  }}
                  render={(props) => (
                    <DatePickerField
                      labelColor="#8888B5"
                      includeTime={true}
                      minDate={clockInDate}
                      label="Clocked out"
                      defaultValue={props.value}
                      onChange={props.onChange}
                      errors={errors}
                      errorMessages={errorMessages}
                      format={`MMM DD, YYYY, hh:mmA [${getTimezone()}]`}
                      name="clocked_out_at"
                      placeholder="Enter clocked out time"
                    />
                  )}
                />
              ) : (
                <TextField
                  defaultValue={formatTime(clockOutDate)}
                  disabled={true}
                  icon={calendarIcon}
                  label="Clocked out"
                  labelColor="#8888B5"
                  name="clocked_out_at"
                  type="text"
                />
              )}
            </div>
          </div>
        </div>
        {createChoices(control, choices, notes, canEdit, errors)}
        <div className="mt-3" />
        {createOtherField(data, notes, errors, register)}
        {!customActionLink ? (
          <div className="d-flex mg-t-s">
            <Button
              label={submitText}
              className="mr-2"
              dataTestId="SubmitButton"
              disabled={isSubmitting || formState.isSubmitting}
              isLoading={isSubmitting || formState.isSubmitting}
              loadingText={submittingText}
            />
            <Button
              label="Cancel"
              className="ml-2"
              dataTestId="CancelButton"
              disabled={isSubmitting || formState.isSubmitting}
              onClick={onDismiss}
              type="PrimaryInverse"
            />
          </div>
        ) : (
          <div className="d-flex">
            <ButtonLink
              label={customActionText}
              href={customActionLink}
              dataTestId="CustomActionButton"
              type="PrimaryInverse"
            />
          </div>
        )}
      </form>
    </Container>
  );
};

const errorMessages = {
  invalid_clockout: {
    title: "Invalid clockout date",
    text: "Your clock out date should be not be set in the future.",
  },
};
const createChoices = (control, choices, defaultValue, canEdit, errors) => (
  <Controller
    name="notes"
    control={control}
    defaultValue={choices.includes(defaultValue) ? defaultValue : defaultValue ? OTHERS_FIELD : ""}
    rules={{
      required: true,
    }}
    render={({ onChange, value }) => (
      <RadioButtonSet
        label="Summary:"
        choices={choices.map((c) => ({
          label: c,
          value: c,
        }))}
        defaultValue={value}
        name="notes"
        onChange={onChange}
        disabled={canEdit === false}
        className={"mb-0"}
        errors={errors}
      />
    )}
  />
);

const createOtherField = (data, defaultValue, errors, register) =>
  (defaultValue && !choices.includes(defaultValue) && !data["notes"]) ||
  data["notes"] === OTHERS_FIELD ? (
    <TextArea
      defaultValue={!choices.includes(defaultValue) ? defaultValue : ""}
      errors={errors}
      hideLabel={true}
      label="Summary"
      minRows={3}
      name="notes_other"
      placeholder={"Summarize your session"}
      maxLength={100}
      ref={register({ required: true, maxLength: 100 })}
    />
  ) : null;

const getTimezone = () => {
  var zone = moment.tz.guess();
  return moment.tz(zone).format("z");
};

const DEFAULT_FORMAT = `MMM DD, YYYY, hh:mmA [${getTimezone()}]`;

const formatTime = (date) => (date ? `${date.format(DEFAULT_FORMAT)}` : ``);

const choices = [
  "I completed all my tasks.",
  "I'm progressing with my tasks.",
  "I'm blocked, waiting on client instruction.",
  "I don't have tasks to work on right now.",
  "Other - please explain",
];

const OTHERS_FIELD = "Other - please explain";

HoursForm.propTypes = {};

HoursForm.defaultProps = {};

export default HoursForm;
