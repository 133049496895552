import React, { useEffect, useState } from "react";
import moment from "moment";
// import PropTypes from 'prop-types';
import styles from "./ClockTimer.module.scss";

const formatTime = (time) => (
  <span className={styles.Time}>
    <span className={styles.Dark}>
      {time.hours}:{time.minutes}
    </span>
    <span className={styles.Light}>.{time.seconds}</span>
  </span>
);

const calculateDuration = (startTime, endTime) => {
  return moment.duration(endTime.diff(startTime));
};

const calculateTimeDifference = (duration) => {
  return {
    hours: `0${parseInt(duration.asHours())}`.slice(-2),
    minutes: `0${parseInt(duration.asMinutes() % 60)}`.slice(-2),
    seconds: `0${parseInt((duration.asSeconds() % 60) % 60)}`.slice(-2),
  };
};

const ClockTimer = ({ time, onUpdate, disabled }) => {
  const [timeDifference, setTimeDifference] = useState({
    hours: "00",
    minutes: "00",
    seconds: "00",
  });

  useEffect(() => {
    if (time) {
      const updateDuration = () => {
        const duration = calculateDuration(moment(time), moment());
        setTimeDifference(calculateTimeDifference(duration));
        onUpdate(duration);
      };
      updateDuration();
      const timer = setInterval(updateDuration, 500);
      return () => {
        clearInterval(timer);
      };
    }
  }, [time]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div
      className={`${styles.ClockTimer} ${disabled ? styles.Disabled : ""}`}
      data-testid="ClockTimer"
    >
      <span className="d-flex align-items-center justify-content-center" data-testid="ClockTime">
        {formatTime(timeDifference)}
      </span>
    </div>
  );
};

ClockTimer.propTypes = {};

ClockTimer.defaultProps = {};

export default ClockTimer;
