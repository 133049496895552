import React from "react";
import { Redirect } from "react-router";

const STORAGE_KEY = "featureFlag";

const FeatureFlagPage = ({ enable = true }) => {
  if (enable) {
    localStorage.setItem(STORAGE_KEY, true);
  } else {
    localStorage.removeItem(STORAGE_KEY);
  }
  return <Redirect to={"/"} />;
};

export default FeatureFlagPage;
