import React, { useCallback, useEffect, useState } from "react";
import moment from "moment-timezone";
import { toast } from "react-toastify";
import _get from "lodash/get";
import format from "date-fns-tz/format";
import { Alert, Button, Container, Loading, UserAvatar } from "@magic-engineering/components";
import api from "../../libs/api";
import forward from "../../assets/images/forward.svg";
import helpCircle from "../../assets/images/help-circle.svg";
import ChangeClientForm from "../ChangeClientForm/ChangeClientForm";
import ClockTimer from "../ClockTimer/ClockTimer";
import SubmitHoursForm from "../../components/SubmitHoursForm/SubmitHoursForm";
import OverTimeRequestForm from "../OverTimeRequestForm/OverTimeRequestForm";

import styles from "./ClockIn.module.scss";
import { getWeekRange } from "../DailyTimeReports/DailyTimeReports";

import Tracking from "@magic-engineering/magic-tracking";
import { isPendingPlan } from "../../libs/helpers";

const views = {
  DEFAULT: "DEFAULT", // running time, probably clocked_out or time is ticking
  CHANGE_CLIENT: "CHANGE_CLIENT",
  CLOCKOUT_FORM: "CLOCKOUT_FORM",
  OT_FORM: "OT_FORM",
};

const ClockIn = ({ clients = [], dtrs = [], onRefresh, client, setClient }) => {
  const [view, setView] = useState(views.DEFAULT);

  const [ots, setOts] = useState([]);
  // ots from PG
  
  const [dtr, setDtr] = useState(null);
  // currently running DTR; null if the user is not clocked in

  const [hoursTotal, setHoursTotal] = useState(0);
  // required weekly hours; does not include overtime hours

  const [hoursTracked, setHoursTracked] = useState(0);
  // hours tracked in finished DTRs; deos not include the current clock in duration

  const [hoursOngoing, setHoursOngoing] = useState(0);
  // hoursTracked + current clocked in duration

  const [hoursRemaining, setHoursRemaining] = useState(0);
  // weekly default hours + OTs - hoursOngoing

  const [clockInDate, setClockInDate] = useState(null);
  // has the value of dtr.clocked_in_at when currently clocked in

  const [clockOutDate, setClockOutDate] = useState(null);
  // holds the time to be used as clock_out_date when manually clocking out

  const [isClockingOut, setIsClockingOut] = useState(false);
  // true when the call to DTRs to clock out is pending

  const [isSubmitting, setIsSubmitting] = useState(false);
  // true when the call to DTR to clock in is pending (similar to isClockingOut)

  const [showClockInTooltip, setShowClockInTooltip] = useState(false);
  // simply show a small alert that explains what clock in means

  const [isLoading, setIsLoading] = useState(true);
  // initial loading when fetching the overtime requests

  const hasClient = client && Object.keys(client).length > 0;
  const isTimetrackingDisabled = !hasClient || isPendingPlan(client);

  useEffect(() => {
    let approvedOts = 0;
    if (ots.length) {
      approvedOts = ots.filter((a) => a.status === "APPROVED").reduce((a, b) => a + b.hours, 0);
    }
    setHoursRemaining((hoursTotal || 0) - hoursOngoing + approvedOts);
  }, [hoursTotal, hoursOngoing, ots]);

  useEffect(() => {
    async function getOts() {
      try {
        setIsLoading(true);
        setOts([]);
        const result = await api.get(`/dtrs/ots?mongo_client_id=${client.mongo_client_id}`);
        setOts(result ? result.data : []);
      } catch (error) {
        toast.error("Something went wrong while fetching overtime requests.");
      } finally {
        setIsLoading(false);
      }
    }
    if (hasClient) {
      setHoursTotal(client.default_hours);
      getOts();
    } else {
      setIsLoading(false);
    }
  }, [client, hasClient]);

  useEffect(() => {
    if (!client && clients.length > 0) setClient(clients[0]);
  }, [clients, client, setClient]);

  useEffect(() => {
    const ongoingDtrs = (dtrs || []).filter(
      (r) => r.status === "RUNNING" && new Date(r.clocked_out_at) > new Date()
    );
    if (ongoingDtrs.length > 0) setClient(ongoingDtrs[0].client);
  }, [dtrs, setClient]);

  useEffect(() => {
    if (client && dtrs.length) {
      const filteredDtrs = (dtrs || []).filter((r) => r.mongo_client_id === client.mongo_client_id);

      setDtr(
        filteredDtrs.find((r) => r.status === "RUNNING" && new Date(r.clocked_out_at) > new Date())
      );
      const hoursTracked = filteredDtrs
        .filter((d) => d.status === "NEW" || new Date(d.clocked_out_at) <= new Date())
        .reduce((a, b) => a + (b.hours_worked || 0), 0);
      setHoursOngoing(hoursTracked);
      setHoursTracked(hoursTracked);
    }
  }, [client, dtrs]);

  useEffect(() => {
    setClockInDate(dtr ? moment(dtr.clocked_in_at) : null);
    setClockOutDate(null);
  }, [dtr]);

  const updateHoursCallback = useCallback(
    (duration) => {
      const autoClockOutAt = dtr ? dtr.hours_worked + hoursTracked : Infinity;
      const newHoursOngoing = Math.min(hoursTracked + duration.asHours(), autoClockOutAt);
      setHoursOngoing(() => newHoursOngoing);
      if (newHoursOngoing === autoClockOutAt) {
        setClockInDate(null);
        setClockOutDate(null);
        setView(views.DEFAULT);
        onRefresh();
      }
    },
    [hoursTracked, dtr, onRefresh]
  );

  const changeClient = (toClient) => {
    if (!clockInDate || hoursRemaining < Number.EPSILON) {
      setClient(toClient);
    } else {
      toast.error(`You cannot switch clients while you have ongoing work with ${client.name}!`);
    }
  };

  let isClockingIn = false; // unfortunately state mutation is not faster than a double-click on a slow computer

  async function clockIn() {
    try {
      if (!isClockingIn) {
        isClockingIn = true;
        setIsSubmitting(true);
        const result = await api.post("/dtrs", {
          mongo_client_id: client.mongo_client_id,
          action: "clock-in",
        });

        if (result) {
          Tracking.clockIn();
          setDtr(result.data);
          setClockInDate(moment(result.data.clocked_in_at));
        }
      }
    } catch (error) {
      const errorCode = _get(error, "response.data");
      switch (errorCode) {
        case "ALREADY_CLOCKED_IN": {
          toast.error("An error occurred. Looks like you're already clocked in.");
          onRefresh();
          break;
        }
        case "NO_MORE_HOURS_LEFT": {
          toast.error("An error occurred. You've already completed your hours for the week.");
          onRefresh();
          break;
        }
        default: {
          toast.error("An error occurred. Please try again.");
        }
      }
    } finally {
      isClockingIn = false;
      setIsSubmitting(false);
    }
  }

  async function clockOut(payload) {
    setIsClockingOut(true);

    try {
      await api.post("/dtrs", {
        mongo_client_id: client.mongo_client_id,
        action: "clock-out",
        ...payload,
      });

      Tracking.clockOut();

      setIsClockingOut(false);

      setClockInDate(null);
      setClockOutDate(null);
      setView(views.DEFAULT);

      onRefresh();
    } catch (error) {
      setIsClockingOut(false);
      const errorCode = _get(error, "response.data");
      switch (errorCode) {
        case "NOT_CLOCKED_IN": {
          toast.error("An error occurred. You are not clocked in.");
          setClockInDate(null);
          setClockOutDate(null);
          onRefresh();
          setView(views.DEFAULT);
          break;
        }
        default: {
          toast.error("An error occurred. Please try again.");
        }
      }
    }
  }

  const weekRange = displayWeekRange();

  switch (view) {
    case views.OT_FORM:
      return (
        <OverTimeRequestForm
          mongo_client_id={client.mongo_client_id}
          onDismiss={(otRequest) => {
            if (otRequest) {
              setOts(ots.concat(otRequest));
            }
            setView(views.DEFAULT);
          }}
        />
      );
    case views.CHANGE_CLIENT:
      return (
        <ChangeClientForm
          client={client}
          clients={clients}
          onChange={(client) => changeClient(client)}
          onDismiss={() => setView(views.DEFAULT)}
        />
      );
    case views.CLOCKOUT_FORM:
      return (
        <SubmitHoursForm
          client={client}
          clockInDate={clockInDate}
          clockOutDate={clockOutDate}
          isSubmitting={isClockingOut}
          onDismiss={() => {
            setView(views.DEFAULT);
            setClockOutDate(null);
          }}
          onSubmit={(payload) => {
            clockOut(payload);
          }}
        />
      );
    case views.DEFAULT:
    default:
      return (
        <Container
          className={`${styles.ClockIn} ${isTimetrackingDisabled ? styles.Disabled : ""}`}
          dataTestId="ClockIn"
        >
          <h3 className="mb-3" data-testid="ClockinHeader">
            {isTimetrackingDisabled && hasClient ? (
              <>
                <span className={styles.TitleBold}>Timetracker</span> will activate on{" "}
                {format(new Date(client.start_date), "MMMM d, yyyy @ haa z")}
              </>
            ) : isTimetrackingDisabled && !hasClient ? (
              <>
                <span className={styles.TitleBold}>Timetracker</span> will activate once you start
                working with a client
              </>
            ) : (
              <>
                Week Ends: <span data-testid="WeekRange">{weekRange}</span>
              </>
            )}
          </h3>
          {isLoading ? (
            <Loading />
          ) : (
            <>
              {clients.length > 0 ? (
                <div
                  className="d-flex align-items-center mb-4 justify-content-between justify-content-xl-start justify-content-lg-start"
                  data-testid="ChangeClientButton"
                  role="button"
                  onClick={() => {
                    setView(views.CHANGE_CLIENT);
                  }}
                >
                  <div>
                    <UserAvatar
                      image={(client || {}).photo_url}
                      name={(client || {}).name}
                      label={(client || {}).name}
                      size="Small"
                      labelTestId="ClientName"
                    />
                  </div>
                  <img className={styles.Forward} src={forward} alt="" />
                </div>
              ) : (
                <div
                  className="d-flex align-items-center mb-4 justify-content-between justify-content-xl-start justify-content-lg-start"
                  data-testid="ChangeClientButton"
                >
                  <UserAvatar
                    disabled={isTimetrackingDisabled}
                    label="No Client"
                    size="Small"
                    labelTestId="ClientName"
                  />
                </div>
              )}
              <div className="d-flex">
                <HoursBox
                  hours={hoursOngoing}
                  text="Hours Tracked"
                  testId="HoursTracked"
                  className="mg-r-m"
                />
                <HoursBox
                  hours={hoursRemaining < Number.EPSILON ? 0 : hoursRemaining}
                  text="Hours Remaining"
                  testId="HoursRemaining"
                />
              </div>
              <div className="mg-t-xl" />
              <ProgressBar value={parseInt((hoursOngoing / hoursTotal) * 100)} />
              <div className="mg-t-s" />
              {hoursRemaining >= Number.EPSILON || isTimetrackingDisabled ? (
                <ClockTimer
                  time={clockInDate}
                  onUpdate={updateHoursCallback}
                  disabled={isTimetrackingDisabled}
                />
              ) : null}
              <div className="d-flex align-items-center justify-content-center">
                {clockInDate && hoursRemaining >= Number.EPSILON ? (
                  <Button
                    disabled={isTimetrackingDisabled}
                    dataTestId="ClockOutButton"
                    onClick={() => {
                      setView(views.CLOCKOUT_FORM);
                      setClockOutDate(moment());
                    }}
                    label="Clock Out"
                    type="Highlighted"
                  />
                ) : (
                  (hoursRemaining >= Number.EPSILON || isTimetrackingDisabled) && (
                    <>
                      <Button
                        className={isTimetrackingDisabled ? styles.DisabledButton : ""}
                        dataTestId="ClockInButton"
                        onClick={() => clockIn()}
                        label="Clock In"
                        disabled={isTimetrackingDisabled || isSubmitting}
                        isLoading={isSubmitting}
                        loadingText={"Clocking In..."}
                      />
                      <span className="position-relative">
                        <img
                          className={styles.HelpIcon}
                          src={helpCircle}
                          alt="Clock In Help Icon"
                          role="button"
                          onClick={() => setShowClockInTooltip(true)}
                        />
                      </span>
                    </>
                  )
                )}
              </div>
              <div className="mt-4" />
              {showClockInTooltip ? (
                <Alert
                  center
                  className="mg-y-s"
                  title="What does this mean?"
                  text="Clocking in refers to the time you make yourself available for your clients."
                  onDismiss={() => setShowClockInTooltip(false)}
                ></Alert>
              ) : null}

              {clockInDate &&
              hoursRemaining <= client.default_hours / 5 &&
              hoursRemaining >= Number.EPSILON ? (
                <Alert
                  center
                  className="mg-y-s"
                  title={`You're close to completing your ${client.default_hours} hours for the week.`}
                  text={
                    `You have less than ${hoursRemaining
                      .toFixed(1)
                      .replace(/[.,]0$/, "")} hours remaining for this week.\n` +
                    `You may request for extra hours when you have completed your required weekly hours.`
                  }
                />
              ) : (
                dtr && (
                  <Alert
                    dataTestId="DefaultClockOut"
                    center
                    className="mg-y-s"
                    title={`Important: We'll clock you out on your behalf at exactly ${moment(
                      dtr.closed_at
                    ).format("h:mm A, MMMM Do")}.`}
                    text={`If you need more time to work, just clock back in.`}
                  />
                )
              )}
              {isTimetrackingDisabled ? null : (
                <ExtraHoursAlert
                  clockInDate={clockInDate}
                  hoursRemaining={hoursRemaining}
                  ots={ots}
                  setView={setView}
                  weekRange={weekRange}
                />
              )}
            </>
          )}
        </Container>
      );
  }
};

const ExtraHoursAlert = ({ hoursRemaining, ots, setView, weekRange }) => {
  if (
    hoursRemaining < Number.EPSILON &&
    (ots.length === 0 || ots.filter((ot) => ot.status === "APPROVED").length === ots.length)
  ) {
    return (
      <Alert
        center
        className="mg-y-s"
        title="Congrats!"
        text="You've completed your hours for the week! Need to work extra hours?"
        buttonLabel="Request Extra Hours"
        onClick={() => {
          setView(views.OT_FORM);
        }}
      />
    );
  }
  if (ots.find((ot) => ot.status === "REQUESTED")) {
    return (
      <Alert
        center
        className="mg-y-s"
        title="Extra Hours Pending Approval"
        text="Check back later. An email has been sent to your client with the approval link."
      />
    );
  }
  if (ots.find((ot) => ot.status === "DENIED")) {
    return (
      <Alert
        center
        className="mg-y-s"
        title="Extra Hours Not Approved"
        text={`Unfortunately your request for extra hours this week ${weekRange} was not approved. Please speak with your client for more info.`}
      />
    );
  }
  return null;
};

const HoursBox = ({ hours, text, testId, className }) => (
  <Container
    withCard
    className={`d-flex flex-column align-items-center justify-content-center ${className} ${styles.HoursBox}`}
    dataTestId={testId}
  >
    <span className={styles.HoursTitle}>
      {hours > 0 ? hours.toFixed(1).replace(/[.,]0$/, "") : "00"}
    </span>
    <span className={styles.HoursText}>{text}</span>
  </Container>
);

const ProgressBar = ({ value }) => (
  <div className={`${styles.Progress} progress`}>
    <div
      className={`${styles.ProgressBar} progress-bar`}
      role="progressbar"
      style={{
        width: `${value}%`,
      }}
      aria-valuenow={value}
      aria-valuemin="0"
      aria-valuemax="100"
    ></div>
  </div>
);

const displayWeekRange = () => {
  const { end_date } = getWeekRange();
  const weekEnd = moment(end_date).tz("Asia/Manila");
  return `${weekEnd.format("dddd MMMM DD [at] hA")} PHT`;
};

ClockIn.propTypes = {};

ClockIn.defaultProps = {};

export default ClockIn;
