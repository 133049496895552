import React, { useState } from "react";
import { toast } from "react-toastify";
import { Button } from "@magic-engineering/components";
import { Auth } from "aws-amplify";
import "./common.scss";

const forgotPasswordAmplify = async (username) => {
  try {
    await Auth.forgotPassword(username);
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export default function ForgotPassword({ setMode, username: initialUsername }) {
  const [username, setUsername] = useState(initialUsername);
  const [isLoading, setIsLoading] = useState(false);
  const [emailSent, setEmailSent] = useState(false);

  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsLoading(true);
    try {
      await forgotPasswordAmplify(username);
      toast.success("Email sent!");
      setEmailSent(true);
    } catch (error) {
      toast.error(error.message);
      setIsLoading(false);
    }
  };

  return (
    <div className="container" data-testid="forgot-password">
      <div className="row">
        <div className="col"></div>
        {emailSent ? (
          <div className="col sign-in-container">
            <div className="pb-2 header">Reset password email sent!</div>
            <div className="pt-3 pb-3 new-user">
              Please check your email inbox and follow the instructions. Email sent to {username}
            </div>
            <div className="pt-3">
              <button
                type="submit"
                className="float-right"
                onClick={() => setMode({ mode: "sign-in", username })}
              >
                CONTINUE
              </button>
            </div>
          </div>
        ) : (
          <form className="col sign-in-container" onSubmit={handleSubmit}>
            <div className="pb-2 header">Reset your password</div>
            <div className="col pt-2 pb-3 input-field">
              <label className="row">
                <span className="mt-1 mb-1">Email</span>
                <input
                  type="email"
                  className="form-control mt-2"
                  placeholder="jane@mycompany.com"
                  onChange={handleUsernameChange}
                  defaultValue={username}
                  required
                />
              </label>
            </div>
            <div className="pt-3">
              <Button
                isLoading={isLoading}
                disabled={isLoading}
                loadingText=""
                className="float-right mb-3"
                label="SEND CODE"
              />
            </div>
            <div className="row col pt-3 pb-3 pr-0 m-0 justify-content-end back-to-login">
              Back to
              <span className="ml-1" onClick={() => setMode({ mode: "sign-in", username })}>
                Login
              </span>
            </div>
          </form>
        )}
        <div className="col"></div>
      </div>
    </div>
  );
}
