import React from "react";
import styles from "./AppWrapper.module.scss";
import { DismissibleAlert, Loading, CollapsibleList } from "@magic-engineering/components";
import ClientList from "../../components/ClientList/ClientList";
import Announcements from "../../components/Announcements/Announcements";
import HelpCenter from "../../components/HelpCenter/HelpCenter";
import DailyTimeReports from "../../components/DailyTimeReports/DailyTimeReports";
import CancellationNotifications from "../../components/CancellationNotifications/CancellationNotifications";
import PlanNotifications from "../../components/PlanNotifications/PlanNotifications";
import ContractSignNotification from "../../components/ContractSignNotification/ContractSignNotification";
import { ReactComponent as InfoIcon } from "../../assets/images/info.svg";
import _ from "lodash";

const AppWrapper = ({ profile }) => {
  if (!profile) {
    return <Loading />;
  }

  const { waiting_for_contractor_sign } = profile?.contracts?.links || {};

  return (
    <div className={styles.AppWrapper} data-testid="AppWrapper">
      <CollapsibleList className="mg-b-l">
        {!_.isEmpty(waiting_for_contractor_sign) && (
          <ContractSignNotification
            key="contract-sign-notification"
            email={profile.deel_email ? profile.deel_email : profile.email}
            contractLink={_.first(waiting_for_contractor_sign)}
          />
        )}
        <PlanNotifications
          key="plan-notifications"
          clients={profile.clients}
          profile={profile.profile}
        />
        <CancellationNotifications key="cancellation-notifications" clients={profile.clients} />
        {(profile.clients || []).length === 0 && [
          <DismissibleAlert
            key="no-clients-alert-1"
            className="mg-b-l"
            icon={<InfoIcon />}
            iconClassName="mb-1 mr-2"
            title="How to Get Matched with a Client Fast:"
            titleClassName="d-flex align-items-center"
            text={
              <div className="text-left">
                <ul>
                  <li>
                    Check your email constantly. Magic will reach out to you if there's a job
                    request that matches your skillset. Each job post is first come first serve so
                    respond promptly.
                  </li>
                  <li>
                    Update your profile. The more complete and comprehensive your profile, the
                    higher your chances of getting matched.
                  </li>
                  <li>
                    Join Magic Learning. Magic Learning offers courses that, if completed, give you
                    matching priority over others.{" "}
                    <a href="mailto:training@getmagicnow.com">Join here</a>.
                  </li>
                </ul>
              </div>
            }
          />,
          <DismissibleAlert
            key="no-clients-alert"
            className="mg-b-l"
            icon={<InfoIcon />}
            iconClassName="mb-1 mr-2"
            title="Once you start working with a client, access this portal to:"
            titleClassName="d-flex align-items-center"
            text={
              <div className="text-left">
                <ul>
                  <li>Review your client profile</li>
                  <li>
                    Record your work and the time that you make yourself available to work for your
                    clients
                  </li>
                  <li>See a record and statuses of your earnings</li>
                </ul>
              </div>
            }
          />,
        ]}
      </CollapsibleList>
      <>
        <DailyTimeReports clients={profile.clients} />
        <div className="mg-b-xxxl" />
      </>
      <Announcements />
      <div className="module-spacing-top" />
      <HelpCenter />
      <div className="module-spacing-top" />
      <ClientList clients={(profile || {}).clients || []} />
    </div>
  );
};

AppWrapper.propTypes = {};

AppWrapper.defaultProps = {};

export default AppWrapper;
