import React, { useState } from "react";
import moment from "moment";
import format from "date-fns-tz/format";
import styles from "./Client.module.scss";
import AboutBuilder from "../Profile/AboutBuilder";
import fields from "../Profile/fields";

// contacts
import gmail from "../../assets/images/contacts/ded-contact-gmail.svg";
import otherContact from "../../assets/images/contacts/ded-contact-other.svg";
import signal from "../../assets/images/contacts/ded-contact-signal.svg";
import slack from "../../assets/images/contacts/ded-contact-slack.svg";
import telegram from "../../assets/images/contacts/ded-contact-telegram.svg";
import whatsapp from "../../assets/images/contacts/ded-contact-whatsapp.svg";

// tools
import asana from "../../assets/images/tools/ded-tools-asana.svg";
import dropbox from "../../assets/images/tools/ded-tools-dropbox.svg";
import gsuite from "../../assets/images/tools/ded-tools-gsuite.svg";
import monday from "../../assets/images/tools/ded-tools-monday.svg";
import ms365 from "../../assets/images/tools/ded-tools-ms-365.svg";
import notion from "../../assets/images/tools/ded-tools-notion.svg";
import otherTool from "../../assets/images/tools/ded-tools-other.svg";
import trello from "../../assets/images/tools/ded-tools-trello.svg";
import zoom from "../../assets/images/tools/ded-tools-zoom.svg";

import avatar from "../../assets/images/avatar.svg";

// circle
import emptyProgressCircle from "../../assets/images/progress-circle.svg";
import progressCircle from "../../assets/images/progress.svg";

import { isPendingPlan } from "../../libs/helpers";

const parseJSONArray = (array, defaultValue) => {
  return array instanceof Array ? array : JSON.parse(array || "[]");
};

function Client({ mongo_client_id, profile }) {
  const [clientProfile] = useState(profile);

  if (!profile || !Object.keys(profile).length) {
    return <div data-testid="Client" />;
  }

  const {
    client_id,
    start_date,
    default_hours,
    status,
    name,
    role,
    role_others,
    company,
    photo_url,
    message_me,
    message_others,
    country_name,
    region_name,
    city_name,
  } = clientProfile;

  const _location = [city_name, region_name, country_name].filter((l) => !!l).join(", ");

  const buildMessageArray = () => {
    const messageArray = parseJSONArray(message_me);
    const messageOthers = parseJSONArray(message_others);
    const hasOtherMessages = messageArray.includes("Other(s)");

    return !hasOtherMessages
      ? messageArray
      : messageArray.filter((m) => m !== "Other(s)").concat(messageOthers);
  };

  const roleDetails = role === "role_others" ? role_others : role;

  const messageDetails = buildMessageArray().map((m, i) => (
    <span className="d-block" key={i}>
      {m}
    </span>
  ));

  const photoBlock = (
    <div className={`${styles.imgBlock} d-flex align-items-center justify-content-center`}>
      <div className={styles.circle}>
        <img
          className={styles.full}
          src={photo_url ? progressCircle : emptyProgressCircle}
          alt=""
        />
      </div>
      <img src={photo_url || avatar} className={styles.photo} height="165" width="165" alt="" />
    </div>
  );

  const profileBlock = (
    <div className="d-flex flex-column ml-4 ml-xl-0 ml-lg-0 align-items-center justify-content-center">
      <span className={`${styles.name} d-block text-center`}>
        {isPendingPlan({ start_date, status }) ? (
          <span className={styles.Highlighted}>NEW: </span>
        ) : null}
        <span>{name}</span>
      </span>
      {roleDetails && company ? (
        <span className={`${styles.text} d-block text-center`}>
          <span className={styles.capitalize}>{roleDetails}</span> at {company}
        </span>
      ) : null}
    </div>
  );

  const planBlock = (
    <div className="d-flex flex-column mb-4">
      <span className={styles.title}>Plan Details</span>
      <span className={`${styles.text} mt-1`}>
        <span className="d-block">
          Start Date:{" "}
          {start_date ? format(new Date(start_date), "MMMM d, yyyy @ hh:mm aa z") : null}
        </span>
        <span className="d-block">Hours needed per week: {default_hours}</span>
      </span>
    </div>
  );

  const contactBlock = (
    <>
      <div className="d-flex flex-column mb-4">
        <div>
          <span className={styles.title}>Contact me via</span>
          <ContactOptions communication={profile.communication} />
        </div>
      </div>
      {messageDetails.length ? (
        <div className="d-flex flex-column mb-4">
          <span className={styles.title}>How often?</span>
          <span className={`${styles.text} mt-1`}>{messageDetails}</span>
        </div>
      ) : null}
      <div className="d-flex flex-column">
        <span className={styles.title}>Software I use at work</span>
        <ToolOptions tools={profile.tools} tool_others={profile.tool_others} />
      </div>
    </>
  );

  return (
    <div className={styles.Client} data-testid="Client" id={`client-${client_id}`}>
      <div className={`card ${styles.card}`}>
        <div className="row no-gutters mb-5 mt-3">
          <div className="col-12 col-xl-3 col-lg-4 d-none d-xl-flex flex-row flex-xl-column flex-lg-column align-items-center mb-4">
            {photoBlock}
            {profileBlock}
          </div>
          <div className="col-12 col-lg-4 d-flex d-xl-none flex-row flex-xl-column flex-lg-column align-items-center mb-4">
            {photoBlock}
            {profileBlock}
          </div>
          <div className="col-12 col-xl-9 col-lg-8 pl-40">
            {planBlock}
            <ProfileBlock profile={profile} location={_location} />
            {contactBlock}
          </div>
        </div>
      </div>
    </div>
  );
}

export function ProfileBlock({ profile, location }) {
  const { timezone, birthdate, sex, sex_others } = profile;

  const gender = sex === "sex_others" ? sex_others : sex;

  return (
    <>
      <div className="d-flex flex-column mb-4">
        <span className={styles.title}>About me</span>
        <span className={`${styles.text} mt-1`}>
          <AboutBuilder data={profile} />
        </span>
      </div>
      <div className="d-flex flex-column mb-4">
        <span className={styles.title}>Basic information</span>
        <span className={`${styles.text} mt-1`}>
          <span className="d-block">Location: {location || "None listed."}</span>
          <span className="d-block">Timezone: {timezone || "None listed."}</span>
          <span className="d-block">
            {" "}
            Birthdate: {birthdate ? moment(birthdate).format("DD/MM/YYYY") : "None listed."}{" "}
          </span>
          <span className="d-block">Pronoun: {gender || "None listed."}</span>
        </span>
      </div>
    </>
  );
}

export function ContactOptions({ communication = [] }) {
  const contactIcons = {
    Email: gmail,
    Other: otherContact,
    Signal: signal,
    Slack: slack,
    Telegram: telegram,
    WhatsApp: whatsapp,
  };

  const buildCommsArray = () => {
    return parseJSONArray(communication).filter((c) => {
      const isCustom = !fields.communication_visibility.choices[0].filter((v) => v._id === c.app)
        .length;
      return isCustom ? "Other(s)" : c.app;
    });
  };

  const contactDetails = buildCommsArray().reduce((prev, curr) => {
    const comms = curr.app;
    const handle = curr.handle;
    const icon = contactIcons[comms] || contactIcons["Other"];

    if (handle) {
      prev.push(
        <div key={comms}>
          <span className="d-flex align-items-center">
            <img className="mr-2" src={icon} alt="" />
            <span className="mt-1">
              {comms}: {handle}
            </span>
          </span>
        </div>
      );
    }

    return prev;
  }, []);

  return (
    <span className={`${styles.text} mt-3`}>
      {contactDetails.length ? (
        contactDetails
      ) : (
        <div>
          <span className="d-flex align-items-center">
            <span className="mt-1">None listed.</span>
          </span>
        </div>
      )}
    </span>
  );
}

export function ToolOptions({ tools = [], tools_others = [] }) {
  const toolIcons = {
    Asana: asana,
    Dropbox: dropbox,
    "Google Suite": gsuite,
    Monday: monday,
    "Microsoft 365": ms365,
    Notion: notion,
    Trello: trello,
    Zoom: zoom,
    Other: otherTool,
  };

  const buildToolArray = () => {
    const toolData = parseJSONArray(tools);
    const toolOthers = parseJSONArray(tools_others);
    const hasOtherTools = toolData.includes("Other(s)");

    return !hasOtherTools ? toolData : toolData.filter((t) => t !== "Other(s)").concat(toolOthers);
  };

  const toolDetails = buildToolArray().map((tool, i) => (
    <div key={i}>
      <span
        className={`d-flex flex-column align-items-center justify-content-center ${styles.tool}`}
      >
        <img src={toolIcons[tool] || toolIcons["Other"]} alt="" />
        <span className="mt-1 header">
          <span className="small">{tool}</span>
        </span>
      </span>
    </div>
  ));

  return (
    <span className={`d-flex flex-wrap ${styles.text} mt-2`}>
      {toolDetails.length ? (
        toolDetails
      ) : (
        <div>
          <span className="d-flex align-items-center">
            <span className="mt-1">None listed.</span>
          </span>
        </div>
      )}
    </span>
  );
}

export default Client;
