import AvoTrackingPlan from "./Avo";
import RudderstackDestination from "./destinationInterface";
import { FeatureFlagFactory, FeatureFlag } from "./split";
import * as Inspector from "avo-inspector";

/**
 * Initializes the Avo tracking library.
 */
const setupTracking = ({ env, options = {} }) => {
  // noop = dont send network requests
  const { noop = false } = options;
  const webDebugger = !!process.env.REACT_AVO_DEBUGGER;
  const avoOptions = {
    env,
    noop,
    webDebugger,
  };

  if (env === undefined) {
    console.warn("No env provided when initializing Avo. Defaulting to dev.");
    avoOptions.env = "dev";
  }

  // inspector initialized only when making network requests
  if (!noop) {
    avoOptions.inspector = new Inspector.AvoInspector({
      apiKey: "XuJBy56o9AY19RhRC0Rs",
      env,
      version: "1.0.0",
      appName: "Magic Tracking",
    });
  }
  AvoTrackingPlan.initAvo(avoOptions, {}, {}, RudderstackDestination);
};

/**
 * Intilializes Avo in TEST mode. Used for unit tests only.
 */
const testModeTracking = () => {
  setupTracking({ env: "dev", options: { noop: true } });
};

/**
 * Initializes tracking for each passed vendor.
 * @param {Object} user
 * @param {String} user.user_id
 * @param {Object} options
 * @param {Object} options.googleAnalytics.measurementId Identifier unique to this app
 */
const initialize = (user, options = {}) => {
  const { googleAnalytics, rudderStack = {} } = options;
  const user_id = user ? user.username : null;
  // Initialize Google analytics event tracking
  // NOTE: Requires code snippet on html of portal
  if (googleAnalytics != null) {
    const { measurementId } = googleAnalytics;
    if (measurementId) {
      if (window && window.gtag) {
        window.gtag("js", new Date());
        window.gtag("config", measurementId, { user_id });
      }
    } else {
      console.error("Google Analytics: Missing measurementId");
    }
  }
  // Rudderstack
  // The snippet in the html includes the variables which define the
  // instance for rudderstack. At this point we only need to identifiy the user.
  if (window && window.rudderanalytics) {
    /*
      Integrations or configuredDestinations tells rudderstack sdk to which
      destinations to send data. In non-prod environments, we avoid sending
      data to all places.
      ConfiguredDestinations can be modified to allow specific destinations
        for example:
          {
            All: false,
            Sentry: true
          }
        the above configuration would send data only to sentry, avoiding all other destinations
     Docs: https://www.rudderstack.com/docs/user-guides/how-to-guides/how-to-filter-selective-destinations/
    */
    const { configuredDestinations = { All: true } } = rudderStack;
    if (user) {
      window.rudderanalytics.identify(
        // uuid, utilizing cognito at the moment
        user_id,
        // user properties (ex email, name, etc)
        {
          email: user.email,
        },
        {
          integrations: configuredDestinations,
        }
      );
    }
    window.rudderanalytics.configuredDestinations = configuredDestinations;
  }
};

/**
 * DEPRECATED: This method of recording events is being phased out.
 * Please do not create new instances and instead utilize the Avo Functions.
 *
 * Event tracking for each supported vendor
 * @param {String} eventName required,
 * @param {Object} properties
 * @param {Object} options
 */
const recordEvent = (eventName, properties = {}, options = {}) => {
  try {
    if (!eventName || typeof eventName !== "string") {
      console.error("Missing or invalid event name", eventName);
      return;
    }
    // Google Analytics GTag Event
    if (window && window.gtag) {
      window.gtag("event", eventName);
    }
    // Rudderstack
    if (window && window.rudderanalytics) {
      window.rudderanalytics.track(eventName, properties, {
        integrations: window.rudderanalytics.configuredDestinations,
        ...options,
      });
    }
  } catch (error) {
    console.error(error);
  }
};

/**
 * DEPRECATED: This method of recording page views is being phased out.
 * Please do not create new instances and instead utilize the Avo Functions.
 */
const pageView = ({ name, category, properties, options }) => {
  // Rudderstack
  if (window && window.rudderanalytics) {
    window.rudderanalytics.page(category, name, properties, {
      integrations: window.rudderanalytics.configuredDestinations,
      ...options,
    });
  }
};

const Tracking = {
  setupTracking,
  testModeTracking,
  initialize,
  recordEvent,
  pageView,
  ...AvoTrackingPlan,
  FeatureFlag,
  FeatureFlagFactory,
};

export default Tracking;
