import axios from "axios";

import { Auth } from "aws-amplify";

const baseURL = process.env.REACT_APP_API_HOST;

const instance = axios.create({
  baseURL,
  timeout: 10000,
});

instance.interceptors.request.use(
  async function (config) {
    const {
      accessToken: { jwtToken },
    } = await Auth.currentSession();

    config.headers["Authorization"] = `Bearer ${jwtToken}`;
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export default instance;
