import React, { useState } from "react";
import "./common.scss";

import SignIn from "./SignIn.js";
import SignUp from "./SignUp.js";
import ConfirmSignUp from "./ConfirmSignUp.js";
import ForgotPassword from "./ForgotPassword.js";
import ForgotPasswordSubmit from "./ForgotPasswordSubmit.js";
import AuthCompleteRegistration from "../AuthCompleteRegistration/AuthCompleteRegistration";

export default function Authenticator({ testURL = null }) {
  const params = new URLSearchParams((testURL && testURL.search) || window.location.search);

  const initialState = { mode: "sign-in" };
  const extraProps = {};

  //?signup redirects to SignUp
  if (params.has("signup")) {
    initialState.mode = "sign-up";
  }

  //?code=x&data=b64json redirects to ConfirmSignUp
  if (params.has("code")) {
    try {
      const data = params.get("data");
      const code = params.get("code");

      if (!data || !code) throw new Error("MISSING_PARAMS");

      const { username } = JSON.parse(atob(data));
      if (!username) throw new Error("MALFORMED_DATA_PARAM");

      extraProps.username = username;
      extraProps.code = code;
      if (params.has("reset")) {
        initialState.mode = "forgot-password-submit";
      } else {
        initialState.mode = "confirm-sign-up";
      }
    } catch (error) {
      console.error(error);
    }
  }

  // registration?auth_cache_token=uuidSomething
  if (params.has("auth_cache_token")) {
    initialState.mode = "complete-registration";
  }

  const [{ mode, username }, setMode] = useState(initialState);

  if (mode === "forgot-password") {
    return <ForgotPassword {...{ setMode, username }} />;
  } else if (mode === "forgot-password-submit") {
    return <ForgotPasswordSubmit {...{ setMode, username, ...extraProps }} />;
  } else if (mode === "sign-up") {
    return <SignUp {...{ setMode, username }} />;
  } else if (mode === "confirm-sign-up") {
    return <ConfirmSignUp {...{ setMode, username, ...extraProps }} />;
  } else if (mode === "complete-registration") {
    const authCacheToken = params.get("auth_cache_token");
    return <AuthCompleteRegistration token={authCacheToken} setMode={setMode} />;
  }
  return <SignIn {...{ setMode, username }} />;
}
