import React from "react";
import { toast } from "react-toastify";

import { useForm } from "react-hook-form";

import styles from "./OverTimeRequestForm.module.scss";
import { Button, Container, TextField, TextArea } from "@magic-engineering/components";

import api from "../../libs/api";
import Tracking from "@magic-engineering/magic-tracking";

const OverTimeRequestForm = ({ onDismiss, mongo_client_id }) => {
  const { register, formState, handleSubmit, errors } = useForm();

  const onSubmit = async (data) => {
    try {
      const response = await api.post("/dtrs/ots", {
        hours: +data.hours,
        user_reason: data.user_reason,
        mongo_client_id,
      });

      Tracking.requestOvertime();
      onDismiss(response && response.data);
    } catch (e) {
      toast.error("An error occurred. Please try again.");
    }
  };

  return (
    <Container dataTestId="OverTimeRequestForm" className={`px-0 pt-0`} onDismiss={onDismiss}>
      <h3 className="pd-y-m">Request Extra Hours</h3>
      <form className={`${styles.Form} d-flex flex-column`} onSubmit={handleSubmit(onSubmit)}>
        <TextField
          defaultValue={1}
          label="How many hours?"
          labelColor="#8888B5"
          name="hours"
          type="number"
          dataTestId="inputHours"
          required
          errors={errors}
          ref={register({ required: true })}
        />
        <TextArea
          errors={errors}
          label="Reason"
          labelColor="#8888B5"
          minRows={3}
          name="user_reason"
          dataTestId="inputReason"
          placeholder={"Type a reason here"}
          maxLength={280}
          ref={register({ required: true, maxLength: 280 })}
        />

        <div className="d-flex mg-t-s">
          <Button
            label={"SUBMIT REQUEST"}
            className="mr-2"
            dataTestId="SubmitButton"
            disabled={formState.isSubmitting}
            isLoading={formState.isSubmitting}
            loadingText={"SUBMITTING REQUEST..."}
          />
        </div>
      </form>
    </Container>
  );
};

export default OverTimeRequestForm;
