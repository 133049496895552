import React, { lazy, Suspense } from "react";
import { Loading } from "@magic-engineering/components";

const LazyJobRequestsPage = lazy(() => import("./JobRequestsPage"));

const JobRequestsPage = (props) => (
  <Suspense fallback={<Loading />}>
    <LazyJobRequestsPage {...props} />
  </Suspense>
);

export default JobRequestsPage;
