const fields = {
  communication_visibility: {
    id: "communication_visibility",
    label: "Assistant should message me on",
    type: "checkbox",
    isRequired: false,
    choices: [
      [
        {
          _id: "Slack",
          name: "Slack",
          conditional: {
            label: "What's your Slack group URL?",
            placeholder: "https://yourcompany.slack.com",
          },
        },
        {
          _id: "Telegram",
          name: "Telegram",
          conditional: {
            label: "What's your Telegram number or username?",
            placeholder: "@myusername",
          },
        },
        {
          _id: "WhatsApp",
          name: "WhatsApp",
          conditional: {
            label: "What's your phone number for WhatsApp?",
            placeholder: "+15555555555",
          },
        },
        {
          _id: "Signal",
          name: "Signal",
          conditional: {
            label: "What's your phone number for Signal?",
            placeholder: "+1-123-456-7890",
          },
        },
        {
          _id: "Email",
          name: "Email",
          conditional: {
            label: "What's your email for communication?",
            placeholder: "me@mycompany.com",
          },
        },
        {
          _id: "communication_others",
          name: "Other(s)",
          conditional: {
            label: "Other app(s) to message me on",
            placeholder: "Other app's username/handle",
          },
          isOmitted: true,
        },
      ],
    ],
  },
};

export default fields;
