import React, { useEffect, useState } from "react";

import styles from "./EditHoursForm.module.scss";

import api from "../../libs/api";

import Tracking from "@magic-engineering/magic-tracking";
import HoursForm from "../HoursForm/HoursForm";

const EDIT_HOURS_RESTRICTION = 48;
export const isEditableDtr = (clockOutDate) =>
  (new Date() - new Date(clockOutDate)) / (1000 * 60 * 60) < EDIT_HOURS_RESTRICTION;

const EditHoursForm = ({
  client = {},
  clockInDate,
  clockOutDate,
  dtrId,
  notes,
  onDismiss,
  onRefresh,
}) => {
  const [alertProps, setAlertProps] = useState();
  const [canEdit, setCanEdit] = useState(true);

  async function updateDtr(payload) {
    setAlertProps(null);

    Tracking.updateClockOut();

    try {
      const { data } = await api.put(`/dtrs/${dtrId}`, {
        client_id: client.client_id,
        ...payload,
      });

      onRefresh(data);
      onDismiss();
    } catch (error) {
      setAlertProps({
        ...displayError(error.response.data),
        type: "Warning",
      });

      if (error.response.data === "DATE_CHANGES_NOT_ALLOWED_AFTER_48HRS") {
        setCanEdit(false);
      }
    }
  }
  useEffect(() => {
    if (clockOutDate && !isEditableDtr(clockOutDate)) {
      setAlertProps({
        ...displayError("DATE_CHANGES_NOT_ALLOWED_AFTER_48HRS"),
      });
      setCanEdit(false);
    }
  }, [clockOutDate]);

  return (
    <div className={styles.EditHoursForm} data-testid="EditHoursForm">
      <HoursForm
        alertProps={alertProps}
        canEdit={canEdit}
        client={client}
        clockInDate={clockInDate}
        clockOutDate={clockOutDate}
        customActionLink={
          !canEdit ? "mailto:hey@getmagicnow.com?subject=Dedicated%20Assistant%20Support" : null
        }
        customActionText="Contact Magic"
        notes={notes}
        onDismiss={onDismiss}
        onSubmit={(payload) => updateDtr(payload)}
        setCanEdit={setCanEdit}
        submitText="Save"
        submittingText="Saving..."
        title="Edit Hours"
      />
    </div>
  );
};

const displayError = (error) => {
  switch (error) {
    case "INVALID_DTR_ID":
      return {
        title: "Daily time report does not exist.",
        text: "You are editing a non-existing daily time report. Please select an existing daily time report to update.",
      };
    case "INVALID_DATE":
      return {
        title: "You have entered an invalid date.",
        text: "You have an entered an invalid date for your clock-in and/or clock-out dates. Please check and try again.",
      };
    case "DATES_OVERLAPS_ANOTHER_DTR":
      return {
        title: "You have entered dates that overlap with other reports.",
        text: "Make sure you are entering clock-in and/or clock-out dates that do not overlap with your other reports.",
      };
    case "CLOCK_OUT_DATE_MUST_BE_AFTER_CLOCK_IN_DATE":
      return {
        title: "Your clock-out date must be after your clock-in date.",
        text: "Make sure your clock-out date is after your clock-in date.",
      };
    case "DATE_CHANGES_NOT_ALLOWED_AFTER_48HRS":
      return {
        title: "Editing has expired.",
        text: "You cannot make edits 48 hours after you submit your hours.",
      };
    case "CLOCKED_IN":
      return {
        title: "Editing not allowed while clocked in.",
        text: "Please clock-out first before editing a Daily Time Record.",
      };
    default:
      return {
        title: "An unknown error occurred.",
        text: "Please try again.",
      };
  }
};

EditHoursForm.propTypes = {};

EditHoursForm.defaultProps = {};

export default EditHoursForm;
