import { createReduxModule } from "hooks-for-redux";
import _ from "lodash";

const defaultProfileState = {};

export const [useProfile, { setProfile }] = createReduxModule("profile", defaultProfileState, {
  setProfile: (state, profile) => {
    return {
      ...state,
      ...profile,
      isComplete: isProfileComplete(profile),
    };
  },
});

export const isProfileComplete = (profile) => {
  try {
    const truthy =
      profile.photo_url &&
      (_.get(profile, "skills.length") > 0 || _.get(profile, "tools.length") > 0) &&
      profile.first_name &&
      profile.last_name &&
      profile.hours_per_week &&
      profile.resume_url;

    return !!truthy;
  } catch (error) {
    return false;
  }
};

export const updateAvailabilityForHire = async ({ profile, available, updateAPI }) => {
  const initial = profile.availableForHire;
  try {
    setProfile({ availableForHire: available });
    await updateAPI(available);
  } catch (err) {
    setProfile({ availableForHire: initial });
    throw err;
  }
};
