import React, { useState, useRef } from "react";
import { Auth } from "aws-amplify";
import { toast } from "react-toastify";
import { Button, DropdownField } from "@magic-engineering/components";

import "./common.scss";
import PeekablePasswordInput from "./PeekablePasswordInput.js";
import { sanitizeName, escapeInput } from "../../libs/helpers";

const signUpAmplify = async ({
  username,
  password,
  firstName,
  lastName,
  phoneNumber,
  referralName,
  referralEmail,
  hearMagic,
}) => {
  try {
    await Auth.signUp({
      username,
      password,
      attributes: {
        "custom:first_name": sanitizeName(firstName),
        "custom:last_name": sanitizeName(lastName),
        phone_number: phoneNumber,
        "custom:referral_name": escapeInput(referralName),
        "custom:referral_email": escapeInput(referralEmail),
        "custom:hear_magic": escapeInput(hearMagic),
      },
    });
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export default function SignUp({ setMode, username: initialUsername = "" }) {
  const [username, setUsername] = useState(initialUsername.trim().toLowerCase());
  const [password, setPassword] = useState("");

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const phoneNumberRef = useRef(null);
  const [referralName, setReferralName] = useState("");
  const [referralEmail, setReferralEmail] = useState("");
  const [hearMagic, setHearMagic] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const [verificationSent, setVerificationSent] = useState(false);

  const handleUsernameChange = (event) => {
    setUsername(event.target.value.trim().toLowerCase());
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleFirstNameChange = (event) => {
    setFirstName(event.target.value);
  };

  const handleLastNameChange = (event) => {
    setLastName(event.target.value);
  };

  const handlePhoneNumberChange = (event) => {
    phoneNumberRef.current.setCustomValidity("");
    setPhoneNumber(event.target.value.trim());
  };

  const handleReferralNameChange = (event) => {
    setReferralName(event.target.value);
  };

  const handleReferralEmailChange = (event) => {
    setReferralEmail(event.target.value.trim().toLowerCase());
  };

  const handleHearMagicChange = (event) => {
    setHearMagic(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsLoading(true);
    try {
      const formattedPhoneNumber = `+${phoneNumber.replace(/\D+/g, "")}`;
      if (!formattedPhoneNumber || formattedPhoneNumber === "+") {
        phoneNumberRef.current.setCustomValidity("Invalid phone number!");
        throw new Error("Invalid phone number!");
      }
      setPhoneNumber(formattedPhoneNumber);
      phoneNumberRef.current.value = formattedPhoneNumber;

      await signUpAmplify({
        username,
        password,
        firstName,
        lastName,
        phoneNumber: formattedPhoneNumber,
        referralName,
        referralEmail,
        hearMagic,
      });
      setVerificationSent(true);
      toast.success("Sign up successful!");
    } catch (error) {
      toast.error(error.message);
    }
    setIsLoading(false);
  };

  return (
    <div className="container" data-testid="sign-up">
      <div className="row">
        <div className="col"></div>
        {verificationSent ? (
          <div className="col sign-in-container">
            <div className="pb-2 header">Verification email sent!</div>
            <div className="pt-3 pb-3 new-user">
              Please check your email inbox and follow the instructions. Email sent to {username}
            </div>
            <div className="pt-3">
              <Button
                onClick={() => setMode({ mode: "sign-in", username })}
                className="float-right"
                label="CONTINUE"
              />
            </div>
          </div>
        ) : (
          <form className="col sign-in-container" onSubmit={handleSubmit}>
            <div className="pb-2 header">Sign up</div>
            <div className="pt-3 pb-3 new-user">
              Already a user?
              <span className="ml-3" onClick={() => setMode({ mode: "sign-in", username })}>
                Login now
              </span>
            </div>
            <div className="col pt-2 pb-3 input-field">
              <label className="row">
                <span className="mt-1 mb-1">First name</span>
                <input
                  type="text"
                  className="form-control mt-2"
                  placeholder="Jane"
                  onChange={handleFirstNameChange}
                  required
                  data-testid="first-name"
                />
              </label>
            </div>
            <div className="col pt-2 pb-3 input-field">
              <label className="row">
                <span className="mt-1 mb-1">Last name</span>
                <input
                  type="text"
                  className="form-control mt-2"
                  placeholder="Doe"
                  onChange={handleLastNameChange}
                  required
                  data-testid="last-name"
                />
              </label>
            </div>
            <div className="col pt-2 pb-3 input-field">
              <label className="row">
                <span className="mt-1 mb-1">Email</span>
                <input
                  type="email"
                  className="form-control mt-2"
                  placeholder="jane@mycompany.com"
                  onChange={handleUsernameChange}
                  defaultValue={username}
                  required
                  data-testid="email"
                />
              </label>
            </div>
            <div className="col pt-2 pb-3 input-field">
              <label className="row">
                <span className="mt-1 mb-1">Password</span>
                <PeekablePasswordInput
                  className="form-control mt-2"
                  placeholder="**********"
                  onChange={handlePasswordChange}
                  required
                  data-testid="password"
                />
              </label>
            </div>
            <div className="col pt-2 pb-3 input-field">
              <label className="row">
                <span className="mt-1 mb-1">Phone number</span>
                <input
                  ref={phoneNumberRef}
                  type="tel"
                  className="form-control mt-2"
                  placeholder="+1 (123) 456-7890"
                  onChange={handlePhoneNumberChange}
                  required
                  data-testid="phone-number"
                />
              </label>
            </div>
            <div className="col pt-2 pb-3 input-field">
              <label className="row">
                <span className="mt-1 mb-1">Referral name</span>
                <input
                  type="text"
                  className="form-control mt-2"
                  placeholder="John Doe"
                  onChange={handleReferralNameChange}
                  data-testid="referral-name"
                />
              </label>
            </div>
            <div className="col pt-2 pb-3 input-field">
              <label className="row">
                <span className="mt-1 mb-1">Referral email</span>
                <input
                  type="email"
                  className="form-control mt-2"
                  placeholder="johndoe@yahoo.com"
                  onChange={handleReferralEmailChange}
                  data-testid="referral-email"
                />
              </label>
            </div>
            <div className="col pt-2 pb-3 input-field">
              <label className="row">
                <span className="mt-1 mb-1">How did you hear about Magic?</span>
                <DropdownField
                  choices={[
                    "Not applicable",
                    "Referral",
                    "Facebook advertisement",
                    "Facebook group post",
                    "Email",
                    "Indeed",
                    "Instagram advertisement",
                    "JobStreet",
                    "Jora",
                    "Kalibrr",
                    "LinkedIn",
                    "Previous Magic employee",
                    "Twitter",
                    "Other",
                  ]}
                  className="w-100 mt-2"
                  onChange={handleHearMagicChange}
                  data-testid="hear-magic"
                />
              </label>
            </div>
            <div className="pt-3">
              <Button
                dataTestId="submit-button"
                isLoading={isLoading}
                disabled={isLoading}
                loadingText=""
                className="float-right"
                label="CREATE ACCOUNT"
              />
            </div>
          </form>
        )}
        <div className="col"></div>
      </div>
    </div>
  );
}
