import React, { lazy, Suspense } from "react";

const LazyIntroPage = lazy(() => import("./IntroPage"));

const IntroPage = (props) => (
  <Suspense fallback={null}>
    <LazyIntroPage {...props} />
  </Suspense>
);

export default IntroPage;
