import React, { useState } from "react";
import { Auth } from "aws-amplify";
import { toast } from "react-toastify";
import { Button, Loading } from "@magic-engineering/components";

import "./common.scss";
import PeekablePasswordInput from "./PeekablePasswordInput.js";

const forgotPasswordSubmitAmplify = async (username, code, password) => {
  try {
    await Auth.forgotPasswordSubmit(username, code, password);
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export default function ForgotPasswordSubmit({ setMode, username, code }) {
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsLoading(true);
    try {
      await forgotPasswordSubmitAmplify(username, code, password);
      setMode({ mode: "sign-in", username });
      toast.success("Password reset successful!");
    } catch (error) {
      toast.error(error.message);
      setIsLoading(false);
    }
  };

  return (
    <div className="container" data-testid="forgot-password-submit">
      <div className="row">
        <div className="col"></div>
        <form className="col sign-in-container" onSubmit={handleSubmit}>
          <div className="pb-2 header">Reset your password</div>
          <div className="col pt-2 pb-3 input-field">
            <label className="row">
              <span className="mt-1 mb-1">Password</span>
              <PeekablePasswordInput
                className="form-control mt-2"
                placeholder="**********"
                onChange={handlePasswordChange}
                required
                data-testid="password"
              />
            </label>
          </div>
          <div className="pt-3">
            {isLoading ? <Loading /> : <Button className="float-right mb-3" label="SUBMIT" />}
          </div>
          <div className="row col pt-3 pb-3 pr-0 m-0 justify-content-end back-to-login">
            Back to
            <span className="ml-1" onClick={() => setMode({ mode: "sign-in", username })}>
              Login
            </span>
          </div>
        </form>
        <div className="col"></div>
      </div>
    </div>
  );
}
