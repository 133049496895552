import React from "react";
import { HashLink } from "react-router-hash-link";
import { toast } from "react-toastify";
import { Auth } from "aws-amplify";
import cn from "classnames";
import PropTypes from "prop-types";
import * as Sentry from "@sentry/browser";

import styles from "./MenuItem.module.scss";

import { ReactComponent as EarningsIcon } from "../../assets/images/navbar/earnings.svg";
import { ReactComponent as FaqIcon } from "../../assets/images/navbar/faq.svg";
import { ReactComponent as FeedbackIcon } from "../../assets/images/navbar/feedback.svg";
import { ReactComponent as LogoutIcon } from "../../assets/images/navbar/logout.svg";
import { ReactComponent as ProfileIcon } from "../../assets/images/navbar/profile.svg";
import { ReactComponent as ReportIcon } from "../../assets/images/navbar/report.svg";
import { ReactComponent as SettingsIcon } from "../../assets/images/navbar/settings.svg";
import { ReactComponent as TimeIcon } from "../../assets/images/navbar/time.svg";
import { ReactComponent as OnboardingZeroIcon } from "../../assets/images/onboarding/onboarding-step-1.svg";
import { ReactComponent as OnboardingFirstIcon } from "../../assets/images/onboarding/onboarding-step-2.svg";
import { ReactComponent as OnboardingSecondIcon } from "../../assets/images/onboarding/onboarding-step-3.svg";

import { stages } from "../../redux/configuration";

export const signOut = async (e) => {
  e.preventDefault();
  try {
    await Auth.signOut();
  } catch (error) {
    Sentry.withScope((scope) => {
      scope.setLevel("warning");
      Sentry.captureMessage(error);
    });
    toast.error("An error occurred. Please try again.");
    console.error(error.message);
  }
};

export const HelpDeskLink = ({ classes = {} }) => (
  <a
    href="https://getmagic.com/help/assistants"
    target="_blank"
    rel="noreferrer"
    className={cn(styles.MenuItem, classes.container)}
  >
    <li className={cn(styles.item, classes.item)}>
      <FaqIcon className={cn(styles.stroke, classes.stroke, classes.icon)} />
      <span className={cn(styles.text, classes.text)}>Help Desk</span>
    </li>
  </a>
);

export const LogoutLink = ({ classes = {} }) => (
  <a href="/" onClick={signOut} className={cn(styles.MenuItem, classes.container)}>
    <li className={cn(styles.item, classes.item)}>
      <LogoutIcon className={cn(styles.stroke, classes.stroke, classes.icon)} />
      <span className={cn(styles.text, classes.text)}>Logout</span>
    </li>
  </a>
);

export const isActive = (pathName, currentPathName, currentHash = "") =>
  `${currentPathName}${currentHash}` === pathName;
export const isActiveHash = (pathName, hashName, currentPathName, currentHash = "") =>
  currentPathName === pathName && (currentHash === hashName || currentHash === "");

const ICON_TYPE = {
  fill: "fill",
  stroke: "stroke",
};

const COMMON_LINKS = {
  HELP_DESK: {
    renderComponent: (props) => <HelpDeskLink {...props} />,
    iconType: ICON_TYPE.stroke,
  },
  SETTINGS: {
    path: "/settings",
    text: "Settings",
    icon: (props) => <SettingsIcon {...props} />,
    iconType: ICON_TYPE.stroke,
  },
  LOGOUT: {
    renderComponent: (props) => <LogoutLink {...props} />,
    iconType: ICON_TYPE.stroke,
  },
};

export const ONBOARDING_LINKS = {
  ONBOARDING_INTRO: {
    path: "/intro",
    text: "Get Started",
    icon: (props) => <OnboardingZeroIcon {...props} />,
  },
  ONBOARDING_QUIZ: {
    path: "/onboarding",
    text: "1. Test Your Knowledge",
    icon: (props) => <OnboardingFirstIcon {...props} />,
  },
  ONBOARDING_PROFILE: {
    path: "/onboarding/profile",
    text: "2. Set Up Your Profile",
    icon: (props) => <OnboardingSecondIcon {...props} />,
    isDisabledFn: (stage) => stage === stages.ONBOARDING,
  },
  SEPARATOR: {
    renderComponent: () => <hr />,
  },
  ...COMMON_LINKS,
};

export const DEFAULT_LINKS = {
  TIMETRACKER: {
    path: "/#timetracker",
    text: "Time Tracker",
    icon: (props) => <TimeIcon {...props} />,
    isActiveFn: (_path, currentPathName, currentHash) =>
      isActive("/#timetracker", currentPathName, currentHash) ||
      isActiveHash("/", "#timetracker", currentPathName, currentHash),
    dataTestid: "TimeTrackerButton",
  },
  NEWS: {
    path: "/#news",
    text: "News",
    icon: (props) => <ReportIcon {...props} />,
  },
  FEEDBACKS: {
    path: "/feedbacks",
    text: "Feedback History",
    icon: (props) => <FeedbackIcon {...props} />,
  },
  EARNINGS: {
    path: "/payouts",
    text: "Earnings",
    icon: (props) => <EarningsIcon {...props} />,
  },
  HELP_DESK: COMMON_LINKS.HELP_DESK,
  PROFILE: {
    path: "/profile",
    text: "Profile",
    icon: (props) => <ProfileIcon {...props} />,
  },
  SETTINGS: COMMON_LINKS.SETTINGS,
  LOGOUT: COMMON_LINKS.LOGOUT,
};

const MenuItem = (props) => {
  const {
    text,
    icon,
    path,
    renderComponent,
    isDisabledFn,
    isActiveFn,
    dataTestid,
    stage,
    activePath,
    activeHash,
    iconType,
    classes,
  } = props;

  if (renderComponent) {
    return renderComponent(props);
  }

  const disabled = isDisabledFn(stage);
  const active = isActiveFn(path, activePath, activeHash);

  return (
    <HashLink
      to={path}
      className={cn(styles.MenuItem, classes.container, {
        [styles.disabled]: disabled,
        [classes.disabled]: disabled,
      })}
    >
      <li
        data-testid={dataTestid}
        className={cn(styles.item, classes.item, {
          [styles.active]: active,
          [classes.active]: active,
        })}
      >
        {icon &&
          icon({
            className: cn(styles[iconType], classes[iconType], classes.icon),
          })}
        <span className={cn(styles.text, classes.text)}>{text}</span>
      </li>
    </HashLink>
  );
};

MenuItem.propTypes = {
  text: PropTypes.any,
  icon: PropTypes.func,
  iconType: PropTypes.oneOf([ICON_TYPE.fill, ICON_TYPE.stroke]),
  path: PropTypes.string,
  isDisabledFn: PropTypes.func,
  isActiveFn: PropTypes.func,
  renderComponent: PropTypes.func,
  dataTestId: PropTypes.string,
  stage: PropTypes.string,
  activePath: PropTypes.string,
  activeHash: PropTypes.string,
  classes: PropTypes.any,
};
MenuItem.defaultProps = {
  isActiveFn: isActive,
  isDisabledFn: () => false,
  iconType: ICON_TYPE.fill,
  classes: {},
};

export default MenuItem;
