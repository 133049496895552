import React, { useState, useEffect } from "react";
import "./common.scss";
import { Button, Loading } from "@magic-engineering/components";

import { Auth } from "aws-amplify";

const confirmSignUpAmplify = async (username, code) => {
  try {
    await Auth.confirmSignUp(username, code);
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export default function ConfirmSignUp({ setMode, username, code }) {
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [verificationError, setVerificationError] = useState(null);

  useEffect(() => {
    const processVerification = async () => {
      setIsLoading(true);
      setVerificationError(null);

      try {
        await confirmSignUpAmplify(username, code);
        setIsSuccess(true);
      } catch (error) {
        if (error.message === "User cannot be confirmed. Current status is CONFIRMED") {
          setVerificationError("Account is already confirmed");
        }
        setIsSuccess(false);
      }

      setIsLoading(false);
    };

    processVerification();
  }, [username, code]);

  const handleContinue = () => {
    window.history.replaceState({}, document.title, "/"); //clear params
    setMode({ mode: "sign-in", username: "" });
  };

  const isUnsuccessful = !isLoading && !isSuccess;

  return (
    <div className="container" data-testid="confirm-sign-up">
      <div className="row">
        <div className="col" />
        <div className="col sign-in-container">
          <div className="pb-2 header">Verification</div>
          <div className={`mt-3 mb-3 pt-3 pb-3 verification-status ${isUnsuccessful && "error"}`}>
            <div className="p-1 title">
              {isSuccess ? "Account verified" : isLoading ? "Verifying..." : "Failed to verify"}
            </div>
            {verificationError && <div className="p-1 description">{verificationError}</div>}
          </div>
          <div className="pt-3">
            {isLoading ? (
              <Loading />
            ) : (
              <Button className="float-right mb-3" label="CONTINUE" onClick={handleContinue} />
            )}
          </div>
        </div>
        <div className="col" />
      </div>
    </div>
  );
}
