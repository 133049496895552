import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Chart as ChartJS, ArcElement } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import api from "../../libs/api";

import styles from "./AssistantLevelBadge.module.scss";

import AssistantLevelModal from "../AssistantLevelModal/AssistantLevelModal";

const AssistantLevelBadge = ({ className, profile }) => {
  const [assistantLevel, setAssistantLevel] = useState();
  const [levels, setLevels] = useState();
  const [pointEvents, setPointEvents] = useState();
  const [pointEventDefinitions, setPointEventDefinitions] = useState();
  const [showModal, setShowModal] = useState(false);
  const { unique_id } = profile || {};
  const { level_id, points, quarter } = assistantLevel || {};

  ChartJS.register(ArcElement);

  useEffect(() => {
    async function getAssistantLevel() {
      try {
        const { data } = await api.get(`/assistants/level/${unique_id}`);
        setAssistantLevel(data || {});
      } catch (e) {
        toast.error("Failed to fetch assistant level.");
        console.error(e.message);
      }
    }

    if (!assistantLevel && unique_id && pointEvents) {
      getAssistantLevel();
    }
  }, [pointEvents, assistantLevel, unique_id]);

  useEffect(() => {
    async function getLevels() {
      try {
        const { data } = await api.get("/assistants/level/definitions");
        setLevels(data || {});
      } catch (e) {
        toast.error("Failed to fetch levels.");
        console.error(e.message);
      }
    }

    if (!levels) {
      getLevels();
    }
  }, [levels]);

  useEffect(() => {
    async function getPointEventDefinitions() {
      try {
        const { data } = await api.get("/assistants/points/definitions");
        setPointEventDefinitions(data || []);
      } catch (e) {
        toast.error("Failed to fetch point event definitions.");
        console.error(e.message);
      }
    }

    if (levels && !pointEventDefinitions) {
      getPointEventDefinitions();
    }
  }, [pointEventDefinitions, levels]);

  useEffect(() => {
    async function getPointEvents() {
      try {
        const { data } = await api.get(`/assistants/points/${unique_id}`);
        setPointEvents(data || {});
      } catch (e) {
        toast.error("Failed to fetch point events.");
        console.error(e.message);
      }
    }

    if (!pointEvents && pointEventDefinitions && unique_id) {
      getPointEvents();
    }
  }, [pointEvents, pointEventDefinitions, unique_id]);

  const level = levels && levels[level_id];
  const nextLevel = levels && levels[level_id + 1];
  const pointEventDefs =
    pointEventDefinitions &&
    pointEventDefinitions.reduce((obj, ped) => {
      obj[ped.type] = ped.label;
      return obj;
    }, {});

  const data = level &&
    !isNaN(parseFloat(points)) && {
      datasets: [
        {
          data: [parseFloat(points) - level.min_points, level.max_points],
          backgroundColor: ["#FFFFFF", "rgba(255, 255, 255, 0.25)"],
          borderWidth: 0,
          cutout: "60%",
          rotation: -180,
        },
      ],
    };

  if (level && levels && !isNaN(parseFloat(points)) && pointEvents && pointEventDefs) {
    return (
      <div
        className={`${className || ""} ${styles.AssistantLevelBadge}`}
        data-testid="AssistantLevelBadge"
      >
        <div
          className={`d-flex ${styles.Badge} justify-content-center align-items-center`}
          data-testid="AssistantLevelBadgeButton"
          style={{ backgroundColor: level.color || "transparent" }}
          onClick={() => setShowModal(true)}
          role="button"
        >
          <div className={styles.Circle}>
            <Doughnut data={data} />
          </div>
          <span data-testid="AssistantLevelBadgeText">
            {level.label} {(parseFloat(points) || 0).toFixed(2)}
          </span>
        </div>
        <AssistantLevelModal
          levels={levels || {}}
          level={level}
          nextLevel={nextLevel}
          points={parseFloat(points)}
          pointEvents={pointEvents || {}}
          pointEventDefinitions={pointEventDefs || {}}
          quarter={quarter}
          isOpen={showModal}
          onDismiss={() => setShowModal(false)}
        />
      </div>
    );
  }

  return null;
};

export default AssistantLevelBadge;
