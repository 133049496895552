import React, { lazy, Suspense } from "react";
import { Loading } from "@magic-engineering/components";

const LazyPayoutsPage = lazy(() => import("./PayoutsPage"));

const PayoutsPage = () => (
  <Suspense fallback={<Loading />}>
    <LazyPayoutsPage />
  </Suspense>
);

export default PayoutsPage;
