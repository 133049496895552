import React from "react";
import styles from "./ContractSignNotification.module.scss";
import { Button, DismissibleAlert } from "@magic-engineering/components";
import { ReactComponent as WarningIcon } from "../../assets/images/warning.svg";

const AlertLink = ({ text, link }) => (
  <a className={styles.AlertLink} href={link} target="_blank" rel="noreferrer">
    <u>{text}</u>
  </a>
);

const ContractSignNotification = ({ email, contractLink }) => {
  return (
    <div className={styles.ContractSignNotification}>
      <DismissibleAlert
        type="warning"
        icon={<WarningIcon />}
        className="mg-b-l"
        iconClassName="mb-1 mr-2"
        title="Your contract is now available for signing"
        dataTestId="ContractSigningInfoBox"
        text={
          <div className="text-left mg-l-m">
            <p className="mg-b-l">
              You need to create an account using {email} in Deel to view and sign your contract.
              Once signed, you will be eligible to receive payments from Magic.
            </p>
            <Button
              className={styles.AlertButton}
              dataTestId="SignContract"
              onClick={() => {
                window.open(contractLink, "_blank");
              }}
              label="Sign Contract"
            />
            <p className="mg-t-l">
              Need Help? Visit the{" "}
              <AlertLink text="Help Desk" link="https://getmagic.com/help/assistants/paid/" /> or{" "}
              <AlertLink text="Contact Magic" link="mailto:questions@getmagic.com" />.
            </p>
          </div>
        }
      />
    </div>
  );
};

export default ContractSignNotification;
