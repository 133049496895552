import { SplitTreatments, withSplitFactory } from "@splitsoftware/splitio-react";

export const buildSdkConfig = () => {
  /**
   * Initializes the Split.io SDK library.
   */
  const sdkConfig = {
    core: {
      authorizationKey: process.env.REACT_APP_SPLIT_IO_AUTH_KEY,
      key: "key",
    },
  };

  /**
   * If we are testing locally, we can override which features to be turned on/off
   * via the environment variables.
   */
  if (process.env.REACT_APP_SPLIT_IO_AUTH_KEY === "localhost") {
    sdkConfig["features"] = JSON.parse(process.env.REACT_APP_SPLIT_IO_FEATURES);
  }

  return sdkConfig;
};

/**
 * Initializes the Split.io SDK in a React app.
 * @param {Object} app
 *
 * Sample code (in App.js):
 *   export default Tracking.FeatureFlagFactory(App);
 */
export const FeatureFlagFactory = (app) => withSplitFactory(buildSdkConfig())(app);

/**
 * Allows any React component to be turned on or off via the Split.io SDK.
 * Wrap any React component with this component, and pass the featureName and
 * attributes props. The featureName is the feature you want to toggle on/off
 * for this component and the attributes are the parameters you could use to
 * toggle the feature on/off via the Split.io interface.
 * @param {Object} attributes
 * @param {Object} children The React child component
 * @param {String} featureName
 *
 * Sample code:
 *   <Tracking.FeatureFlag
 *     featureName="feature_name"
 *     attributes={{ email: profile.email }}>
 *     <Component />
 *   </Tracking.FeatureFlag>
 */
export const FeatureFlag = (props) => {
  const { attributes, children, featureName } = props;

  // Handles what we should display when the feature is toggled on/off.
  // Treatment values are either "on" or "off" by default.
  const renderContent = (treatmentWithConfig) => {
    const { treatment } = treatmentWithConfig;

    if (treatment === "on") {
      return children;
    }

    return null;
  };

  // Here, you can determine what the component should display while we are
  // fetching the feature data from Split.io via the isReady conditional logic.
  return (
    <SplitTreatments names={[featureName]} attributes={attributes}>
      {({ treatments, isReady }) => (isReady ? renderContent(treatments[featureName]) : null)}
    </SplitTreatments>
  );
};
