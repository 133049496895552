import React from "react";
import { Link, useLocation } from "react-router-dom";

import { UserAvatar } from "@magic-engineering/components";
import Tracking from "@magic-engineering/magic-tracking";

import AssistantLevelBadge from "../../components/AssistantLevelBadge/AssistantLevelBadge";

import styles from "./Navbar.module.scss";
import MenuItem, { ONBOARDING_LINKS, DEFAULT_LINKS } from "../Sidebar/MenuItem";
import logoBubble from "../../assets/images/logo-bubble.svg";
import { stages } from "../../redux/configuration";
import useViewport from "../../hooks/useViewPort";

const DropdownMenu = ({ profile, stage }) => {
  const { pathname, hash } = useLocation();
  const isOnboardingDone = ![stages.ONBOARDING, stages.REQUIRE_PROFILE].includes(stage);
  const menuItems = isOnboardingDone ? DEFAULT_LINKS : ONBOARDING_LINKS;
  const otherProps = {
    activePath: pathname,
    activeHash: hash,
    stage,
    classes: {
      item: `dropdown-item d-flex align-items-center ${styles.dropdownItem}`,
      text: styles.dropdownText,
      icon: `mr-3`,
      fill: styles.fill,
      stroke: styles.stroke,
    },
  };

  return (
    <div className="collapse navbar-collapse" id="navbarSupportedContent">
      <div className="ml-auto">
        <ul
          className="navbar-nav ml-auto"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
        >
          <Tracking.FeatureFlag
            featureName="assistant_levels_feature"
            attributes={{
              email: profile.email,
              assistant_id: profile.assistant_id,
              created_at: new Date(profile.created_at).getTime(),
            }}
          >
            <AssistantLevelBadge className={styles.assistantBadge} profile={profile} />
          </Tracking.FeatureFlag>
          {Object.keys(menuItems).map((key, idx) => (
            <MenuItem key={idx} {...menuItems[key]} {...otherProps} />
          ))}
        </ul>
      </div>
    </div>
  );
};

export const DROPDOWN_MENU_BREAKPOINT = 767;

const Navbar = ({ profile = {}, hasUser, stage }) => {
  const { width } = useViewport();

  return (
    <nav className={`navbar navbar-expand-lg navbar-light ${styles.Navbar}`} data-testid="Navbar">
      <div className={`d-flex align-items-center ${styles.navbarBrandContainer}`}>
        <Link className={`navbar-brand d-flex align-items-center ${styles.navbarBrand}`} to="/">
          <img className={styles.logo} src={logoBubble} alt="" />
          <div className={styles.navbarText}>
            <span className={styles.main}>Magic</span>&nbsp;
            <span className={styles.sub}>Assistant</span>
          </div>
        </Link>
      </div>
      {hasUser && <div className={styles.divider} />}
      <div className={styles.navbarTextTablet}>
        <span className={styles.main}>Magic</span>&nbsp;
        <span className={styles.sub}>Assistant</span>
      </div>

      {hasUser && (
        <>
          {/* Use conditional rendering instead of CSS display: none to avoid multiple API calls when rendering multiple Assistant Badge*/}
          {width <= DROPDOWN_MENU_BREAKPOINT ? (
            <>
              <div className={`d-flex justify-content-end ${styles.navbarRightComponent}`}>
                <button
                  data-testid="NavBarToggle"
                  className={`navbar-toggler ${styles.navbarToggle}`}
                  type="button"
                  data-toggle="collapse"
                  data-target="#navbarSupportedContent"
                  aria-controls="navbarSupportedContent"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <UserAvatar image={(profile || {}).photo_url} useAvatarIcon={true} />
                </button>
              </div>
              <DropdownMenu {...{ stage, profile }} />
            </>
          ) : (
            <div className={`d-flex justify-content-end ${styles.navbarRightComponent}`}>
              <Tracking.FeatureFlag
                featureName="assistant_levels_feature"
                attributes={{
                  email: profile.email,
                  assistant_id: profile.assistant_id,
                  created_at: new Date(profile.created_at).getTime(),
                }}
              >
                <AssistantLevelBadge className="mr-3" profile={profile || {}} />
              </Tracking.FeatureFlag>
              <Link to="/profile">
                <UserAvatar image={(profile || {}).photo_url} useAvatarIcon={true} />
              </Link>
            </div>
          )}
        </>
      )}
    </nav>
  );
};

Navbar.propTypes = {};
Navbar.defaultProps = {};

export default Navbar;
