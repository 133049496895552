import React from "react";
import styles from "./ResourceCard.module.scss";
import Tracking from "@magic-engineering/magic-tracking";

export const getCoverStyle = (url) => {
  return {
    background: `url(${url})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  };
};

const ResourceCard = ({ resource }) => (
  <div className={styles.ResourceCard} data-testid="ResourceCard">
    <a
      href={resource.link}
      target="_blank"
      rel="noreferrer"
      title={resource.short_description}
      onClick={() => Tracking.openHelpCenterLink()}
      data-testid="Link"
    >
      <div>
        <figure style={getCoverStyle(resource.cover_image)}></figure>
        <div className={styles.Content}>
          <h3 className="text-truncate">{resource.title}</h3>
          <span className="d-block color-secondary text-truncate">
            {resource.short_description}
          </span>
        </div>
      </div>
    </a>
  </div>
);

export const ResourceCompactCard = ({ resource }) => (
  <div className={styles.ResourceCompactCard} data-testid="ResourceCompactCard">
    <a
      href={resource.link}
      target="_blank"
      rel="noreferrer"
      title={resource.short_description}
      onClick={() => Tracking.openHelpCenterLink()}
      data-testid="Link"
    >
      <div>
        <figure style={getCoverStyle(resource.cover_image)}></figure>
        <div
          className={`d-flex align-items-center justify-content-center ${styles.CompactContent}`}
        >
          <header>{resource.title}</header>
        </div>
      </div>
    </a>
  </div>
);

export default ResourceCard;
