import React, { useEffect, useState } from "react";
import moment from "moment";
// import PropTypes from 'prop-types';
import styles from "./SubmitHoursForm.module.scss";

import HoursForm from "../HoursForm/HoursForm";

const SubmitHoursForm = ({
  client = {},
  clockInDate,
  clockOutDate,
  isSubmitting,
  onDismiss,
  onSubmit,
}) => {
  const [alertProps, setAlertProps] = useState();

  useEffect(() => {
    if (clockInDate && clockOutDate) {
      setAlertProps({
        title: `You clocked ${calculateClockedHours(clockInDate, clockOutDate)}!`,
        text: "Please summarize what you did from the options below and confirm.",
      });
    }
  }, [clockInDate, clockOutDate]);

  return (
    <div className={styles.SubmitHoursForm} data-testid="SubmitHoursForm">
      <HoursForm
        alertProps={alertProps}
        client={client}
        clockInDate={clockInDate}
        clockOutDate={clockOutDate}
        isSubmitting={isSubmitting}
        onDismiss={onDismiss}
        onSubmit={onSubmit}
        submitText="Submit"
        submittingText="Submitting..."
        title="Submit Hours"
      />
    </div>
  );
};

const calculateClockedHours = (clockInDate, clockOutDate) => {
  var duration = moment.duration(clockOutDate.diff(clockInDate));

  var hours = Math.floor(duration.asHours());
  var minutes = Math.floor(duration.asMinutes() % 60);
  var seconds = duration.asSeconds().toFixed(2);

  if (hours < 1 && minutes < 1) {
    return `${seconds} seconds`;
  } else if (hours < 1 && minutes > 1) {
    return `00:${minutes.toString().padStart(2, "0")} minutes`;
  } else {
    return `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")} hrs`;
  }
};

SubmitHoursForm.propTypes = {};

SubmitHoursForm.defaultProps = {};

export default SubmitHoursForm;
