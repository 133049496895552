import upperFirst from "lodash/upperFirst";

export const isPendingPlan = ({ start_date, status = "" }) => {
  return (
    new Date(start_date) > new Date() && status === "Intro Successful - Onboarding Scheduled" // Pending plan status
  );
};

export const getSmartDollarString = (amount, isForceCents) => {
  const amountSign = amount < 0 ? "−" : ""; // Note: minus sign is U+2212 and not U+002D
  const amountAbs = Math.abs(amount);
  const amountCentsString = amountAbs.toFixed(2).split(".")[1];
  const amountDollarsString = Math.floor(amountAbs).toString();
  const amountDollarsStringCommaSeparated = amountDollarsString.replace(
    /(\d)(?=(\d{3})+(?!\d))/g,
    "$1,"
  );
  return (
    `${amountSign}$${amountDollarsStringCommaSeparated}` +
    (isForceCents || amountCentsString !== "00" ? `.${amountCentsString}` : "")
  );
};

export const fixedTwoDecimal = (amount) =>
  amount
    .toFixed(2)
    .toLocaleString()
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");

/**
 * Formats and normalizes name and removes non alpha numeric characters and extra spaces
 * @param {String} name
 * @returns {String}
 */
export const sanitizeName = (name) =>
  name && normalizeDiacritics(name)
    .replace(/[^\w\s]/gi, "") // remove non alpha numeric characters
    .split(/\s+/g) // split by spaces
    .map(upperFirst)
    .join(" ")
    .trim();

/**
 * Replaces accents and diacritics with normal alphabet
 * @param {String} name
 * @returns {String}
 */
export const normalizeDiacritics = (input) =>
  input.normalize("NFD").replace(/[\p{Diacritic}]/gu, "");

/**
 * Replaces < and > with character entity name equivalent
 * @param {String} string
 * @returns String
 */
export const escapeInput = (string) => {
  return string && string.replace(/</g, "&lt;").replace(/>/g, "&gt;").trim();
};

export const getOrdinalSuffix = (number) => {
  // this function will return string of ordinal suffix
  // eg: 1 => 1st, 2 => 2nd
  const suffix = ["th", "st", "nd", "rd"];
  const v = number % 100;
  return number + (suffix[(v - 20) % 10] || suffix[v] || suffix[0]);
};
